import { ArrowRight, Loader2, MessageSquare, PenLine } from 'lucide-react';
import React from 'react';

interface MessagePreviewProps {
  renderedText: string;
  recipient?: string;
  isSaving?: boolean;
}

export const MessagePreview: React.FC<MessagePreviewProps> = ({
  renderedText,
  recipient = '@finch-external-ops',
  isSaving = false,
}) => {
  const isEmpty = !renderedText || renderedText.trim() === '';

  return (
    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 shadow-sm">
      <div className="flex items-center mb-3">
        <MessageSquare className="w-4 h-4 text-indigo-500 mr-2" />
        <h3 className="font-semibold text-gray-700">Slack Message Preview</h3>
        {isSaving && (
          <div className="ml-auto flex items-center text-xs text-amber-600">
            <Loader2 className="w-3 h-3 mr-1.5 animate-spin" />
            <span>Updating preview...</span>
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-4 shadow-inner overflow-auto max-h-[300px]">
        <div className="flex items-start space-x-3">
          <div className="w-9 h-9 rounded bg-indigo-100 flex items-center justify-center flex-shrink-0 text-indigo-700 font-bold text-sm">
            F
          </div>

          <div className="flex-1">
            <div className="flex items-baseline mb-1">
              <span className="font-bold text-gray-800">Finch</span>
              <span className="ml-2 text-xs text-gray-400">
                Today at{' '}
                {new Date().toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>

            <div className="text-gray-700 whitespace-pre-line">
              <span className="font-medium text-indigo-600">
                Hi {recipient},
              </span>
              <br />
              {!isEmpty ? (
                <div className="p-1 prose prose-sm">{renderedText}</div>
              ) : (
                <div className="mt-2 border border-dashed border-gray-200 rounded-md p-3 bg-gray-50">
                  <div className="flex items-center text-gray-400 mb-2">
                    <PenLine className="w-4 h-4 mr-2" />
                    <span className="font-medium">
                      Your message will appear here
                    </span>
                  </div>
                  <div className="pl-6 text-sm text-gray-400 space-y-1.5">
                    <p className="flex items-center">
                      <ArrowRight className="w-3 h-3 mr-1.5 flex-shrink-0" />
                      <span>Start typing in the editor above</span>
                    </p>
                    <p className="flex items-center">
                      <ArrowRight className="w-3 h-3 mr-1.5 flex-shrink-0" />
                      <span>Use variables for dynamic content</span>
                    </p>
                    <p className="flex items-center">
                      <ArrowRight className="w-3 h-3 mr-1.5 flex-shrink-0" />
                      <span>Preview updates as you type</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
