import { Box, CircularProgress, Stack } from '@mui/material';

import useSharedMatterSelection from '../../hooks/useSharedMatterSelection';
import { NotFound } from './NotFound';
import { ReadOnlyMatter } from './ReadOnlyMatter';
import ReadOnlyMatterHeader from './ReadOnlyMatterHeader';

export const SharedMatter = () => {
  const { selectedMatterData, selectedMatterLoading } =
    useSharedMatterSelection();

  if (selectedMatterLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!selectedMatterData) {
    return <NotFound />;
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Stack direction="row" sx={{ height: '100vh', width: '100vw' }}>
        <Stack key={selectedMatterData?.id} direction="column">
          <ReadOnlyMatterHeader matter={selectedMatterData} />
          <ReadOnlyMatter matter={selectedMatterData} />
        </Stack>
      </Stack>
    </Box>
  );
};
