import React from 'react';

import { formatDate } from '@/lib/utils';

interface DisplayFieldProps {
  label: string;
  value: any;
  multiline?: boolean;
}

export const DisplayField: React.FC<DisplayFieldProps> = ({
  label,
  value,
  multiline = false,
}) => {
  if (value === null || value === undefined || value === '') {
    return <span className="text-muted-foreground italic">Not provided</span>;
  }

  // Handle date fields by checking if it matches an ISO date format
  if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}/.test(value)) {
    return formatDate(value);
  }

  // For multiline text, preserve whitespace
  if (multiline && typeof value === 'string') {
    return <div className="whitespace-pre-wrap">{value}</div>;
  }

  // For all other values, just display as is
  return value;
};
