import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type TemplateValues = Record<string, string>;

interface TemplateContextType {
  values: TemplateValues;
  setValues: (values: TemplateValues) => void;
  usedVariables: Set<string>;
  setUsedVariables: (variables: Set<string>) => void;
  getUnpopulatedVariables: () => string[];
  hasUnpopulatedVariables: boolean;
}

const TemplateContext = createContext<TemplateContextType | null>(null);

export const useTemplateVariables = () => {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error(
      'useTemplateVariables must be used within a TemplateVariablesProvider',
    );
  }
  return context;
};

interface TemplateVariablesProviderProps {
  children: ReactNode;
  values: TemplateValues;
  setValues: (values: TemplateValues) => void;
}

export const TemplateVariablesProvider = ({
  children,
  values,
  setValues,
}: TemplateVariablesProviderProps) => {
  const [usedVariables, setUsedVariables] = useState<Set<string>>(new Set());

  const getUnpopulatedVariables = useCallback(() => {
    return Array.from(usedVariables).filter(
      (variable) => !values[variable] || values[variable].trim() === '',
    );
  }, [usedVariables, values]);

  const contextValue = useMemo(
    () => ({
      values,
      setValues,
      usedVariables,
      setUsedVariables,
      getUnpopulatedVariables,
      hasUnpopulatedVariables: getUnpopulatedVariables().length > 0,
    }),
    [values, setValues, usedVariables, getUnpopulatedVariables],
  );

  return (
    <TemplateContext.Provider value={contextValue}>
      {children}
    </TemplateContext.Provider>
  );
};
