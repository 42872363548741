import { Article, VisibilityOff } from '@mui/icons-material';
import { MoreHorizontal } from 'lucide-react';
import * as React from 'react';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  TaskUpdate,
  TaskUpdateVisibility,
} from '@/services/types/client-intake-types';

interface TaskUpdatesProps {
  updates: TaskUpdate[];
  onCreateUpdate: (update: string, visibility: TaskUpdateVisibility) => void;
  onUpdateUpdate: (
    updateId: string,
    update: string,
    visibility: TaskUpdateVisibility,
  ) => void;
  onDeleteUpdate: (updateId: string) => void;
}

export const TaskUpdates: React.FC<TaskUpdatesProps> = ({
  updates,
  onCreateUpdate,
  onUpdateUpdate,
  onDeleteUpdate,
}) => {
  const [creatingUpdate, setCreatingUpdate] = React.useState(false);
  const [newUpdate, setNewUpdate] = React.useState('');
  const [editingUpdate, setEditingUpdate] = React.useState<TaskUpdate | null>(
    null,
  );
  const [newUpdateVisibility, setNewUpdateVisibility] =
    React.useState<TaskUpdateVisibility>(TaskUpdateVisibility.PUBLIC);

  const sortedUpdates = updates
    ? [...updates].sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )
    : [];

  const handleAddUpdate = async () => {
    if (!newUpdate.trim()) return;
    onCreateUpdate(newUpdate, newUpdateVisibility);
    setNewUpdate('');
    setCreatingUpdate(false);
  };

  // Helper function to convert URLs to clickable links
  const renderTextWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline"
            onClick={(e) => e.stopPropagation()}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="flex flex-col w-full gap-2 py-2">
      <div className="flex flex-row justify-between items-center">
        <h3 className="text-lg font-semibold">Updates</h3>
        <Button
          variant="outline"
          className="border-gray-300 hover:bg-gray-100"
          size="sm"
          onClick={() => setCreatingUpdate(true)}
        >
          <span className="font-bold text-xs text-black">ADD</span>
        </Button>
      </div>
      <div className="space-y-2 border border-gray-300 rounded-lg px-4 py-2">
        {sortedUpdates?.length > 0 ? (
          sortedUpdates.map((update) => (
            <div
              key={update.id}
              className="mt-4 flex flex-row gap-8 items-top pb-2"
            >
              <div className="flex justify-between text-sm text-gray-500 font-semibold">
                <div>{new Date(update.created_at).toLocaleDateString()}</div>
              </div>
              <div className="flex flex-col w-full">
                {editingUpdate?.id === update.id ? (
                  <div className="flex flex-col gap-2">
                    <Textarea
                      value={editingUpdate.update}
                      onChange={(e) =>
                        setEditingUpdate({
                          ...editingUpdate,
                          update: e.target.value,
                        })
                      }
                      className="w-full"
                    />
                    <div className="flex flex-row gap-4 justify-between">
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          className="data-[state=checked]:bg-white data-[state=checked]:border-black"
                          id="private-toggle-edit"
                          checked={
                            editingUpdate.visibility ===
                            TaskUpdateVisibility.PRIVATE
                          }
                          onCheckedChange={() =>
                            setEditingUpdate({
                              ...editingUpdate,
                              visibility:
                                editingUpdate.visibility ===
                                TaskUpdateVisibility.PRIVATE
                                  ? TaskUpdateVisibility.PUBLIC
                                  : TaskUpdateVisibility.PRIVATE,
                            })
                          }
                        />
                        <Label htmlFor="private-toggle-edit">
                          <span className="font-bold">Private</span>
                        </Label>
                      </div>
                      <div className="flex gap-2">
                        <Button
                          variant="outline"
                          onClick={() => setEditingUpdate(null)}
                          className="border-gray-300 cursor-pointer hover:bg-gray-100 font-semibold text-sm"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="text-white cursor-pointer font-semibold text-sm"
                          onClick={async () => {
                            await onUpdateUpdate(
                              update.id,
                              editingUpdate.update,
                              editingUpdate.visibility as TaskUpdateVisibility,
                            );
                            setEditingUpdate(null);
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex justify-between items-start">
                      <div className="text-sm">
                        {renderTextWithLinks(update.update)}
                      </div>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button
                            variant="ghost"
                            className="h-8 w-8 p-0 hover:bg-gray-100"
                          >
                            <MoreHorizontal className="h-4 w-4 text-gray-500" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align="end"
                          className="bg-white border border-gray-300"
                        >
                          <DropdownMenuItem
                            onClick={() => setEditingUpdate(update)}
                            className="cursor-pointer hover:bg-gray-100"
                          >
                            <span className="font-semibold">Edit</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="cursor-pointer text-red-600 hover:bg-red-100"
                            onClick={() => onDeleteUpdate(update.id)}
                          >
                            <span className="font-semibold text-red-600">
                              Delete
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    {update.attachments.length > 0 && (
                      <div>
                        {update.attachments.map((attachment) => (
                          <div
                            className="text-xs text-gray-500 border border-gray-400 p-2 rounded-md"
                            key={attachment.id}
                          >
                            <div className="flex flex-row gap-2 items-center">
                              <Article className="h-4 w-4 text-gray-500" />
                              {attachment.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {update.visibility === 'Private' && (
                      <div className="flex flex-row gap-1 items-center">
                        <div>
                          <VisibilityOff className="py-1" />
                        </div>
                        <div className="text-xs font-gray-500 font-semibold">
                          Not visible to attorneys
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-2 w-full h-[64px] justify-center items-center">
            <span className="text-gray-500 text-sm">No updates found</span>
          </div>
        )}
        {creatingUpdate && (
          <div className="flex flex-row justify-start gap-14 mb-2">
            <div className="text-sm text-gray-500 font-semibold">Today</div>
            <div className="flex flex-col w-full">
              <Textarea
                id="new-update"
                aria-label="New task update"
                placeholder="Enter new update..."
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
                className="mb-2 w-full"
              />
              <div className="flex flex-row gap-4 justify-between">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    className="data-[state=checked]:bg-white data-[state=checked]:border-black"
                    id="private-toggle"
                    checked={
                      newUpdateVisibility === TaskUpdateVisibility.PRIVATE
                    }
                    onCheckedChange={() =>
                      setNewUpdateVisibility(
                        newUpdateVisibility === TaskUpdateVisibility.PRIVATE
                          ? TaskUpdateVisibility.PUBLIC
                          : TaskUpdateVisibility.PRIVATE,
                      )
                    }
                  />
                  <Label htmlFor="private-toggle">
                    <span className="font-bold">Private</span>
                  </Label>
                </div>
                <Button onClick={handleAddUpdate}>
                  <span className="font-bold text-white">Add Update</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
