import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithErrorHandling } from './baseQuery';

interface SeedMatterSummaryResponse {
  task_id: string;
  status: 'PENDING' | 'SUCCESS' | 'FAILURE';
  completed?: boolean;
  result?: any;
  error?: string;
}

export const matterSummaryApi = createApi({
  reducerPath: 'matterSummaryApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    // Endpoint to trigger seeding of matter summary
    seedMatterSummary: builder.mutation<
      SeedMatterSummaryResponse,
      { matterId: string }
    >({
      query: ({ matterId }) => ({
        url: `api/matters/${matterId}/summary/`,
        method: 'POST',
      }),
    }),

    // Endpoint to get the status of a matter summary seeding task
    getSeedMatterSummaryStatus: builder.query<
      SeedMatterSummaryResponse,
      { matterId: string; taskId: string }
    >({
      query: ({ matterId, taskId }) => ({
        url: `api/matters/${matterId}/summary/`,
        method: 'GET',
        params: { task_id: taskId },
      }),
    }),
  }),
});

export const {
  useSeedMatterSummaryMutation,
  useGetSeedMatterSummaryStatusQuery,
} = matterSummaryApi;
