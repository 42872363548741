import { Close } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import { useDeleteMatterKeyConsiderationMutation } from '../../../services/api/mattersService';
import {
  MandatoryFlags,
  MatterKeyConsideration,
  MatterKeyConsiderationCategory,
  MatterKeyConsiderationSeverity,
} from '../../../services/types/client-intake-types';
import { getConsiderationCategoryColor } from '../../../utils/color';
import { Conditional } from '../../base/Utils';
import { KeyConsiderationFlag } from './KeyConsiderationFlag';

const Category: React.FC<{
  category: MatterKeyConsiderationCategory;
  severity: MatterKeyConsiderationSeverity;
}> = ({ category, severity }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: getConsiderationCategoryColor(severity),
      }}
      noWrap
      width="calc(100% - 24px)"
    >
      {category}
    </Typography>
  );
};

export const FlagAndCategory: React.FC<{
  onChange: (changes: Partial<MatterKeyConsideration>) => void;
  consideration: MatterKeyConsideration;
}> = ({ consideration, onChange }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <KeyConsiderationFlag
        severity={consideration.severity}
        onChange={(newSeverity) => {
          onChange({ severity: newSeverity });
        }}
      />
      <Category
        category={consideration.category}
        severity={consideration.severity}
      />
    </Stack>
  );
};

const DeleteButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <Button onClick={onClick} sx={{ padding: 0 }}>
      <Close color="disabled" />
    </Button>
  );
};

export const KeyConsiderationHeader: React.FC<{
  matterId: string;
  consideration: MatterKeyConsideration;
  onChange: (changes: Partial<MatterKeyConsideration>) => void;
  isHovered: boolean;
}> = ({ matterId, consideration, isHovered, onChange }) => {
  const [deleteMatterKeyConsideration] =
    useDeleteMatterKeyConsiderationMutation();

  const handleDelete = () => {
    deleteMatterKeyConsideration({
      matter_id: matterId,
      id: consideration.id,
    });
  };

  const isMandatory = MandatoryFlags.includes(consideration.category);

  return (
    <Stack
      direction="row"
      spacing={1}
      marginBottom={2}
      justifyContent="space-between"
      width="100%"
    >
      <FlagAndCategory consideration={consideration} onChange={onChange} />
      <Conditional isVisible={isHovered && !isMandatory}>
        <Stack direction="row" width="fit-content">
          <DeleteButton onClick={handleDelete} />
        </Stack>
      </Conditional>
    </Stack>
  );
};
