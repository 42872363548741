import { Article } from '@mui/icons-material';
import * as React from 'react';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  useGetBlobsForCollectionQuery,
  useGetCollectionTreeQuery,
} from '@/services/api/collectionService';
import {
  Blob,
  CollectionNode,
  Task,
} from '@/services/types/client-intake-types';

interface DocumentSelectionModalProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
  onSelectDocuments: (blobIds: string[]) => Promise<void>;
  task: Task;
}

export const DocumentSelectionModal: React.FC<DocumentSelectionModalProps> = ({
  open,
  onClose,
  matterId,
  onSelectDocuments,
  task,
}) => {
  const { data: collectionTree } = useGetCollectionTreeQuery(matterId, {
    skip: !matterId,
  });
  const { data: blobs } = useGetBlobsForCollectionQuery(matterId, {
    skip: !matterId,
  });
  const [selectedCollectionId, setSelectedCollectionId] =
    React.useState<string>('');
  const [selectedBlobIds, setSelectedBlobIds] = React.useState<Set<string>>(
    new Set(task.attachments.map((attachment) => attachment.id)),
  );
  const [searchQuery, setSearchQuery] = React.useState('');

  // Set root collection as default when data is loaded
  React.useEffect(() => {
    if (collectionTree && !selectedCollectionId) {
      setSelectedCollectionId(collectionTree.id);
    }
  }, [collectionTree, selectedCollectionId]);

  React.useEffect(() => {
    setSelectedBlobIds(new Set(task.attachments.map((a) => a.id)));
  }, [task.attachments]);

  // Filter blobs based on selected collection
  const filteredBlobs = React.useMemo(() => {
    if (!blobs) return [];

    return blobs.filter(
      (blob) =>
        (selectedCollectionId === collectionTree?.id ||
          blob.collection_details.id === selectedCollectionId) &&
        (searchQuery === '' ||
          blob.name.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [blobs, selectedCollectionId, collectionTree, searchQuery]);

  const handleToggleSelect = (blobId: string) => {
    const newSelected = new Set(selectedBlobIds);
    if (newSelected.has(blobId)) {
      newSelected.delete(blobId);
    } else {
      newSelected.add(blobId);
    }
    setSelectedBlobIds(newSelected);
  };

  const renderCollectionOptions = (
    node: CollectionNode,
    level = 0,
  ): JSX.Element[] => {
    const options: JSX.Element[] = [];
    const prefix = '\u00A0'.repeat(level * 2);

    options.push(
      <SelectItem
        key={node.id}
        value={node.id}
        className="cursor-pointer hover:bg-gray-100"
      >
        {prefix + (node.name === 'root' ? 'All Files' : node.name)}
      </SelectItem>,
    );

    if (node.children) {
      node.children.forEach((child: CollectionNode) => {
        options.push(...renderCollectionOptions(child, level + 1));
      });
    }

    return options;
  };

  const handleSubmit = async () => {
    await onSelectDocuments(Array.from(selectedBlobIds));
    onClose();
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent className="bg-white">
        <AlertDialogHeader>
          <AlertDialogTitle>Select Documents</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="flex flex-col gap-4 py-4 overflow-auto">
          <div className="flex flex-col items-start gap-2">
            <Label className="text-sm font-semibold">Folder</Label>
            <Select
              value={selectedCollectionId}
              onValueChange={(value) => setSelectedCollectionId(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a folder" />
              </SelectTrigger>
              <SelectContent className="bg-white border border-gray-300">
                {collectionTree && renderCollectionOptions(collectionTree)}
              </SelectContent>
            </Select>
            <Input
              placeholder="Search documents..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full h-10 px-3 py-2"
            />
          </div>

          <div className="border rounded-md p-2 h-[300px] overflow-y-auto">
            {filteredBlobs.length > 0 ? (
              <div>
                {filteredBlobs.map((blob) => (
                  <Button
                    variant="ghost"
                    key={blob.id}
                    className="flex items-center p-2 hover:bg-gray-50 cursor-pointer m-2"
                    onClick={() => handleToggleSelect(blob.id)}
                  >
                    <Checkbox
                      className="cursor-pointer data-[state=checked]:bg-white data-[state=checked]:border-black"
                      checked={selectedBlobIds.has(blob.id)}
                      onCheckedChange={() => {
                        handleToggleSelect(blob.id);
                      }}
                    />
                    <div className="ml-2 flex items-center">
                      <Article className="h-4 w-4 text-gray-500 mr-2" />
                      <div className="flex flex-col items-start">
                        <div className="font-medium">{blob.name}</div>
                        <div className="text-xs text-gray-500">
                          {blob.file_size > 1024 * 1024
                            ? `${(blob.file_size / (1024 * 1024)).toFixed(1)} MB`
                            : `${(blob.file_size / 1024).toFixed(1)} KB`}
                          •{' '}
                          {blob.file_extension?.toUpperCase().replace('.', '')}•{' '}
                          {new Date(blob.modified_at).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </Button>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-40 text-gray-500">
                No documents found
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <Button
            onClick={() => {
              onClose();
              setSelectedBlobIds(new Set(task.attachments.map((a) => a.id)));
            }}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={selectedBlobIds.size === 0}
            className="bg-primary text-white hover:bg-primary/90 font-bold"
          >
            Save
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
