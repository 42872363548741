import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../services/types/user-types';

type AuthState = {
  loggedIn: boolean;
  redirectPath: string | null;
  user: User | null;
};

const initialState: AuthState = {
  loggedIn: false,
  redirectPath: null,
  user: null,
};

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    userLoggedIn: (state: Draft<AuthState>, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.loggedIn = true;
    },

    userLoggedOut: (state: Draft<AuthState>) => {
      state.user = null;
      state.loggedIn = false;
    },

    setRedirectPath: (state, action: PayloadAction<string | null>) => {
      state.redirectPath = action.payload;
    },
  },
});

export const { userLoggedIn, userLoggedOut, setRedirectPath } =
  rootSlice.actions;

export default rootSlice.reducer;
