import { Box, Divider, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';

import { useKeyConsiderations } from '../../hooks/useKeyConsiderationsSelection';
import useMatterSelection from '../../hooks/useMatterSelection';
import { DetailedMatter } from '../../services/types/client-intake-types';
import ClientScreenTile from '../base/ClientScreenTile';
import { Modal } from '../base/Modal';
import { Conditional } from '../base/Utils';
import { KeyConsideration } from './key_consideration/KeyConsideration';
import { KeyConsiderationForm } from './key_consideration/KeyConsiderationForm';
import { KeyConsiderationsHeader } from './KeyConsiderationsHeader';

const CreateKeyConsiderationModal = ({
  open,
  onClose,
  matter,
}: {
  open: boolean;
  onClose: () => void;
  matter: DetailedMatter;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <KeyConsiderationForm matter={matter} onClose={onClose} />
    </Modal>
  );
};

const KeyConsiderations: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();
  const { mandatoryConsiderations, nonMandatoryConsiderations } =
    useKeyConsiderations(selectedMatterData?.key_considerations || []);

  const [isAdding, setIsAdding] = useState(false);

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2}>
        <KeyConsiderationsHeader onAdd={() => setIsAdding(true)} />
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {mandatoryConsiderations &&
              selectedMatterData &&
              mandatoryConsiderations.map((consideration) => (
                <KeyConsideration
                  key={consideration.id}
                  selectedMatterData={selectedMatterData}
                  consideration={consideration}
                />
              ))}
            {nonMandatoryConsiderations &&
              selectedMatterData &&
              nonMandatoryConsiderations.map((consideration) => (
                <KeyConsideration
                  key={consideration.id}
                  selectedMatterData={selectedMatterData}
                  consideration={consideration}
                />
              ))}
          </Grid>
        </Box>
      </Stack>
      <Conditional isVisible={!!selectedMatterData}>
        <CreateKeyConsiderationModal
          open={isAdding}
          onClose={() => setIsAdding(false)}
          matter={selectedMatterData!}
        />
      </Conditional>
    </ClientScreenTile>
  );
};

export default KeyConsiderations;
