import { History } from 'lucide-react';
import React from 'react';

import { Badge } from '@/components/ui/badge';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

import { ScheduleCall } from './types';
import { formatDateTime, getStatusBadgeClass } from './utils';

interface RecentCallHistoryProps {
  recentCalls: ScheduleCall[];
  isLoading: boolean;
}

export const RecentCallHistory: React.FC<RecentCallHistoryProps> = ({
  recentCalls,
  isLoading,
}) => {
  const renderRecentCalls = () => {
    if (isLoading) {
      return (
        <div className="space-y-2">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        {recentCalls.slice(0, 4).map((call) => {
          const badgeClass = getStatusBadgeClass(call.status, false);

          return (
            <div key={call.id} className="flex items-center text-xs">
              <Badge
                variant="outline"
                className={cn(badgeClass, 'mr-2 px-2 text-xs flex-shrink-0')}
              >
                {call.status}
              </Badge>
              <span className="truncate">
                {formatDateTime(call.created_at)}
              </span>
            </div>
          );
        })}
        {recentCalls.length > 4 && (
          <p className="text-xs text-muted-foreground mt-1 col-span-full">
            + {recentCalls.length - 4} more calls
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg p-3 shadow-sm">
      <div className="flex items-center mb-2">
        <History className="h-4 w-4 mr-2 text-primary flex-shrink-0" />
        <h4 className="font-medium">Recent Calls</h4>
      </div>

      {renderRecentCalls()}
    </div>
  );
};
