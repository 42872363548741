import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithErrorHandling } from './baseQuery';

export const formsApiClient = createApi({
  reducerPath: 'formsApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['FormCategories'] as const,
  endpoints: (builder) => ({
    getFormCategories: builder.query<string[], void>({
      query: () => 'api/forms/templates/categories/',
      providesTags: ['FormCategories'],
      transformResponse: (response: { categories: string[] }) =>
        response.categories,
    }),
  }),
});

export const { useGetFormCategoriesQuery } = formsApiClient;
