import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateTaskModal } from '@/components/tasks/CreateTaskModal';
import { TaskDetail } from '@/components/tasks/task-detail';
import { TaskGroupings } from '@/components/tasks/TaskGroupings';
import { Button } from '@/components/ui/button';

export const TaskPanel = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { matter_id, task_id } = useParams();
  const navigate = useNavigate();

  const handleBackToTasks = () => {
    navigate(`/matters/${matter_id}`);
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="sticky top-0 bg-white z-10 py-2 px-2">
          <div className="flex flex-row  items-center justify-between">
            {task_id ? (
              <div className="flex items-center">
                <Button
                  variant="ghost"
                  onClick={handleBackToTasks}
                  className="h-auto text-sm font-medium"
                  size="sm"
                >
                  <ChevronLeft className="h-4 w-4 " />
                  <span>Back to All Tasks</span>
                </Button>
              </div>
            ) : (
              <>
                <h3 className="text-2xl font-bold">Tasks</h3>
                <Button
                  variant="default"
                  onClick={() => setIsCreateModalOpen(true)}
                  className="px-4"
                >
                  <span className="text-sm font-bold text-white">Add Task</span>
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="overflow-y-auto flex-grow px-2">
          {task_id ? (
            <TaskDetail
              taskId={task_id}
              matterId={matter_id}
              onClose={handleBackToTasks}
              isModal={false}
            />
          ) : (
            <div className="px-2">
              <TaskGroupings />
            </div>
          )}
        </div>
      </div>
      <CreateTaskModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </>
  );
};

export default TaskPanel;
