import { Phone, PlusCircle, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

interface PhoneNumber {
  id: string;
  phone_number: string;
}

/**
 * Validates a phone number
 * @param phoneNumber The phone number to validate
 * @returns true if the phone number is valid, false otherwise
 */
const isValidPhoneNumber = (phoneNumber: string): boolean => {
  // Allow empty strings (validation happens on blur)
  if (!phoneNumber || phoneNumber.trim() === '') return true;

  // Basic phone validation - at least 10 digits
  // Remove all non-digit characters for counting
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  return digitsOnly.length >= 10;
};

/**
 * Formats a phone number for display
 * @param phoneNumber The phone number to format
 * @returns The formatted phone number
 */
const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';

  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Format based on number of digits
  if (digitsOnly.length === 10) {
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
  }
  if (digitsOnly.length === 11 && digitsOnly[0] === '1') {
    return `1 (${digitsOnly.slice(1, 4)}) ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7)}`;
  }

  // If doesn't match patterns, return as is but with some spacing
  return phoneNumber;
};

interface PhoneNumberFieldProps {
  phone: PhoneNumber;
  index: number;
  isEditing: boolean;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
}

/**
 * Individual phone number field component
 */
const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  phone,
  index,
  isEditing,
  onChange,
  onRemove,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  // Validate on mount and when phone number changes
  useEffect(() => {
    if (phone.phone_number) {
      setIsValid(isValidPhoneNumber(phone.phone_number));
    } else {
      setIsValid(true);
    }
  }, [phone.phone_number]);

  if (isEditing) {
    return (
      <div className="flex w-full items-center">
        <Input
          value={phone.phone_number || ''}
          onChange={(e) => {
            onChange(index, e.target.value);
            if (isTouched) {
              setIsValid(isValidPhoneNumber(e.target.value));
            }
          }}
          onBlur={() => {
            setIsTouched(true);
            setIsValid(isValidPhoneNumber(phone.phone_number));
          }}
          placeholder="Enter phone number"
          className={cn(
            'h-9 border-0 shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 px-0',
            !isValid &&
              isTouched &&
              'text-destructive border-destructive focus-visible:ring-destructive',
          )}
        />
        {!isValid && isTouched && (
          <div className="text-xs text-destructive ml-2">Invalid</div>
        )}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => onRemove(index)}
          className="h-7 px-2 text-destructive/70 hover:text-destructive hover:bg-destructive/10"
        >
          <Trash className="h-3.5 w-3.5" />
        </Button>
      </div>
    );
  }

  // Format phone number for display
  const formattedPhone = formatPhoneNumber(phone.phone_number);
  const phoneUrl = `tel:${phone.phone_number.replace(/\D/g, '')}`;

  return (
    <div className="text-sm text-gray-600 hover:text-gray-900 transition-colors">
      <a
        href={phoneUrl}
        className="inline-flex items-center gap-1.5 hover:underline"
        aria-label={`Call ${formattedPhone}`}
      >
        <Phone className="h-3.5 w-3.5 text-muted-foreground/60" />
        {formattedPhone}
      </a>
    </div>
  );
};

interface PhoneNumberFieldsProps {
  phoneNumbers: PhoneNumber[];
  isEditing: boolean;
  onAdd: () => void;
  onRemove: (index: number) => void;
  onChange: (index: number, value: string) => void;
}

/**
 * Component for displaying and editing phone numbers
 */
export const PhoneNumberFields: React.FC<PhoneNumberFieldsProps> = ({
  phoneNumbers,
  isEditing,
  onAdd,
  onRemove,
  onChange,
}) => {
  return (
    <div className="border-t border-gray-100">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-900">
          <div className="flex justify-between items-center">
            <span>Phone Numbers</span>
            {isEditing && (
              <Button
                variant="ghost"
                size="sm"
                onClick={onAdd}
                className="h-8 text-xs text-muted-foreground/60 hover:text-primary/80"
              >
                <PlusCircle className="mr-1 h-3.5 w-3.5" />
                <span className="hidden sm:inline">Add Phone</span>
              </Button>
            )}
          </div>
        </dt>
        <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
          {phoneNumbers.length === 0 ? (
            <div className="text-sm text-muted-foreground italic">
              No phone numbers added
            </div>
          ) : (
            <div className="space-y-3">
              {phoneNumbers.map((phone, index) => (
                <div
                  key={phone.id || index}
                  className={
                    isEditing
                      ? ''
                      : 'border-b border-gray-50 pb-2 last:border-b-0 last:pb-0'
                  }
                >
                  <PhoneNumberField
                    phone={phone}
                    index={index}
                    isEditing={isEditing}
                    onChange={onChange}
                    onRemove={onRemove}
                  />
                </div>
              ))}
            </div>
          )}
        </dd>
      </div>
    </div>
  );
};
