import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';

import {
  DemandConfigurationCreate,
  DemandConfigurationEditor,
  DemandConfigurationListView,
  DemandConfigurationPreviewPage,
} from './index';

export const Firms = () => {
  const [activeTab, setActiveTab] = useState('demandLetter');
  const { pathname } = useLocation();
  const { configId } = useParams();
  const navigate = useNavigate();

  // Determine which component to render based on the current route
  const renderDemandConfigComponent = () => {
    if (pathname.includes('/create')) {
      return <DemandConfigurationCreate />;
    }
    if (pathname.includes('/edit') && configId) {
      return <DemandConfigurationEditor configId={configId} />;
    }
    if (configId && !pathname.includes('/edit')) {
      return <DemandConfigurationPreviewPage configId={configId} />;
    }
    return <DemandConfigurationListView />;
  };

  return (
    <div className="p-6 w-full mx-auto">
      <div className="flex gap-6">
        {/* Sidebar */}
        <div className="w-1/8">
          <div className="space-y-1">
            <Button
              variant={activeTab === 'demandLetter' ? 'default' : 'ghost'}
              className="w-full justify-start"
              onClick={() => {
                setActiveTab('demandLetter');
                navigate('/firms');
              }}
            >
              Demand Letter
            </Button>
          </div>
        </div>

        {/* Main content */}
        <div className="w-5/6">
          {activeTab === 'demandLetter' && renderDemandConfigComponent()}
        </div>
      </div>
    </div>
  );
};
