import { addDays, format } from 'date-fns';
import { Calendar as CalendarIcon, Repeat } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';

import { DAYS_OF_WEEK, ScheduleFormValues } from './types';

export const DaysOfWeekSelector = () => {
  const form = useFormContext<ScheduleFormValues>();
  const selectedDaysOfWeek = form.watch('selectedDaysOfWeek') || [];
  const endDate = form.watch('endDate');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Set default end date (today + 7 days) if not already set
  useEffect(() => {
    if (!endDate) {
      const defaultEndDate = addDays(new Date(), 7);
      form.setValue('endDate', defaultEndDate);
    }
  }, [endDate, form]);

  // Handle day of week selection
  const handleDayOfWeekToggle = (dayValue: number) => {
    const currentSelection = [...selectedDaysOfWeek];
    const index = currentSelection.indexOf(dayValue);

    if (index === -1) {
      // Add the day
      currentSelection.push(dayValue);
    } else {
      // Remove the day
      currentSelection.splice(index, 1);
    }

    form.setValue('selectedDaysOfWeek', currentSelection, {
      shouldValidate: true,
    });

    // Show validation error if no days selected
    if (currentSelection.length === 0) {
      form.setError('selectedDaysOfWeek', {
        type: 'manual',
        message: 'Select at least one day of the week',
      });
    } else {
      form.clearErrors('selectedDaysOfWeek');
    }
  };

  // Handle end date selection
  const handleEndDateChange = (date: Date | undefined) => {
    if (date) {
      form.setValue('endDate', date, { shouldValidate: true });
      setIsCalendarOpen(false);
    }
  };

  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="selectedDaysOfWeek"
        render={({ field }) => (
          <FormItem className="border rounded-lg p-4">
            <div className="mb-3">
              <FormLabel className="text-lg block m-0">
                Call on these days:
              </FormLabel>
            </div>

            <FormControl>
              <div className="grid grid-cols-7 gap-2">
                {DAYS_OF_WEEK.map((day) => (
                  <Button
                    key={day.value}
                    type="button"
                    variant={
                      selectedDaysOfWeek.includes(day.value)
                        ? 'default'
                        : 'outline'
                    }
                    className={cn(
                      'h-12 w-full',
                      selectedDaysOfWeek.includes(day.value) && 'font-bold',
                    )}
                    onClick={() => handleDayOfWeekToggle(day.value)}
                  >
                    {day.label.substring(0, 3)}
                  </Button>
                ))}
              </div>
            </FormControl>

            {selectedDaysOfWeek.length > 0 && (
              <div className="mt-4 text-sm text-muted-foreground">
                <div className="flex items-center gap-1.5">
                  <Repeat className="h-4 w-4 opacity-70" />
                  <span className="font-medium">
                    {selectedDaysOfWeek.length} day
                    {selectedDaysOfWeek.length !== 1 ? 's' : ''} selected
                  </span>
                </div>
              </div>
            )}

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="endDate"
        render={({ field }) => (
          <FormItem className="border rounded-lg p-4">
            <div className="">
              <FormLabel className="text-lg block m-0">Until:</FormLabel>
            </div>

            <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-full pl-3 text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                  onClick={() => setIsCalendarOpen(true)}
                >
                  {field.value
                    ? format(field.value, 'PPP')
                    : 'Select an end date'}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={field.value}
                  onSelect={handleEndDateChange}
                  disabled={(date) => date < new Date()}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
