import { TextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

export const TextFormField = forwardRef<HTMLDivElement, MuiTextFieldProps>(
  ({ ...rest }: MuiTextFieldProps, ref) => {
    return <TextField fullWidth {...rest} ref={ref} />;
  },
);

TextFormField.displayName = 'TextFormField';
