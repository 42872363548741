import { useEffect, useState } from 'react';

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import {
  useGenerateDemandMutation,
  useGetDemandStatusQuery,
  usePutDemandMutation,
} from '@/services/api';
import { DemandGenerationStatus } from '@/services/types/demand-types';

import { Button } from '../ui/button';
import { Chat } from './chat/components/Chat';
import { Editor } from './editor/editor';
import { EditorProvider, useEditor } from './editor/editor-provider';

const DemandsEditor = ({ matterId }: { matterId: string }) => {
  const { editor } = useEditor();
  const { data: demand } = useGetDemandStatusQuery({ matterId });

  const handleInsertGeneratedDemand = () => {
    if (demand?.generated_document) {
      editor?.setData(demand.generated_document);
    }
  };
  return (
    <div className="w-full h-[calc(100%-2rem)] bg-background rounded border overflow-auto">
      <Editor matterId={matterId} />
    </div>
  );
};

const PlaceholderContent = ({ matterId }: { matterId: string }) => {
  const [putDemand, { data: demand }] = usePutDemandMutation();

  useEffect(() => {
    putDemand({ matterId, data: { name: 'New Demand' } });
  }, [matterId, putDemand]);

  if (!demand) {
    return null;
  }

  return (
    <div className="h-full w-full bg-muted rounded-lg overflow-auto">
      <Chat matterId={matterId} demandId={demand?.id} />
    </div>
  );
};

export const Demands = ({ matterId }: { matterId: string }) => {
  const [hasDemandGenerated, setHasDemandGenerated] = useState(false);
  const [generateDemand, { isLoading: isGenerating }] =
    useGenerateDemandMutation();

  const { data: demand } = useGetDemandStatusQuery(
    { matterId },
    {
      pollingInterval: 3000,
      skip: hasDemandGenerated,
    },
  );

  useEffect(() => {
    if (demand?.generated_document) {
      setHasDemandGenerated(true);
    }
  }, [demand]);

  if (!demand) {
    return (
      <div className="h-[calc(100vh-300px)] flex flex-col items-center justify-center p-6">
        <div className="flex flex-col items-center space-y-4">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
          <h3 className="text-lg font-medium text-primary">Loading</h3>
        </div>
      </div>
    );
  }
  const handleGenerateDemand = async () => {
    try {
      await generateDemand({
        matterId,
      }).unwrap();
    } catch (error) {
      console.error('Failed to generate demand:', error);
    }
  };

  if (!demand.generated_document) {
    return (
      <div className="h-[calc(100vh-300px)] flex flex-col items-center justify-center p-6">
        <div className="flex flex-col items-center space-y-6">
          {isGenerating ||
          demand.generation_status === DemandGenerationStatus.GENERATING ? (
            <>
              <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
              <h3 className="text-lg font-medium text-primary">
                Generating demand...
              </h3>
              <p className="text-sm text-muted-foreground text-center max-w-md">
                This may take a moment while we prepare your document
              </p>
            </>
          ) : (
            <>
              <h3 className="text-lg font-medium">
                No demand document available
              </h3>
              <p className="text-sm text-muted-foreground text-center max-w-md mb-4">
                Would you like to generate a demand document for this matter?
              </p>
              <Button onClick={handleGenerateDemand} size="lg">
                Generate Demand Document
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <EditorProvider>
      <div className="h-full">
        <ResizablePanelGroup direction="horizontal" className="h-full">
          <ResizablePanel
            id="editor"
            defaultSize={70}
            minSize={50}
            className="min-w-min"
          >
            <DemandsEditor matterId={matterId} />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel id="chat" defaultSize={30} minSize={20}>
            <PlaceholderContent matterId={matterId} />
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </EditorProvider>
  );
};
