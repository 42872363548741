import { Box, Container, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useState } from 'react';

import { useRequestResetPasswordMutation } from '../../services/api/userService';
import { isEmailValid } from '../../utils/validation';
import { SecondaryButton } from '../base/Buttons';
import FinchLogo from '../base/FinchLogo';
import { useNotification } from '../contexts/NotificationContext';

interface ForgotPasswordFormState {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const notify = useNotification();
  const [requestResetPassword] = useRequestResetPasswordMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formState, setFormState] = useState<ForgotPasswordFormState>({
    email: '',
  });

  const handleChange =
    (prop: keyof ForgotPasswordFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
    };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmailValid(formState.email)) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      await requestResetPassword({ email: formState.email });
      notify(
        `Password reset request sent to email: ${formState.email}`,
        'success',
        3000,
      );
    } catch (e) {
      Sentry.captureException(e);
      notify('Failed to send password reset email', 'error', 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <FinchLogo width="160px" />
        <Box component="form" onSubmit={handleSubmit} width="100%">
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formState.email}
            onChange={handleChange('email')}
            error={!!error && error.includes('email')}
            inputProps={{
              pattern: '[^\\s@]+@[^\\s@]+\\.[^\\s@]+',
            }}
          />

          <SecondaryButton
            type="submit"
            fullWidth
            disableRipple
            disabled={loading}
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
          >
            Send Reset Link
          </SecondaryButton>
        </Box>
      </Box>
    </Container>
  );
};
