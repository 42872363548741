import { Loader2 } from 'lucide-react';
import { useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { cn } from '../../../lib/utils';
import { BlandCallTemplate } from '../../../services/types/bland-templates.types';

// Helper function to format the date in a human-readable way
const formatDate = (date: string) => {
  const d = new Date(date);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - d.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) return 'just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  if (diffInHours < 24) return `${diffInHours}h ago`;
  if (diffInDays < 7) return `${diffInDays}d ago`;

  return d.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: d.getFullYear() !== now.getFullYear() ? 'numeric' : undefined,
  });
};

interface TemplateListItemProps {
  template: BlandCallTemplate;
  isSelected: boolean;
  onSelect: (template: BlandCallTemplate) => void;
  onDelete: (template: BlandCallTemplate) => void;
}

const TemplateListItem = ({
  template,
  isSelected,
  onSelect,
  onDelete,
}: TemplateListItemProps) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleMouseLeave = () => {
    setIsConfirmingDelete(false);
  };

  const handleDelete = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    if (!isConfirmingDelete) {
      setIsConfirmingDelete(true);
      return;
    }
    onDelete(template);
  };

  return (
    <div className="group relative" onMouseLeave={handleMouseLeave}>
      <button
        type="button"
        className={cn(
          'w-full text-left px-3 py-2.5 rounded-lg text-sm border transition-all',
          'bg-white hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-primary/30',
          isSelected
            ? 'bg-gray-100 border-gray-200 shadow-sm'
            : 'border-gray-100 hover:border-gray-200',
        )}
        onClick={() => onSelect(template)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onSelect(template);
          }
        }}
      >
        <div className="flex items-start justify-between gap-3">
          <div className="min-w-0 flex-1">
            <div className="font-medium text-gray-900 mb-1.5 break-words pr-2">
              {template.title}
            </div>
            <div className="space-y-1">
              <div className="text-xs text-gray-500">
                {template.finch_task_type}
              </div>
              <time className="text-[11px] tabular-nums text-gray-400">
                Updated {formatDate(template.modified_at)}
              </time>
            </div>
          </div>
          <div
            role="button"
            tabIndex={0}
            className={cn(
              'shrink-0 opacity-0 group-hover:opacity-100 transition-all',
              isConfirmingDelete
                ? 'bg-transparent'
                : 'p-2 rounded-md -my-1.5 -mr-1.5 hover:bg-red-100 text-gray-500 hover:text-red-600',
              'focus-visible:ring-2 focus-visible:ring-red-500/30 min-w-[32px] flex items-center justify-center',
            )}
            onClick={isConfirmingDelete ? undefined : handleDelete}
            onKeyDown={(e) => {
              if (!isConfirmingDelete && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault();
                handleDelete(e);
              }
            }}
            aria-label={`Delete template "${template.title}"`}
          >
            {isConfirmingDelete ? (
              <div className="flex flex-col gap-1">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsConfirmingDelete(false);
                  }}
                  className="text-[11px] font-medium px-2 py-1 bg-gray-100 text-gray-600 rounded hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(template);
                  }}
                  className="text-[11px] font-medium px-2 py-1 bg-red-100 text-red-600 rounded hover:bg-red-200"
                >
                  Delete
                </button>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 6h18" />
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
              </svg>
            )}
          </div>
        </div>
      </button>
    </div>
  );
};

interface TemplateListProps {
  templates: BlandCallTemplate[];
  isLoading: boolean;
  selectedTemplate: BlandCallTemplate | null;
  onSelectTemplate: (template: BlandCallTemplate | null) => void;
  onDeleteTemplate: (template: BlandCallTemplate) => void;
  onCreateNew: () => void;
  onDuplicate: () => void;
}

export const TemplateList = ({
  templates,
  isLoading,
  selectedTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateNew,
  onDuplicate,
}: TemplateListProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredTemplates = useMemo(() => {
    return templates
      .filter((t) =>
        searchQuery
          ? t.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (t.finch_task_type
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) ??
              false)
          : true,
      )
      .sort(
        (a, b) =>
          new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime(),
      );
  }, [templates, searchQuery]);

  return (
    <div className="flex flex-col h-full">
      <div className="sticky top-0 bg-white z-10 pb-3 border-b space-y-3">
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onCreateNew}
            className="hover:bg-purple-50 hover:text-purple-700 hover:border-purple-200 transition-colors"
          >
            Create
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={onDuplicate}
            disabled={!selectedTemplate}
            className="hover:bg-purple-50 hover:text-purple-700 hover:border-purple-200 transition-colors"
          >
            Duplicate
          </Button>
        </div>
        <Input
          type="text"
          placeholder="Search templates..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full focus-visible:ring-0 focus-visible:ring-offset-0 border-gray-300 focus:border-gray-600"
        />
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center p-4">
          <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
        </div>
      ) : (
        <div className="overflow-y-auto flex-1 pr-2 -mr-2 space-y-1.5 mt-4 min-h-0">
          {filteredTemplates.map((template) => (
            <TemplateListItem
              key={template.id}
              template={template}
              isSelected={selectedTemplate?.id === template.id}
              onSelect={onSelectTemplate}
              onDelete={onDeleteTemplate}
            />
          ))}
          {filteredTemplates.length === 0 && (
            <div className="text-sm text-gray-500 text-center py-8">
              No templates found
            </div>
          )}
        </div>
      )}
    </div>
  );
};
