import { zodResolver } from '@hookform/resolvers/zod';
import { MessageSquare, RefreshCw } from 'lucide-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { useTemplateState } from '@/components/template-editor/hooks/useTemplateState';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogTitle,
  RightDialogContent,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { useSendExternalOpsMessageMutation } from '@/services/api/externalOpsMessageService';
import { useGetMatterContextQuery } from '@/services/api/matterContextService';
import { useGetOperatorTemplatesQuery } from '@/services/api/operatorTemplateService';
import { Task } from '@/services/types/client-intake-types';
import { OperatorTemplate } from '@/services/types/operator-templates-types';
import {
  ContactType,
  TASKS_WITH_ATTACHED_INSURANCE_POLICY,
  TASKS_WITH_ATTACHED_MEDICAL_PROVIDER,
} from '@/services/types/task-types';

import { ContactSelector } from './ContactSelector';
import { TemplateSelector } from './TemplateSelector';

// Define the form schema - only need provider, no phone number
const formSchema = z.object({
  provider: z
    .object({
      id: z.string(),
      name: z.string(),
      phone: z.string(),
      type: z.nativeEnum(ContactType),
    })
    .nullable()
    .optional(),
});

type FormValues = z.infer<typeof formSchema>;

interface OperatorMessageModalProps {
  task: Task;
  onClose: () => void;
  handleSubmit: (action: any) => void;
}

interface RefreshContextButtonProps {
  onRefresh: () => Promise<any>;
}

const RefreshContextButton: React.FC<RefreshContextButtonProps> = ({
  onRefresh,
}) => {
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  return (
    <div className="flex justify-start my-2">
      <Button
        variant="ghost"
        size="sm"
        type="button"
        onClick={async () => {
          setIsRefreshing(true);
          try {
            await onRefresh();
            toast.success('Context refreshed');
          } catch (error) {
            toast.error('Failed to refresh context');
          } finally {
            setIsRefreshing(false);
          }
        }}
        disabled={isRefreshing}
        className="h-7 text-xs text-gray-500 hover:text-gray-900"
      >
        <RefreshCw
          className={`h-3 w-3 mr-1 ${isRefreshing ? 'animate-spin' : ''}`}
        />
        refresh context
      </Button>
    </div>
  );
};

// Message preview component
const MessagePreview: React.FC<{
  renderedText: string;
  recipient?: string;
}> = ({ renderedText, recipient = '@finch-external-ops' }) => {
  const isEmpty = !renderedText || renderedText.trim() === '';

  return (
    <div className="p-4 bg-gray-50 rounded-lg border border-gray-200 shadow-sm">
      <div className="flex items-center mb-3">
        <MessageSquare className="w-4 h-4 text-indigo-500 mr-2" />
        <h3 className="font-semibold text-gray-700">Slack Message Preview</h3>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-4 shadow-inner overflow-auto max-h-[300px]">
        {isEmpty ? (
          <div className="text-gray-400 italic">
            Message preview will appear here...
          </div>
        ) : (
          <div className="space-y-2">
            <div className="text-sm font-semibold text-gray-600">
              To: {recipient}
            </div>
            <div className="whitespace-pre-wrap text-sm">{renderedText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const OperatorMessageModal: React.FC<OperatorMessageModalProps> = ({
  task,
  onClose,
  handleSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<OperatorTemplate | null>(null);

  // Add the mutation hook
  const [sendExternalOpsMessage] = useSendExternalOpsMessageMutation();

  const isMedicalTask = TASKS_WITH_ATTACHED_MEDICAL_PROVIDER.includes(
    task.type as any,
  );
  const isInsuranceTask = TASKS_WITH_ATTACHED_INSURANCE_POLICY.includes(
    task.type as any,
  );

  const { data: templates = [], isLoading: isLoadingTemplates } =
    useGetOperatorTemplatesQuery();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const selectedContact = form.watch('provider');

  // Query for matter context
  const queryParams = {
    matterId: task.matter,
    ...(selectedContact?.type === ContactType.MEDICAL_PROVIDER && {
      medicalNoteId: selectedContact.id,
    }),
    ...(selectedContact?.type === ContactType.INSURANCE_ADJUSTER && {
      insuranceNoteId: selectedContact.id,
    }),
  };

  const { data: matterContext, refetch: refetchMatterContext } =
    useGetMatterContextQuery(queryParams);

  const {
    setTemplate: setOperatorTemplate,
    handleContentChange,
    getRenderedTemplate,
  } = useTemplateState({});

  // Transform matterContext to ensure all values are strings and include contact info
  const templateValues = React.useMemo(() => {
    const values: Record<string, string> = {};

    // Add matter context values
    if (matterContext) {
      Object.entries(matterContext).forEach(([key, value]) => {
        values[key] = value || '';
      });
    }

    // Add contact-specific values if a contact is selected
    if (selectedContact) {
      values.contact_name = selectedContact.name || '';
      values.contact_phone = selectedContact.phone || '';
      values.contact_type = selectedContact.type || '';
    }

    return values;
  }, [matterContext, selectedContact]);

  // Update the template when a new one is selected
  React.useEffect(() => {
    if (selectedTemplate) {
      setOperatorTemplate({
        id: selectedTemplate.id,
        prompt: selectedTemplate.prompt_text || '',
        prompt_html: '',
        prompt_json: selectedTemplate.prompt_json,
      } as any); // Type assertion to avoid type mismatch
    }
  }, [selectedTemplate, setOperatorTemplate]);

  // Add a ref to store the validation state
  const hasUnpopulatedVariablesRef = React.useRef(false);

  return (
    <Dialog open onOpenChange={onClose} modal={false}>
      <RightDialogContent className="bg-white h-[95vh] overflow-y-scroll flex flex-col gap-4">
        <DialogTitle className="sr-only">Send Operator Message</DialogTitle>
        <Form {...form}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1 border-b pb-4 border-gray-200">
              <span className="text-xs text-gray-500 font-semibold uppercase">
                TASK - {task.type.name}
              </span>
              <h2 className="text-2xl font-bold">Send Operator Message</h2>
            </div>

            <div className="flex flex-col gap-3">
              <ContactSelector
                form={form}
                matterId={task.matter}
                isMedicalTask={isMedicalTask}
                isInsuranceTask={isInsuranceTask}
                allowCustomPhoneNumber={false}
              />

              <TemplateSelector
                templates={templates}
                isLoadingTemplates={isLoadingTemplates}
                taskType={task.type}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                handleContentChange={handleContentChange}
                onHasUnpopulatedVariablesChange={(hasUnpopulated) => {
                  hasUnpopulatedVariablesRef.current = hasUnpopulated;
                }}
                templateValues={templateValues}
              />

              <RefreshContextButton onRefresh={refetchMatterContext} />

              <MessagePreview
                renderedText={getRenderedTemplate()}
                recipient="@finch-external-ops"
              />
            </div>

            <div className="flex justify-end mt-4">
              <Button
                className="text-white bg-primary font-bold"
                size="lg"
                type="button"
                onClick={form.handleSubmit(
                  async (values) => {
                    // Block submission if there are unpopulated variables
                    if (hasUnpopulatedVariablesRef.current) {
                      toast.error(
                        'Please fill in all required template variables before proceeding',
                      );
                      return;
                    }

                    if (!selectedTemplate) {
                      toast.error('Please select a template before proceeding');
                      return;
                    }

                    try {
                      setIsSubmitting(true);

                      // Send the message to the external ops channel
                      const result = await sendExternalOpsMessage({
                        task_id: task.id,
                        message: getRenderedTemplate(),
                      }).unwrap();

                      toast.success('Message sent to Slack successfully');
                      onClose();
                    } catch (err) {
                      console.error('Failed to send message:', err);
                      toast.error('Failed to send message. Please try again.');
                    } finally {
                      setIsSubmitting(false);
                    }
                  },
                  (errors) => {
                    console.error('Form validation errors:', errors);
                    if (errors.provider) {
                      toast.error(
                        'Please select a valid contact or clear the selection',
                      );
                    }
                    if (!errors.provider && Object.keys(errors).length > 0) {
                      toast.error(
                        'Please fix the form errors before submitting',
                      );
                    }
                  },
                )}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send to Slack'}
              </Button>
            </div>
          </div>
        </Form>
      </RightDialogContent>
    </Dialog>
  );
};
