import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CreateTaskUpdatePayload,
  Task,
  TaskGroup,
  TaskSearchResults,
  TaskStatus,
  TaskType,
  TaskTypeAction,
  TaskUpdate,
  UpdateTaskPayload,
} from '../types/client-intake-types';
import { Contact } from '../types/task-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const tasksApiClient = createApi({
  reducerPath: 'tasksApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Tasks', 'TaskUpdates', 'TaskTypes', 'TaskGroups', 'TaskStatuses'],
  endpoints: (builder) => ({
    // Task List endpoint
    getTaskTypes: builder.query<TaskType[], void>({
      query: () => `api/tasks/types/`,
      providesTags: ['TaskTypes'],
    }),
    getTaskGroups: builder.query<TaskGroup[], void>({
      query: () => `api/tasks/groups/`,
      providesTags: ['TaskGroups'],
    }),
    getTaskStatuses: builder.query<TaskStatus[], void>({
      query: () => `api/tasks/statuses/`,
      providesTags: ['TaskStatuses'],
    }),
    getTask: builder.query<Task, { matterId: string; taskId: string }>({
      query: ({ matterId, taskId }) =>
        `api/matters/${matterId}/tasks/${taskId}/`,
      providesTags: (result, error, { taskId }) => [
        { type: 'Tasks', id: taskId },
      ],
    }),
    getTasks: builder.query<
      TaskSearchResults,
      {
        search?: string;
        limit?: number;
        offset?: number;
        statuses?: string[];
        matter?: string;
        matter__firm?: string;
        ordering?: string;
        assignee?: string;
        group?: string;
      }
    >({
      query: ({
        search,
        limit,
        offset,
        statuses,
        matter,
        matter__firm,
        ordering,
        assignee,
        group,
      }) => {
        // This is unfortunate, but Django's MultipleChoiceFilter
        // expects params like ?status=1&status=2, so we need to
        // manually construct the query params here.
        const params = new URLSearchParams({
          search: search || '',
          limit: limit?.toString() || '',
          offset: offset?.toString() || '',
          matter: matter || '',
          matter__firm: matter__firm || '',
          ordering: ordering || '',
          assignee: assignee || '',
          group: group || '',
        });
        if (statuses) {
          statuses.forEach((status) => {
            params.append('status', status);
          });
        }
        return {
          url: `api/tasks/?${params.toString()}`,
        };
      },
      providesTags: ['Tasks'],
    }),

    createTask: builder.mutation<
      Task,
      { matterId: string; task: UpdateTaskPayload }
    >({
      query: ({ matterId, task }) => ({
        url: `api/matters/${matterId}/tasks/`,
        method: 'POST',
        body: task,
      }),
      invalidatesTags: ['Tasks'],
    }),

    updateTask: builder.mutation<
      Task,
      { matterId: string; taskId: string; task: UpdateTaskPayload }
    >({
      query: ({ matterId, taskId, task }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/`,
        method: 'PATCH',
        body: task,
      }),
      invalidatesTags: ['Tasks'],
    }),

    deleteTask: builder.mutation<void, { matterId: string; taskId: string }>({
      query: ({ matterId, taskId }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),

    // Task Update endpoints
    getTaskUpdates: builder.query<
      TaskUpdate[],
      { matterId: string; taskId: string }
    >({
      query: ({ matterId, taskId }) =>
        `api/matters/${matterId}/tasks/${taskId}/updates/`,
      providesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    createTaskUpdate: builder.mutation<
      TaskUpdate,
      { matterId: string; taskId: string; update: CreateTaskUpdatePayload }
    >({
      query: ({ matterId, taskId, update }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/`,
        method: 'POST',
        body: update,
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    updateTaskUpdate: builder.mutation<
      TaskUpdate,
      {
        matterId: string;
        taskId: string;
        updateId: string;
        update: CreateTaskUpdatePayload;
      }
    >({
      query: ({ matterId, taskId, updateId, update }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/${updateId}/`,
        method: 'PATCH',
        body: update,
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    deleteTaskUpdate: builder.mutation<
      void,
      { matterId: string; taskId: string; updateId: string }
    >({
      query: ({ matterId, taskId, updateId }) => ({
        url: `api/matters/${matterId}/tasks/${taskId}/updates/${updateId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'TaskUpdates', id: taskId },
      ],
    }),

    // TODO(dean): Point this at actions endpoint
    // n.b. not sure if this should take in a taskType or a taskId
    getTaskTypeActions: builder.query<TaskTypeAction[], string>({
      query: (taskType) => `api/task-type-actions/${taskType}/`,
    }),

    // TODO(dean): Point this at providers endpoint
    getProviders: builder.query<Contact[], void>({
      query: () => `api/providers/`,
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetTaskUpdatesQuery,
  useGetTaskTypeActionsQuery,
  useGetProvidersQuery,
  useCreateTaskUpdateMutation,
  useUpdateTaskUpdateMutation,
  useDeleteTaskUpdateMutation,
  useGetTaskStatusesQuery,
  useGetTaskTypesQuery,
  useGetTaskGroupsQuery,
} = tasksApiClient;
