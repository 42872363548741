import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { DetailedMatter } from '../../../services/types/client-intake-types';
import theme from '../../../theme/theme';
import { hasPopulatedFields } from '../../../utils/noteUtils';
import ReadOnlyClientEmploymentNoteSection from './notes/ReadOnlyClientEmploymentNoteSection';
import ReadOnlyDamageNoteSection from './notes/ReadOnlyDamageNoteSection';
import ReadOnlyEmergencyContactNoteSection from './notes/ReadOnlyEmergencyContactNoteSection';
import ReadOnlyIncidentNoteSection from './notes/ReadOnlyIncidentNoteSection';
import ReadOnlyInsuranceNoteSection from './notes/ReadOnlyInsuranceNoteSection';
import ReadOnlyInvolvedPersonNoteSection from './notes/ReadOnlyInvolvedPersonNoteSection';
import ReadOnlyLawEnforcementNoteSection from './notes/ReadOnlyLawEnforcementNoteSection';
import ReadOnlyMatterClientNoteSection from './notes/ReadOnlyMatterClientNoteSection';
import ReadOnlyMedicalNoteSection from './notes/ReadOnlyMedicalNoteSection';
import ReadOnlyPriorLawsuitNoteSection from './notes/ReadOnlyPriorLawsuitNoteSection';
import ReadOnlyPriorMedicalNoteSection from './notes/ReadOnlyPriorMedicalNoteSection';

interface ReadOnlyMatterNotesProps {
  matter: DetailedMatter;
}

// Define sections for both navigation and rendering.
const SECTIONS = [
  { id: 'client', label: 'Client' },
  { id: 'emergency', label: 'Emergency Contact' },
  { id: 'incident', label: 'Accident Details' },
  { id: 'employment', label: 'Employment' },
  { id: 'medical', label: 'Medicals' },
  { id: 'insurance', label: 'Insurance' },
  { id: 'prior-medical', label: 'Prior Medical' },
  { id: 'prior-lawsuit', label: 'Prior Lawsuit' },
  { id: 'law', label: 'Police' },
  { id: 'damage', label: 'Damages' },
  { id: 'involved', label: 'Additional Parties' },
] as const;

const ReadOnlyMatterNotes = ({
  matter,
}: ReadOnlyMatterNotesProps): JSX.Element => {
  const [activeSection, setActiveSection] = useState<string>('incident');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scrollToSection = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Update active section based on scroll position
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting, target }) => {
          if (isIntersecting) {
            setActiveSection(target.id);
          }
        });
      },
      {
        rootMargin: '-20% 0px -75% 0px',
      },
    );

    SECTIONS.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  // Filter sections that have content
  const renderableSections = [
    {
      id: 'client',
      content: matter.clients?.[0] && hasPopulatedFields(matter.clients[0]) && (
        <ReadOnlyMatterClientNoteSection client={matter.clients[0]} />
      ),
    },
    {
      id: 'emergency',
      content: matter.emergency_contact_note &&
        hasPopulatedFields(matter.emergency_contact_note) && (
          <ReadOnlyEmergencyContactNoteSection
            note={matter.emergency_contact_note}
          />
        ),
    },
    {
      id: 'incident',
      content: matter.incident_note &&
        hasPopulatedFields(matter.incident_note) && (
          <ReadOnlyIncidentNoteSection note={matter.incident_note} />
        ),
    },
    {
      id: 'medical',
      content: (matter.medical_notes ?? []).some(hasPopulatedFields) && (
        <ReadOnlyMedicalNoteSection notes={matter.medical_notes ?? []} />
      ),
    },
    {
      id: 'insurance',
      content: (matter.insurance_notes ?? []).some(hasPopulatedFields) && (
        <ReadOnlyInsuranceNoteSection notes={matter.insurance_notes ?? []} />
      ),
    },
    {
      id: 'employment',
      content: matter.client_employment_note &&
        hasPopulatedFields(matter.client_employment_note) && (
          <ReadOnlyClientEmploymentNoteSection
            note={matter.client_employment_note}
          />
        ),
    },
    {
      id: 'prior-medical',
      content: (matter.prior_medical_notes ?? []).some(hasPopulatedFields) && (
        <ReadOnlyPriorMedicalNoteSection
          notes={matter.prior_medical_notes ?? []}
        />
      ),
    },
    {
      id: 'prior-lawsuit',
      content: (matter.prior_lawsuit_notes ?? []).some(hasPopulatedFields) && (
        <ReadOnlyPriorLawsuitNoteSection
          notes={matter.prior_lawsuit_notes ?? []}
        />
      ),
    },
    {
      id: 'damage',
      content: (matter.damage_notes ?? []).some(hasPopulatedFields) && (
        <ReadOnlyDamageNoteSection notes={matter.damage_notes ?? []} />
      ),
    },
  ].filter((section) => section.content);

  // Only show navigation items for sections that have content
  const navigationItems = SECTIONS.filter((section) =>
    renderableSections.some(
      (renderableSection) => renderableSection.id === section.id,
    ),
  );

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          borderBottom: `1px solid ${theme.palette.border.main}`,
          paddingBottom: 3,
          marginBottom: 3,
        }}
      >
        <Typography variant="h2">Complete Intake Form</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {/* Sidebar Navigation */}
        <Box
          sx={{
            position: isMobile ? 'relative' : 'sticky',
            top: isMobile ? 0 : 16,
            height: 'fit-content',
            p: 2,
            pr: isMobile ? 2 : 6,
            minWidth: isMobile ? 'auto' : '120px',
            alignSelf: 'flex-start',
            borderBottom: isMobile
              ? `1px solid ${theme.palette.border.main}`
              : 'none',
            marginBottom: isMobile ? 2 : 0,
          }}
        >
          <Box
            sx={{
              display: isMobile ? 'flex' : 'block',
              flexDirection: isMobile ? 'row' : 'column',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              gap: isMobile ? 2 : 0,
            }}
          >
            {navigationItems.map((section) => (
              <Box
                key={section.id}
                data-section={section.id}
                onClick={() => scrollToSection(section.id)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  pl: 2,
                  height: '24px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    color:
                      activeSection === section.id
                        ? 'primary.main'
                        : 'text.secondary',
                    transition: 'color 0.2s',
                    '&:hover': {
                      color: 'primary.main',
                    },
                    whiteSpace: 'nowrap',
                  }}
                >
                  {section.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Main Content */}
        <Box sx={{ flex: 1 }}>
          {renderableSections.map((section, index) => (
            <Box
              key={section.id}
              id={section.id}
              sx={{
                mb: index < renderableSections.length - 1 ? 3 : 0,
              }}
            >
              {section.content}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReadOnlyMatterNotes;
