import { Box, Typography } from '@mui/material';

import { useGetMattersQuery } from '../../services/api';
import { MattersTable } from './MattersTable';

export const Matters = () => {
  const { data, isLoading } = useGetMattersQuery();

  if (isLoading) {
    return (
      <Box p={3}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box p={3}>
        <Typography>No matters found</Typography>
      </Box>
    );
  }

  return (
    <Box p={0}>
      <MattersTable data={data} />
    </Box>
  );
};
