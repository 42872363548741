import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  useAcceptSharedMatterMutation,
  useDeclineSharedMatterMutation,
  useGetSharedMatterStatusQuery,
} from '../../services/api';
import { DetailedMatter } from '../../services/types/client-intake-types';
import { ActionButtons } from './components/ActionButtons';
import { MatterInfo } from './components/MatterInfo';

interface ReadOnlyMatterHeaderProps {
  matter: DetailedMatter;
}

const ReadOnlyMatterHeader: React.FC<ReadOnlyMatterHeaderProps> = ({
  matter,
}) => {
  const { token } = useParams();
  const { data: sharedMatterStatus } = useGetSharedMatterStatusQuery(token);
  const [acceptSharedMatter] = useAcceptSharedMatterMutation();
  const [declineSharedMatter] = useDeclineSharedMatterMutation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAccept = async () => {
    await acceptSharedMatter({
      token: token!,
    });
  };

  const handleDecline = async () => {
    await declineSharedMatter({
      token: token!,
    });
  };

  return (
    <div
      style={{
        minHeight: isMobile ? 'auto' : 148,
        borderBottom: '1px solid',
        borderBottomColor: 'rgba(0, 0, 0, 0.12)',
        padding: theme.spacing(6, 6, 3, 6),
      }}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        gap={3}
        alignItems={isMobile ? 'stretch' : 'flex-start'}
        justifyContent="space-between"
      >
        <MatterInfo matter={matter} />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: isMobile ? 'flex-start' : 'flex-end',
            mt: isMobile ? 1 : 'auto',
          }}
        >
          {/* TODO: Add back in when we want to allow accept/decline on shared matters */}
          {/* <ActionButtons
            status={sharedMatterStatus?.status}
            token={token!}
            onAccept={handleAccept}
            onDecline={handleDecline}
          /> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default ReadOnlyMatterHeader;
