import { ChevronLeft } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Header } from '@/layout/Header';
import {
  useCreateBlandTemplateMutation,
  useDeleteBlandTemplateMutation,
  useGetBlandTemplatesQuery,
  useUpdateBlandTemplateMutation,
} from '@/services/api/blandtemplateService';
import { CanonicalTaskType } from '@/services/types/task-types';

import { BlandCallTemplate } from '../../services/types/bland-templates.types';
import { EditableTitle } from './components/EditableTitle';
import { TaskTypeSelector } from './components/TaskTypeSelector';
import { TemplateDebugPanel } from './components/TemplateDebugPanel';
import { TemplateEditor } from './components/TemplateEditor';
import { TemplateList } from './components/TemplateList';
import {
  DEFAULT_CONTENT_HTML,
  DEFAULT_CONTENT_JSON,
  DEFAULT_CONTENT_TEXT,
} from './config/editor-config';
import {
  TemplateValues,
  TemplateVariablesProvider,
} from './context/TemplateContext';
import { getSampleTemplateValues } from './hooks/useSampleTemplateValues';
import { useTemplateState } from './hooks/useTemplateState';

const EmptyEditorState = () => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center border-2 border-dashed rounded-lg bg-muted/5">
    <ChevronLeft className="w-12 h-12 mb-4 text-muted-foreground" />
    <h3 className="text-lg font-semibold mb-2">No Template Selected</h3>
    <p className="text-sm text-muted-foreground max-w-sm">
      Select a template from the list on the left to start editing, or create a
      new template using the buttons above.
    </p>
  </div>
);

export const TemplateManagerPage = () => {
  // Template state management
  const [taskTypeFilter, setTaskTypeFilter] = useState<CanonicalTaskType>(
    CanonicalTaskType.ALL,
  );
  const [title, setTitle] = useState<string>('');
  const [selectedTaskType, setSelectedTaskType] = useState<CanonicalTaskType>(
    CanonicalTaskType.ALL,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [templateValues, setTemplateValues] = useState<TemplateValues>(() =>
    getSampleTemplateValues(CanonicalTaskType.ALL),
  );

  const {
    template: selectedTemplate,
    setTemplate: setSelectedTemplate,
    handleContentChange,
    handleSettingsChange,
    getOriginalTemplate,
  } = useTemplateState({
    onTemplateChange: (template) => {
      if (template) {
        setTitle(template.title);
        setSelectedTaskType(template.finch_task_type || CanonicalTaskType.ALL);
      } else {
        setTitle('');
        setSelectedTaskType(CanonicalTaskType.ALL);
      }
    },
  });

  // API mutations and queries
  const { data: templates = [], isLoading: isLoadingTemplates } =
    useGetBlandTemplatesQuery();
  const [createTemplate] = useCreateBlandTemplateMutation();
  const [updateTemplate] = useUpdateBlandTemplateMutation();
  const [deleteTemplate] = useDeleteBlandTemplateMutation();

  // Memoized helper functions
  const hasSettingsChanged = useCallback(
    (current: BlandCallTemplate, original: BlandCallTemplate) => {
      const settingsToCompare = [
        'voice',
        'model',
        'first_sentence',
        'wait_for_greeting',
        'block_interruptions',
        'interruption_threshold',
        'background_track',
        'noise_cancellation',
        'available_tags',
      ];

      return settingsToCompare.some(
        (key) =>
          current[key as keyof BlandCallTemplate] !==
          original[key as keyof BlandCallTemplate],
      );
    },
    [],
  );

  // Memoized dirty check
  const isDirty = useMemo(() => {
    if (!selectedTemplate || !title.trim()) return false;

    const originalTemplate = getOriginalTemplate();
    if (!originalTemplate) return false;

    return (
      selectedTemplate.prompt !== originalTemplate.prompt ||
      selectedTemplate.prompt_html !== originalTemplate.prompt_html ||
      selectedTemplate.prompt_json !== originalTemplate.prompt_json ||
      title !== originalTemplate.title ||
      selectedTaskType !== originalTemplate.finch_task_type ||
      hasSettingsChanged(selectedTemplate, originalTemplate)
    );
  }, [
    selectedTemplate,
    title,
    selectedTaskType,
    getOriginalTemplate,
    hasSettingsChanged,
  ]);

  const handleSaveTemplate = useCallback(async () => {
    if (!selectedTemplate || !title.trim()) return;

    setIsSaving(true);
    try {
      const result = await updateTemplate({
        id: selectedTemplate.id,
        template: {
          ...selectedTemplate,
          title: title.trim(),
          finch_task_type: selectedTaskType,
        },
      }).unwrap();
      setSelectedTemplate(result);
      toast.success('Prompt Updated');
    } catch (error) {
      toast.error('Failed to save prompt');
    } finally {
      setIsSaving(false);
    }
  }, [selectedTemplate, title, selectedTaskType, updateTemplate]);

  const handleCreateNew = useCallback(async () => {
    try {
      const result = await createTemplate({
        title: 'New Prompt',
        finch_task_type: CanonicalTaskType.ALL,
        prompt: DEFAULT_CONTENT_TEXT,
        prompt_html: DEFAULT_CONTENT_HTML,
        prompt_json: JSON.stringify(DEFAULT_CONTENT_JSON),
      }).unwrap();
      setSelectedTemplate(result);
      toast.success('Prompt Created');
    } catch (err) {
      toast.error('Failed to create prompt');
    }
  }, [createTemplate]);

  const handleDuplicate = useCallback(async () => {
    if (!selectedTemplate) return;

    try {
      const result = await createTemplate({
        ...selectedTemplate,
        id: undefined,
        title: `Copy of ${selectedTemplate.title}`,
      }).unwrap();
      setSelectedTemplate(result);
      toast.success('Prompt Duplicated');
    } catch (err) {
      toast.error('Failed to duplicate prompt');
    }
  }, [selectedTemplate, createTemplate]);

  const handleDeleteTemplate = useCallback(
    async (template: BlandCallTemplate) => {
      try {
        await deleteTemplate(template.id);
        if (selectedTemplate?.id === template.id) {
          setSelectedTemplate(null);
        }
        toast.success('Prompt Deleted');
      } catch (err) {
        toast.error('Failed to delete prompt');
      }
    },
    [deleteTemplate, selectedTemplate],
  );

  // Effects
  useEffect(() => {
    setTemplateValues(getSampleTemplateValues(selectedTaskType));
  }, [selectedTaskType]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        e.key === 'Enter' &&
        isDirty &&
        !isSaving
      ) {
        e.preventDefault();
        handleSaveTemplate();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isDirty, isSaving, handleSaveTemplate]);

  return (
    <div className="h-screen w-screen">
      <Header />
      <main className="p-4 flex-1 flex flex-col h-[calc(100vh-4rem)]">
        <div className="grid grid-cols-[300px_1fr] gap-8 flex-1 min-h-0">
          <div className="overflow-hidden flex flex-col">
            <h1 className="text-2xl font-bold mb-4">Bland Prompts</h1>
            <div className="flex-1 min-h-0">
              <TemplateList
                templates={templates}
                isLoading={isLoadingTemplates}
                selectedTemplate={selectedTemplate}
                onSelectTemplate={setSelectedTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onCreateNew={handleCreateNew}
                onDuplicate={handleDuplicate}
              />
            </div>
          </div>

          <div className="overflow-y-auto">
            {!selectedTemplate ? (
              <EmptyEditorState />
            ) : (
              <div className="space-y-4">
                <div className="bg-gray-50/50 rounded-lg border divide-y">
                  <div className="p-4">
                    <EditableTitle title={title} onChange={setTitle} />
                  </div>
                  <div className="p-4 flex items-center justify-between gap-8">
                    <div className="w-[400px]">
                      <span className="block text-xs text-gray-500 font-semibold mb-1">
                        LINKED TASK <span className="text-red-500">*</span>
                      </span>
                      <TaskTypeSelector
                        value={selectedTaskType}
                        onChange={(value) =>
                          setSelectedTaskType(value || CanonicalTaskType.ALL)
                        }
                        className="w-full"
                        label=""
                      />
                    </div>
                    <div className="h-9 flex items-center justify-end w-[200px]">
                      <div
                        className={`transition-all duration-200 ${isDirty && !isSaving ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1 pointer-events-none'}`}
                      >
                        <Button
                          variant="default"
                          onClick={handleSaveTemplate}
                          className="bg-primary text-white hover:bg-primary/90 font-semibold px-8 shrink-0"
                          title={`Save (${navigator.platform.includes('Mac') ? '⌘' : 'Ctrl'}+Enter)`}
                        >
                          <div className="flex items-center">
                            <span>Save Changes</span>
                            <span className="ml-2 text-xs opacity-60">
                              {navigator.platform.includes('Mac')
                                ? '⌘'
                                : 'Ctrl'}
                              +↵
                            </span>
                          </div>
                        </Button>
                      </div>
                      <div
                        className={`absolute transition-all duration-200 ${isSaving ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
                      >
                        <div className="w-4 h-4 border-2 border-primary/30 border-t-primary rounded-full animate-spin" />
                      </div>
                    </div>
                  </div>
                  <TemplateVariablesProvider
                    values={templateValues}
                    setValues={setTemplateValues}
                  >
                    <div className="grid grid-cols-[70%_30%] divide-x">
                      <div>
                        <div className="p-4">
                          <span className="block text-xs text-gray-500 font-semibold uppercase mb-2">
                            PROMPT <span className="text-red-500">*</span>
                          </span>
                          <TemplateEditor
                            template={selectedTemplate}
                            onContentChange={handleContentChange}
                            onSettingsChange={handleSettingsChange}
                          />
                        </div>
                      </div>
                      <div className="p-4">
                        <TemplateDebugPanel taskType={selectedTaskType} />
                      </div>
                    </div>
                  </TemplateVariablesProvider>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
