import { Box, Stack, Typography } from '@mui/material';

import FinchBird from '../base/FinchBird';

const WelcomeScreen = () => {
  return (
    <Stack
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="80vw"
      textAlign="center"
      p={3}
      gap={4}
    >
      <Box mb={3}>
        <FinchBird />
      </Box>
      <Stack gap={4} maxWidth="400px">
        <Typography variant="h1">Welcome to Finch!</Typography>
        <Typography variant="h3">No audio recordings found... yet!</Typography>
        <Typography variant="body1" paragraph>
          Make sure you’ve completed the required onboarding to ensure your user
          is properly set up with your own unique phone number to use Finch.
        </Typography>
        <Typography variant="body1" paragraph>
          Email <a href="mailto:admin@finchlegal.com">admin@finchlegal.com</a>{' '}
          to get this process going. Otherwise, you can call your Finch number,
          dial in your client, and when you hang up the call, refresh this site
          and you should see your generated case information magically appear!
        </Typography>
      </Stack>
    </Stack>
  );
};

export default WelcomeScreen;
