import { LinkIcon, Unlink, Unlink2 } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useFrontMatterLinks } from '@/providers/FrontMatterLinksContext';

const ConfirmationPopover = ({
  isOpen,
  onOpenChange,
  onConfirm,
  title,
  children,
  trigger,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
  title: string;
  children?: React.ReactNode;
  trigger: React.ReactNode;
}) => (
  <Popover open={isOpen} onOpenChange={onOpenChange}>
    <PopoverTrigger asChild>{trigger}</PopoverTrigger>
    <PopoverContent className="w-80 p-4" align="end" sideOffset={8}>
      <div className="space-y-4">
        <div>
          <h4 className="text-foreground/90 mb-3 text-sm font-medium">
            {title}
          </h4>
          {children}
        </div>
        <div className="flex items-center justify-end gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onOpenChange(false)}
            className="text-muted-foreground/70 hover:text-foreground/90 hover:bg-muted/50"
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            size="sm"
            onClick={onConfirm}
            className="bg-destructive/20 text-destructive hover:bg-destructive/30 hover:text-destructive"
          >
            {isOpen ? 'Confirm' : 'Replace'}
          </Button>
        </div>
      </div>
    </PopoverContent>
  </Popover>
);

export const LinkingButton = () => {
  const {
    selectedMatter,
    isLoading,
    linkMatter,
    unlinkMatter,
    matters,
    linkedMatterId,
  } = useFrontMatterLinks();

  const isLinked = selectedMatter?.id === linkedMatterId;
  const hasOtherLinkedMatter = linkedMatterId && !isLinked;
  const linkedMatter = matters.find((m) => m.id === linkedMatterId);
  const [isConfirming, setIsConfirming] = React.useState(false);

  if (!selectedMatter) return null;

  const handleAction = async () => {
    if (isLinked || hasOtherLinkedMatter) {
      await unlinkMatter();
    } else {
      await linkMatter(selectedMatter);
    }
    setIsConfirming(false);
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isLinked || hasOtherLinkedMatter) {
      setIsConfirming(true);
    } else {
      handleAction();
    }
  };

  const getButtonClassName = () => {
    if (isConfirming) return 'text-destructive/70 hover:text-destructive/90';
    if (isLinked || hasOtherLinkedMatter) return 'hover:text-destructive/90';
    return 'hover:text-primary/90';
  };

  const getIconComponent = () => {
    if (isLinked || hasOtherLinkedMatter) return Unlink;
    return LinkIcon;
  };

  const IconComponent = getIconComponent();
  const iconClassName = cn('h-3 w-3 p-0.5');

  const buttonProps = {
    variant: 'ghost' as const,
    size: 'sm' as const,
    onClick: handleButtonClick,
    disabled: isLoading,
    className: cn(
      'text-muted-foreground/50 transition-colors',
      getButtonClassName(),
    ),
  };

  if (!hasOtherLinkedMatter && !isLinked) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button {...buttonProps}>
              <IconComponent className={iconClassName} />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom" className="text-xs">
            Link this conversation to {selectedMatter.name}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  const title = isLinked
    ? `Unlink this conversation from ${selectedMatter.name}?`
    : `Unlink this conversation from ${linkedMatter?.name}?`;

  return (
    <ConfirmationPopover
      isOpen={isConfirming}
      onOpenChange={setIsConfirming}
      onConfirm={handleAction}
      title={title}
      trigger={
        <Button {...buttonProps}>
          <IconComponent className={iconClassName} />
        </Button>
      }
    >
      {hasOtherLinkedMatter && (
        <div className="bg-muted/30 rounded-lg border p-3">
          <p className="text-muted-foreground/70 mb-2 text-sm">
            You need to unlink the current matter before linking to a new one.
          </p>
          <div className="space-y-2.5">
            <div className="flex items-center gap-2">
              <div className="bg-destructive/10 flex h-5 w-5 items-center justify-center rounded-full">
                <Unlink className="text-destructive/70 h-3 w-3" />
              </div>
              <p className="text-muted-foreground/70 text-sm">
                {linkedMatter?.name}
              </p>
            </div>
          </div>
        </div>
      )}
    </ConfirmationPopover>
  );
};
