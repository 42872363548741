import React from 'react';

import { Badge } from '@/components/ui/badge';

interface InfoBadgeProps {
  variant: 'outline' | 'secondary';
  icon?: React.ReactNode;
  text: string | undefined;
  className?: string;
}

/**
 * InfoBadge component for displaying small pieces of information with optional icons
 * Used for displaying metadata like dates, statuses, and categories
 */
export const InfoBadge: React.FC<InfoBadgeProps> = ({
  variant,
  icon,
  text,
  className = '',
}) => {
  if (!text) return null;

  return (
    <Badge
      variant={variant}
      className={`h-4 flex-shrink-0 px-1.5 py-0 text-[10px] font-normal ${className}`}
    >
      {icon && icon}
      {text}
    </Badge>
  );
};

export type { InfoBadgeProps };
