import React, { useEffect } from 'react';

import { useGetMatterDetailsQuery } from '@/services/api/mattersService';
import { NoteType } from '@/services/types/matter-notes-types';

import { MatterNotesHeader } from './components';
import { EndOfNotes } from './components/EndOfNotes';
import { MATTER_NOTE_CONFIGS } from './constants';
import { MatterClientNoteV2 } from './matter-client-v2';
import { MatterNoteSection } from './section/MatterNoteSection';

interface MatterNotesV2Props {
  matterId: string;
  /**
   * Optional array of note types to include. If not provided, all note types will be rendered.
   */
  includeTypes?: NoteType[];
  /**
   * Optional array of note types to exclude. Takes precedence over includeTypes.
   */
  excludeTypes?: NoteType[];
  /**
   * Optional className for the container
   */
  className?: string;
  /**
   * Optional flag to hide client information section
   */
  hideClientInfo?: boolean;
}

/**
 * Simple table of contents component that renders small badges for each section
 */
interface TableOfContentsProps {
  sections: { id: string; title: string }[];
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ sections }) => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      // Prevent default hash behavior
      window.history.pushState(null, '', `#${id}`);

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="flex flex-wrap gap-1.5">
      {sections.map((section) => (
        <button
          key={section.id}
          type="button"
          onClick={() => scrollToSection(section.id)}
          className="text-xs px-2.5 py-0.5 rounded-full bg-slate-50 hover:bg-slate-100 text-slate-500 transition-colors border border-slate-100"
        >
          {section.title}
        </button>
      ))}
    </div>
  );
};

/**
 * Top-level component that renders all MatterNoteSection components for each MatterNoteConfig
 */
export const MatterNotesV2: React.FC<MatterNotesV2Props> = ({
  matterId,
  includeTypes,
  excludeTypes = [],
  className = '',
  hideClientInfo = false,
}) => {
  // Fetch matter details to get the client information
  const { data: matterDetails, isLoading: isLoadingMatter } =
    useGetMatterDetailsQuery(matterId);

  // Filter note configs based on includeTypes and excludeTypes
  const configsToRender = React.useMemo(() => {
    const noteTypes = Object.keys(MATTER_NOTE_CONFIGS) as NoteType[];

    // Filter by includeTypes if provided
    const filteredByInclude = includeTypes
      ? noteTypes.filter((type) => includeTypes.includes(type))
      : noteTypes;

    // Then filter out excludeTypes
    return filteredByInclude
      .filter((type) => !excludeTypes.includes(type))
      .map((type) => MATTER_NOTE_CONFIGS[type]);
  }, [includeTypes, excludeTypes]);

  // Get the primary client (first client in the list)
  const primaryClient = matterDetails?.clients?.[0];

  // Generate sections for table of contents
  const tocSections = React.useMemo(() => {
    const sections = [];

    // Add client info section if applicable
    if (!hideClientInfo && primaryClient) {
      sections.push({ id: 'client-info', title: 'Client Information' });
    }

    // Add all note sections
    configsToRender.forEach((config) => {
      sections.push({ id: `section-${config.type}`, title: config.title });
    });

    return sections;
  }, [configsToRender, hideClientInfo, primaryClient]);

  useEffect(() => {
    // Add CSS to handle fixed header offset for hash navigation
    const style = document.createElement('style');
    style.textContent = `
      [id] {
        scroll-margin-top: 150px;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const stickyHeaderHeight = 'h-[150px]'; // Adjust this value if needed

  return (
    <div>
      <div
        className={`sticky top-0 z-10 bg-white px-6 pb-4 pt-4 pr-5 ${stickyHeaderHeight}`}
        data-sticky-header
      >
        <MatterNotesHeader matterId={matterId} />
        {/* Table of Contents */}
        <TableOfContents sections={tocSections} />
      </div>

      <div className={`space-y-6 px-6 pt-4 pr-5 ${className}`}>
        {/* Client Information Section */}
        {!hideClientInfo && primaryClient && (
          <div id="client-info" className="mb-8">
            <MatterClientNoteV2 client={primaryClient} matterId={matterId} />
          </div>
        )}

        {/* Matter Note Sections */}
        {configsToRender.map((config) => (
          <div id={`section-${config.type}`} key={config.type}>
            <MatterNoteSection config={config} matterId={matterId} />
          </div>
        ))}

        <EndOfNotes />
      </div>
    </div>
  );
};

export default MatterNotesV2;
