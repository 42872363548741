import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import { DetailedMatter } from '../../../services/types/client-intake-types';
import ClientScreenTile from '../../base/ClientScreenTile';

interface ReadOnlySummariesProps {
  matter: DetailedMatter;
}

const SummaryItem: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Grid item xs={12} sm={12} lg={6} key={title}>
      <Typography variant="body1" sx={{ mb: '8px' }}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

const FinchEvaluation: React.FC<{ matter: DetailedMatter | undefined }> = ({
  matter,
}) => {
  return (
    <Grid item xs={12} sm={12} lg={6}>
      <Typography
        variant="body1"
        sx={{ mb: '8px', color: 'var(--Primary, rgba(62, 33, 243, 1))' }}
      >
        Finch Evaluation
      </Typography>
      <Typography variant="body2" color="info.light">
        {matter?.intake_note || ''}
      </Typography>
    </Grid>
  );
};

const IncidentDetails: React.FC<{ matter: DetailedMatter }> = ({ matter }) => {
  const accidentSummary = matter.summaries?.find(
    (summary) => summary.category === 'Accident',
  );

  return (
    <SummaryItem title="Incident Details">
      <Typography variant="body2" color="info.light">
        {accidentSummary?.content || ''}
      </Typography>
    </SummaryItem>
  );
};

const ReadOnlySummaries: React.FC<ReadOnlySummariesProps> = ({ matter }) => {
  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2} sx={{ margin: 'initial' }}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: 1,
          }}
          justifyContent="space-between"
          display="flex"
          alignItems="bottom"
        >
          <Typography variant="h2">Summary</Typography>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            <IncidentDetails matter={matter} />
            <FinchEvaluation matter={matter} />
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default ReadOnlySummaries;
