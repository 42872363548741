import { Box, Container, TextField, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSignupUserMutation } from '../../services/api/userService';
import { userLoggedIn } from '../../state/reducers/root';
import { isPhoneNumberValid } from '../../utils/validation';
import { SecondaryButton } from '../base/Buttons';
import FinchLogoLarge from '../base/FinchLogoLarge';

interface SignUpFormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordAgain: string;
}

const SignUpForm: React.FC = () => {
  const [formState, setFormState] = useState<SignUpFormState>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordAgain: '',
  });
  const [fieldErrors, setFieldErrors] = useState<Partial<SignUpFormState>>({});
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [signupUser] = useSignupUserMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('token');
    setToken(tokenFromUrl);
  }, [location]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFieldErrors({});
    setGeneralError(null);

    const newFieldErrors: Partial<SignUpFormState> = {};

    if (!token) {
      setGeneralError('Invalid or missing token');
      return;
    }

    if (formState.password !== formState.passwordAgain) {
      newFieldErrors.passwordAgain = 'Passwords do not match';
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formState.email)) {
      newFieldErrors.email = 'Please enter a valid email address';
    }

    if (!isPhoneNumberValid(formState.phoneNumber)) {
      newFieldErrors.phoneNumber = 'Please enter a valid 10 digit phone number';
    }

    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      return;
    }

    try {
      const response = await signupUser({
        first_name: formState.firstName,
        last_name: formState.lastName,
        email: formState.email,
        phone_number: formState.phoneNumber,
        password1: formState.password,
        password2: formState.passwordAgain,
        token,
      }).unwrap();

      dispatch(userLoggedIn(response.user));
    } catch (e) {
      Sentry.captureException(e);
      setGeneralError(
        'An unexpected error occurred. Please try again with a new invitation link.',
      );
    }
  };

  const handleChange =
    (prop: keyof SignUpFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
      setFieldErrors({ ...fieldErrors, [prop]: undefined }); // Clear field-specific error on input change
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <FinchLogoLarge />
        <Typography
          variant="body1"
          textAlign="center"
          mt="48px"
          maxWidth="300px"
        >
          Welcome to Finch! Please fill out the form below to create your
          account.
        </Typography>

        {generalError && (
          <Typography
            variant="body1"
            sx={{
              mt: '16px',
              color: 'red',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {generalError}
          </Typography>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: generalError ? '0px' : '16px' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formState.email}
            onChange={handleChange('email')}
            error={!!fieldErrors.email}
            helperText={fieldErrors.email || ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="First Name"
            type="text"
            id="firstName"
            value={formState.firstName}
            onChange={handleChange('firstName')}
            error={!!fieldErrors.firstName}
            helperText={fieldErrors.firstName || ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Last Name"
            type="text"
            id="lastName"
            value={formState.lastName}
            onChange={handleChange('lastName')}
            error={!!fieldErrors.lastName}
            helperText={fieldErrors.lastName || ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phoneNumber"
            label="Phone Number"
            type="text"
            id="phoneNumber"
            value={formState.phoneNumber}
            onChange={handleChange('phoneNumber')}
            error={!!fieldErrors.phoneNumber}
            helperText={fieldErrors.phoneNumber || ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={formState.password}
            onChange={handleChange('password')}
            error={!!fieldErrors.password}
            helperText={fieldErrors.password || ''}
            inputProps={{
              minLength: 8,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password-again"
            label="Password Again"
            type="password"
            id="password-again"
            autoComplete="password-again"
            value={formState.passwordAgain}
            onChange={handleChange('passwordAgain')}
            error={!!fieldErrors.passwordAgain}
            helperText={fieldErrors.passwordAgain || ''}
            inputProps={{
              minLength: 8,
            }}
          />
          <SecondaryButton
            type="submit"
            fullWidth
            disableRipple
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
          >
            Sign Up
          </SecondaryButton>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUpForm;
