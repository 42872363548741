import {
  Close,
  Delete,
  Description,
  Download,
  DriveFileMove,
  OpenInNew,
  Search,
  Upload,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { saveAs } from 'file-saver';
import Fuse from 'fuse.js';
import JSZip from 'jszip';
import { DragEvent, useEffect, useMemo, useState } from 'react';

import useMatterSelection from '../../../hooks/useMatterSelection';
import {
  useCreateBlobMutation,
  useDestroyBlobMutation,
  useUpdateBlobMutation,
} from '../../../services/api/collectionService';
import { CollectionNode } from '../../../services/types/client-intake-types';
import {
  BlobActions,
  DeleteConfirmationDialog,
  FolderItem,
  UploadDialog,
} from './components';
import { UploadProgress } from './types';

export const Documents = () => {
  const { collectionTree, blobs } = useMatterSelection();
  const [selectedCollectionId, setSelectedCollectionId] =
    useState<string>('unset');
  const [isDragging, setIsDragging] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedBlobIds, setSelectedBlobIds] = useState<Set<string>>(
    new Set(),
  );
  const [bulkMoveDialogOpen, setBulkMoveDialogOpen] = useState(false);
  const [bulkMoveCollectionId, setBulkMoveCollectionId] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [createBlob] = useCreateBlobMutation();
  const [updateBlob] = useUpdateBlobMutation();
  const [destroyBlob] = useDestroyBlobMutation();

  const rootNodeId = collectionTree?.id;
  const selectedBlobs = blobs?.filter(
    (blob) =>
      selectedCollectionId === rootNodeId ||
      blob.collection_details.id === selectedCollectionId,
  );

  // Initialize Fuse.js for fuzzy search
  const fuse = useMemo(() => {
    if (!selectedBlobs) return null;
    return new Fuse(selectedBlobs, {
      keys: ['name'],
      threshold: 0.3,
      ignoreLocation: true,
    });
  }, [selectedBlobs]);

  // Filter blobs based on search query
  const filteredBlobs = useMemo(() => {
    if (!searchQuery || !fuse) return selectedBlobs;
    return fuse.search(searchQuery).map((result) => result.item);
  }, [searchQuery, fuse, selectedBlobs]);

  useEffect(() => {
    if (collectionTree && selectedCollectionId === 'unset') {
      setSelectedCollectionId(collectionTree.id);
    }
  }, [collectionTree, selectedCollectionId]);

  // Clean up completed uploads after 3 seconds
  useEffect(() => {
    if (
      uploadProgress.length > 0 &&
      uploadProgress.every(
        (p) => p.status === 'completed' || p.status === 'error',
      )
    ) {
      const timer = setTimeout(() => {
        setUploadProgress([]);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [uploadProgress]);

  const getStatusText = (
    status: UploadProgress['status'],
    progress: number,
  ) => {
    switch (status) {
      case 'completed':
        return 'Completed';
      case 'error':
        return 'Failed';
      default:
        return `${progress}%`;
    }
  };

  const handleUploadToCollection = async (
    files: File[],
    collectionId: string,
  ) => {
    setUploadError(null);
    if (!collectionTree) return;

    // Initialize progress for each file
    setUploadProgress(
      files.map((file) => ({
        fileName: file.name,
        progress: 0,
        status: 'pending' as const,
      })),
    );

    try {
      await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', file.name);
          formData.append('original_filename', file.name);
          formData.append('collection', collectionId);
          formData.append('file_mime_type', file.type);

          // Set upload as started
          setUploadProgress((prev) =>
            prev.map((p, i) =>
              i === index ? { ...p, status: 'uploading', progress: 10 } : p,
            ),
          );

          try {
            const result = await createBlob({
              matterId: collectionTree.matter_id,
              blob: formData as any,
            }).unwrap();

            // Mark upload as completed
            setUploadProgress((prev) =>
              prev.map((p, i) =>
                i === index ? { ...p, status: 'completed', progress: 100 } : p,
              ),
            );
            return result;
          } catch (error) {
            // Mark upload as failed
            setUploadProgress((prev) =>
              prev.map((p, i) =>
                i === index ? { ...p, status: 'error', progress: 0 } : p,
              ),
            );
            throw error;
          }
        }),
      );
    } catch (error) {
      setUploadError('Failed to upload one or more files. Please try again.');
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      await handleUploadToCollection(files, selectedCollectionId);
    }
  };

  const handleBulkMove = async () => {
    if (!collectionTree || !filteredBlobs) return;

    try {
      await Promise.all(
        Array.from(selectedBlobIds).map((blobId) => {
          const blob = filteredBlobs.find((b) => b.id === blobId);
          if (!blob) return Promise.resolve();

          return updateBlob({
            matterId: collectionTree.matter_id,
            blobId: blob.id,
            blob: {
              id: blob.id,
              collection: bulkMoveCollectionId,
            },
          }).unwrap();
        }),
      );
      setBulkMoveDialogOpen(false);
      setSelectedBlobIds(new Set());
      setSelectedCollectionId(bulkMoveCollectionId);
    } catch (error) {
      setUploadError('Failed to move files. Please try again.');
    }
  };

  const handleBulkDeleteConfirm = async () => {
    if (!collectionTree) return;

    try {
      await Promise.all(
        Array.from(selectedBlobIds).map((blobId) =>
          destroyBlob({
            matterId: collectionTree.matter_id,
            blobId,
          }).unwrap(),
        ),
      );
      setSelectedBlobIds(new Set());
    } catch (error) {
      setUploadError('Failed to delete files. Please try again.');
    }
    setDeleteDialogOpen(false);
  };

  const handleBulkDownload = async () => {
    if (!filteredBlobs) return;

    if (selectedBlobIds.size === 1) {
      // Single file download
      const blob = filteredBlobs.find(
        (b) => b.id === Array.from(selectedBlobIds)[0],
      );
      if (blob) {
        window.open(blob.download_url, '_blank');
      }
    } else {
      // Multiple files - create zip
      const zip = new JSZip();
      const blobsToDownload = filteredBlobs.filter((blob) =>
        selectedBlobIds.has(blob.id),
      );

      try {
        // Show loading state
        setUploadError(null);
        const loadingProgress = blobsToDownload.map((blob) => ({
          fileName: blob.name + blob.file_extension,
          progress: 0,
          status: 'pending' as const,
        }));
        setUploadProgress(loadingProgress);

        // Fetch all files and add to zip
        await Promise.all(
          blobsToDownload.map(async (blob, index) => {
            try {
              // Update progress - started downloading
              setUploadProgress((prev) =>
                prev.map((p, i) =>
                  i === index ? { ...p, status: 'uploading', progress: 10 } : p,
                ),
              );

              const response = await fetch(blob.download_url);
              const fileBlob = await response.blob();

              // Update progress - finished downloading
              setUploadProgress((prev) =>
                prev.map((p, i) =>
                  i === index ? { ...p, status: 'uploading', progress: 50 } : p,
                ),
              );

              // Add to zip with folder structure if not in root
              const filePath =
                blob.collection_details.id === rootNodeId
                  ? blob.name
                  : `${blob.collection_details.name}/${blob.name}${blob.file_extension}`;
              zip.file(filePath, fileBlob);

              // Update progress - added to zip
              setUploadProgress((prev) =>
                prev.map((p, i) =>
                  i === index
                    ? { ...p, status: 'completed', progress: 100 }
                    : p,
                ),
              );
            } catch (error) {
              // Mark as failed if download fails
              setUploadProgress((prev) =>
                prev.map((p, i) =>
                  i === index ? { ...p, status: 'error', progress: 0 } : p,
                ),
              );
              throw error;
            }
          }),
        );

        // Generate and download zip
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'documents.zip');
      } catch (error) {
        setUploadError(
          'Failed to download one or more files. Please try again.',
        );
      }
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && filteredBlobs) {
      setSelectedBlobIds(new Set(filteredBlobs.map((blob) => blob.id)));
    } else {
      setSelectedBlobIds(new Set());
    }
  };

  const handleToggleSelect = (blobId: string) => {
    const newSelected = new Set(selectedBlobIds);
    if (newSelected.has(blobId)) {
      newSelected.delete(blobId);
    } else {
      newSelected.add(blobId);
    }
    setSelectedBlobIds(newSelected);
  };

  const renderCollectionOptions = (
    node: CollectionNode,
    level = 0,
  ): JSX.Element[] => {
    const options: JSX.Element[] = [];
    const prefix = '\u00A0'.repeat(level * 2);

    options.push(
      <MenuItem key={node.id} value={node.id}>
        {prefix + (node.name === 'root' ? 'All Files' : node.name)}
      </MenuItem>,
    );

    if (node.children) {
      node.children.forEach((child: CollectionNode) => {
        options.push(...renderCollectionOptions(child, level + 1));
      });
    }

    return options;
  };

  return (
    <Stack
      direction="row"
      spacing={6}
      sx={{
        height: 'calc(100vh - 300px)',
        overflow: 'hidden',
      }}
    >
      {/* Left sidebar with folders */}
      <Box
        sx={{
          minWidth: 300,
          maxWidth: 450,
          flexShrink: 0,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          bgcolor: 'background.paper',
        }}
      >
        <List
          sx={{
            p: 0,
            overflowY: 'auto',
            height: '100%',
            '&::-webkit-scrollbar': {
              width: 8,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 4,
            },
          }}
        >
          {collectionTree && (
            <FolderItem
              key="root"
              node={collectionTree}
              selectedId={selectedCollectionId}
              setSelectedCollectionId={setSelectedCollectionId}
              level={0}
              rootNodeId={rootNodeId}
              onUploadToCollection={handleUploadToCollection}
            />
          )}
        </List>
      </Box>

      {/* Right content area */}
      <Stack
        direction="column"
        sx={{
          width: '100%',
          minWidth: 0,
          flexGrow: 1,
          overflow: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            px: 2,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">
              {`${filteredBlobs?.length || 0} Document${
                filteredBlobs?.length === 1 ? '' : 's'
              }`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              placeholder="Search files..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" color="action" />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setSearchQuery('')}
                      edge="end"
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  height: 30.75,
                  backgroundColor: 'transparent',
                  fontSize: 13,
                  py: 1,
                },
              }}
              sx={{
                width: 200,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderWidth: 1,
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                    borderWidth: 1,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                    borderWidth: 1,
                  },
                },
                '& .MuiInputAdornment-root': {
                  height: 'auto',
                },
                '& input::placeholder': {
                  fontSize: 13,
                },
              }}
            />
            <Button
              startIcon={<Upload />}
              variant="outlined"
              size="small"
              onClick={() => setUploadDialogOpen(true)}
            >
              Upload
            </Button>
          </Stack>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ px: 2, flexShrink: 0 }}>
          {uploadError && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setUploadError(null)}
            >
              {uploadError}
            </Alert>
          )}

          {uploadProgress.length > 0 && (
            <Box sx={{ mb: 2 }}>
              {uploadProgress.map((progress, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ flex: 1, color: 'text.secondary' }}
                    >
                      {progress.fileName}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ color: 'text.secondary' }}
                    >
                      {getStatusText(progress.status, progress.progress)}
                    </Typography>
                  </Stack>
                  <LinearProgress
                    variant="determinate"
                    value={progress.progress}
                    color={progress.status === 'error' ? 'error' : 'primary'}
                    sx={{
                      height: 2,
                      borderRadius: 1,
                      backgroundColor:
                        progress.status === 'error' ? 'error.light' : undefined,
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{
            position: 'relative',
            flexGrow: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            transition: 'all 0.2s ease',
            ...(isDragging && {
              '&::after': {
                content: '"Drop files here"',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(25, 118, 210, 0.08)',
                border: '2px dashed #1976d2',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.25rem',
                color: 'primary.main',
                zIndex: 1,
              },
            }),
          }}
        >
          <List
            sx={{
              p: 0,
              overflowY: 'auto',
              height: '100%',
              '&::-webkit-scrollbar': {
                width: 8,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: 4,
              },
            }}
          >
            {filteredBlobs && filteredBlobs.length > 0 && (
              <ListItem
                sx={{
                  pl: 0,
                  position: 'sticky',
                  top: 0,
                  bgcolor: 'background.paper',
                  zIndex: 1,
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
                secondaryAction={
                  selectedBlobIds.size > 0 && (
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<DriveFileMove />}
                        onClick={() => setBulkMoveDialogOpen(true)}
                      >
                        Move ({selectedBlobIds.size})
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<Download />}
                        onClick={handleBulkDownload}
                      >
                        Download ({selectedBlobIds.size})
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<Delete />}
                        color="error"
                        onClick={() => setDeleteDialogOpen(true)}
                      >
                        Delete ({selectedBlobIds.size})
                      </Button>
                    </Stack>
                  )
                }
              >
                <Checkbox
                  checked={selectedBlobIds.size === filteredBlobs.length}
                  indeterminate={
                    selectedBlobIds.size > 0 &&
                    selectedBlobIds.size < filteredBlobs.length
                  }
                  onChange={handleSelectAll}
                  size="small"
                />
                <ListItemText
                  primary={<Typography variant="body2">Select All</Typography>}
                />
              </ListItem>
            )}
            {filteredBlobs?.map((blob) => (
              <ListItem
                key={blob.id}
                disablePadding
                secondaryAction={
                  collectionTree && (
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        disableRipple
                        edge="end"
                        onClick={() => window.open(blob.download_url, '_blank')}
                        size="small"
                        sx={{ color: 'primary.main' }}
                      >
                        <OpenInNew fontSize="small" />
                      </IconButton>
                      <BlobActions
                        blob={blob}
                        collectionTree={collectionTree}
                        onSuccess={() => {
                          // The queries will automatically refetch due to invalidation
                        }}
                        setSelectedCollectionId={setSelectedCollectionId}
                      />
                    </Stack>
                  )
                }
              >
                <ListItemButton
                  disableRipple
                  onClick={() => handleToggleSelect(blob.id)}
                  sx={{ pl: 0 }}
                >
                  <Checkbox
                    disableRipple
                    checked={selectedBlobIds.has(blob.id)}
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleToggleSelect(blob.id);
                    }}
                  />
                  <ListItemText>
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <ListItemIcon sx={{ minWidth: 36, pl: 2, pr: 2 }}>
                        <Description fontSize="small" color="action" />
                      </ListItemIcon>
                      <ListItemText
                        primary={blob.name}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                        secondary={`${
                          blob.file_size > 1024 * 1024
                            ? `${(blob.file_size / (1024 * 1024)).toFixed(1)} MB`
                            : `${(blob.file_size / 1024).toFixed(1)} KB`
                        } • ${blob.file_extension?.toUpperCase().replace('.', '')} • ${new Date(
                          blob.modified_at,
                        ).toLocaleDateString()} • ${
                          blob.collection_details.id === rootNodeId
                            ? 'Uncategorized'
                            : blob.collection_details?.name
                        }`}
                        secondaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            {filteredBlobs?.length === 0 && searchQuery && (
              <Box
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: 'text.secondary',
                }}
              >
                <Search sx={{ fontSize: 48, mb: 2 }} />
                <Typography variant="body1" gutterBottom>
                  No files found matching &quot;{searchQuery}&quot;
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  Try adjusting your search terms
                </Typography>
              </Box>
            )}
          </List>
        </Box>

        {collectionTree && (
          <UploadDialog
            open={uploadDialogOpen}
            onClose={() => setUploadDialogOpen(false)}
            collectionId={selectedCollectionId}
            collectionTree={collectionTree}
            onUpload={handleUploadToCollection}
          />
        )}

        {/* Bulk Move Dialog */}
        <Dialog
          open={bulkMoveDialogOpen}
          onClose={() => setBulkMoveDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Move Files</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Destination Folder</InputLabel>
              <Select
                value={bulkMoveCollectionId}
                onChange={(e) => setBulkMoveCollectionId(e.target.value)}
                label="Destination Folder"
              >
                {collectionTree && renderCollectionOptions(collectionTree)}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBulkMoveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleBulkMove} variant="contained">
              Move {selectedBlobIds.size} files
            </Button>
          </DialogActions>
        </Dialog>

        {/* Bulk Delete Confirmation Dialog */}
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={handleBulkDeleteConfirm}
          itemCount={selectedBlobIds.size}
        />
      </Stack>
    </Stack>
  );
};
