import { Autocomplete, AutocompleteProps, Box, TextField } from '@mui/material';
import { forwardRef } from 'react';

import countries from './countries.json';

export type CountryCodes = keyof typeof countries;

type BaseCountryFormFieldProps = Omit<
  AutocompleteProps<CountryCodes, false, true, false>,
  'options' | 'renderInput'
>;

interface CountryFormFieldProps extends BaseCountryFormFieldProps {
  countryCodesToDisplay?: CountryCodes[];
}

export const CountryFormField = forwardRef<
  HTMLDivElement,
  CountryFormFieldProps
>(({ countryCodesToDisplay, ...rest }, ref) => {
  return (
    <Autocomplete
      ref={ref}
      options={
        countryCodesToDisplay || (Object.keys(countries) as CountryCodes[])
      }
      // Searching: This is the value that is used for searching
      getOptionLabel={(option) => countries?.[option]?.[0] || ''}
      // Rendering: How each option is displayed
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
              alt=""
            />
            {countries[option][0]}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Choose a country" />
      )}
      isOptionEqualToValue={(option, value) => option === value}
      disableClearable
      {...rest}
    />
  );
});

CountryFormField.displayName = 'CountryFormField';
