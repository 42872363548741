import { createApi } from '@reduxjs/toolkit/query/react';

import {
  DocumentCreationRequest,
  DocumentInstance,
  DocumentTemplate,
  DocumentTemplateFields,
} from '../types/letters-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const lettersApiClient = createApi({
  reducerPath: 'lettersApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    'Document',
    'DocumentTemplates',
    'DocumentFields',
    'DocumentInstances',
    'DocumentInstance',
    'DocumentPreview',
  ] as const,
  endpoints: (builder) => ({
    getDocumentTemplates: builder.query<DocumentTemplate[], string>({
      query: (firmId) => `api/firms/${firmId}/templates/`,
      providesTags: ['DocumentTemplates'],
    }),
    getDocumentTemplateFields: builder.query<
      DocumentTemplateFields,
      {
        firmId: string;
        templateId: string;
        matterId?: string;
      }
    >({
      query: ({ firmId, templateId, matterId }) => ({
        url: `api/firms/${firmId}/templates/${templateId}/fields/`,
        params: { matter_id: matterId },
      }),
      providesTags: (result, error, { templateId }) => [
        { type: 'DocumentFields', id: templateId },
      ],
    }),
    createDocument: builder.mutation<
      DocumentInstance,
      {
        matterId: string;
        data: DocumentCreationRequest;
      }
    >({
      query: ({ matterId, data }) => ({
        url: `api/matters/${matterId}/documents/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['DocumentInstances'],
    }),
    getMatterDocuments: builder.query<
      DocumentInstance[],
      { matterId: string; token?: string }
    >({
      query: ({ matterId, token }) =>
        `api/matters/${matterId}/documents/?token=${token}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'DocumentInstance' as const,
                id,
              })),
              'DocumentInstances',
            ]
          : ['DocumentInstances'],
    }),
    getDocumentInstance: builder.query<
      DocumentInstance,
      {
        matterId: string;
        documentInstanceId: string;
      }
    >({
      query: ({ matterId, documentInstanceId }) =>
        `api/matters/${matterId}/documents/${documentInstanceId}/`,
      providesTags: (result) =>
        result
          ? [{ type: 'DocumentInstance', id: result.id }]
          : ['DocumentInstance'],
    }),
    sendDocumentForSigning: builder.mutation<
      DocumentInstance,
      {
        matterId: string;
        documentInstanceId: string;
      }
    >({
      query: ({ matterId, documentInstanceId }) => ({
        url: `api/matters/${matterId}/documents/${documentInstanceId}/send/`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: 'DocumentInstance', id: result.id }]
          : ['DocumentInstance'],
    }),
    resendDocumentForSigning: builder.mutation<
      DocumentInstance,
      {
        matterId: string;
        documentInstanceId: string;
      }
    >({
      query: ({ matterId, documentInstanceId }) => ({
        url: `api/matters/${matterId}/documents/${documentInstanceId}/resend/`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: 'DocumentInstance', id: result.id }]
          : ['DocumentInstance'],
    }),
    refreshDocument: builder.mutation<
      DocumentInstance,
      { matterId: string; documentInstanceId: string }
    >({
      query: ({ matterId, documentInstanceId }) => ({
        url: `api/matters/${matterId}/documents/${documentInstanceId}/refresh/`,
        method: 'PATCH',
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: 'DocumentInstance', id: result.id }]
          : ['DocumentInstance'],
    }),
  }),
});

// Export hooks for usage in components
export const {
  useGetDocumentTemplatesQuery,
  useGetDocumentTemplateFieldsQuery,
  useCreateDocumentMutation,
  useGetMatterDocumentsQuery,
  useGetDocumentInstanceQuery,
  useSendDocumentForSigningMutation,
  useResendDocumentForSigningMutation,
  useRefreshDocumentMutation,
} = lettersApiClient;
