import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { setRedirectPath } from '../../state/reducers/root';
import {
  selectLoggedIn,
  selectRedirectPath,
} from '../../state/selectors/root-selectors';

const ProtectedRoute: React.FC = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const redirectPath = useSelector(selectRedirectPath);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!loggedIn && !redirectPath) {
      dispatch(
        setRedirectPath(`${location.pathname}?${searchParams.toString()}`),
      );
    }
  }, [loggedIn, redirectPath, location.pathname, dispatch, searchParams]);

  if (loggedIn) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
