import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import {
  useGetDocumentTemplatesQuery,
  useGetMatterDocumentsQuery,
  useRefreshDocumentMutation,
} from '../../services/api/lettersService';
import { DetailedMatter } from '../../services/types/client-intake-types';
import {
  DOCUMENT_INSTANCE_STATUS_DISPLAY_NAMES,
  DocumentInstance,
  DocumentTemplate,
} from '../../services/types/letters-types';
import { TertiaryButton } from '../base/Buttons';
import { CreateLetter } from './CreateLetter';
import { PreviewLetter } from './PreviewLetter';

interface LettersProps {
  matter: DetailedMatter;
}

const LetterCard = ({
  letter,
  matter,
}: {
  letter: DocumentInstance;
  matter: DetailedMatter;
}) => {
  const isContract = letter.document_template.requires_signatures;
  const [showPreview, setShowPreview] = useState(false);
  const TemplateIcon = isContract ? (
    <Tooltip title="Contract - Requires signatures">
      <HistoryEduIcon color="primary" />
    </Tooltip>
  ) : (
    <Tooltip title="Letter">
      <ArticleIcon color="primary" />
    </Tooltip>
  );

  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={0} alignItems="flex-start">
          <Stack direction="row" spacing={2} alignItems="center">
            {TemplateIcon}
            <Typography variant="h3">
              {letter.document_template.name}
            </Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {DOCUMENT_INSTANCE_STATUS_DISPLAY_NAMES[letter.status]}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button variant="contained" onClick={() => setShowPreview(true)}>
            Preview
          </Button>
        </Stack>
      </Stack>

      <Divider />
      <PreviewLetter
        open={showPreview}
        onClose={() => setShowPreview(false)}
        document={letter}
        matter={matter}
      />
    </>
  );
};

const CreatedLettersList = ({ matter }: { matter: DetailedMatter }) => {
  const { data: letters, isLoading } = useGetMatterDocumentsQuery({
    matterId: matter.id,
  });
  const [refreshDocument] = useRefreshDocumentMutation();

  useEffect(() => {
    const refreshAllDocuments = async () => {
      if (letters?.length) {
        // Create an array of promises for all refresh operations
        const refreshPromises = letters.map((letter) =>
          refreshDocument({
            matterId: matter.id,
            documentInstanceId: letter.id,
          }),
        );

        // Wait for all refresh operations to complete
        await Promise.all(refreshPromises);
      }
    };

    refreshAllDocuments();
  }, [letters, matter.id, refreshDocument]);

  if (isLoading) {
    return <Typography>Loading letters...</Typography>;
  }

  if (!letters?.length) {
    return <Typography>No letters created yet</Typography>;
  }

  return (
    <Stack spacing={4}>
      {letters.map((letter) => (
        <LetterCard key={letter.id} letter={letter} matter={matter} />
      ))}
    </Stack>
  );
};

export const Template = ({ template }: { template: DocumentTemplate }) => {
  const TemplateIcon = template.requires_signatures ? (
    <Tooltip title="Requires signatures">
      <HistoryEduIcon color="primary" />
    </Tooltip>
  ) : (
    <ArticleIcon color="primary" />
  );

  return (
    <Stack
      direction="row"
      spacing={4}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      minWidth={480}
    >
      <Stack direction="row" spacing={3} alignItems="center">
        {TemplateIcon}
        <Typography variant="h3">{template.name}</Typography>
      </Stack>

      {template.requires_signatures && (
        <Typography variant="body3">Requires signatures</Typography>
      )}
    </Stack>
  );
};

const LettersMenu = ({
  anchorEl,
  open,
  onClose,
  templates,
  onTemplateSelect,
}: {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  templates?: DocumentTemplate[];
  onTemplateSelect: (template: DocumentTemplate) => void;
}) => (
  <Menu
    anchorEl={anchorEl}
    id="letters-menu"
    open={open}
    onClose={onClose}
    onClick={onClose}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  >
    {templates?.map((template) => (
      <MenuItem key={template.id} onClick={() => onTemplateSelect(template)}>
        <Template template={template} />
      </MenuItem>
    ))}
  </Menu>
);

const LettersHeader = ({
  onAddClick,
  open,
}: {
  onAddClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ marginBottom: 3 }}
  >
    <Typography variant="h2">Letters</Typography>
    <TertiaryButton
      disableRipple
      onClick={onAddClick}
      aria-controls={open ? 'letters-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      <AddIcon />
    </TertiaryButton>
  </Stack>
);

export const Letters = ({ matter }: LettersProps) => {
  const { data: documentTemplates } = useGetDocumentTemplatesQuery(
    matter.firm.id,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTemplate, setSelectedTemplate] =
    useState<DocumentTemplate | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTemplateSelect = (template: DocumentTemplate) => {
    setSelectedTemplate(template);
  };

  const handleCreateLetterClose = () => {
    setSelectedTemplate(null);
  };

  return (
    <Stack direction="column" spacing={4}>
      <Box>
        <LettersHeader onAddClick={handleClick} open={open} />
        <LettersMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          templates={documentTemplates}
          onTemplateSelect={handleTemplateSelect}
        />
        <Divider />
      </Box>

      <CreatedLettersList matter={matter} />

      {selectedTemplate && (
        <CreateLetter
          open={Boolean(selectedTemplate)}
          onClose={handleCreateLetterClose}
          matter_id={matter.id}
          template={selectedTemplate}
          firm_id={matter.firm.id}
        />
      )}
    </Stack>
  );
};
