import {
  ApplicationAttachment,
  ApplicationAttachmentId,
  ApplicationCancelToken,
  ApplicationComment,
  ApplicationCommentId,
  ApplicationDraft,
  ApplicationLink,
  ApplicationMessage,
  ApplicationMessageId,
  ApplicationMessageList,
  ApplicationPaginationToken,
  ApplicationRecipient,
  ApplicationRecipientList,
  ApplicationSingleConversation,
  ConversationContext,
  SingleConversationContext,
} from '@frontapp/plugin-sdk';

import { BaseMatter } from '@/services/types/client-intake-types';
import { MatterLinkResult } from '@/types/frontMatter';

import { extractUrlsFromMessages } from './urlExtractor';

export interface FrontAttachment {
  id: string;
  name: string;
  size: number;
  content_type: string;
}

/**
 * Interface for an attachment with its associated message ID
 */
export interface MessageAttachment {
  message_id: ApplicationMessageId;
  attachment: FrontAttachment;
}

/**
 * Type guard that checks if the current Front context is a single conversation view.
 * @param context The conversation context to check
 * @returns {boolean} Returns true if the context is a SingleConversationContext, enabling type narrowing
 */
export function isSingleConversationContext(
  context: ConversationContext,
): context is SingleConversationContext {
  return !!(
    context &&
    context.type === 'singleConversation' &&
    'conversation' in context &&
    context.conversation
  );
}

/**
 * Lists all ApplicationLinks pointing at Finch Matter URLs
 * @param context The conversation context
 * @returns {ApplicationLink[]} Array of matter links
 */
export function listMatterLinks(
  context: ConversationContext,
): ApplicationLink[] {
  if (!isSingleConversationContext(context)) return [];

  return context.conversation.links.filter((link) =>
    link.externalUrl.includes('/matters/'),
  );
}

/**
 * Checks if the current conversation is not linked to any Matter
 * @param context The conversation context
 * @returns {boolean} True if the conversation is not linked to any matter, false otherwise
 */
export function conversationIsUnlinked(context: ConversationContext): boolean {
  return listMatterLinks(context).length === 0;
}

/**
 * Checks if the current conversation is linked to at least one Matter
 * @param context The conversation context
 * @returns {boolean} True if the conversation is linked to at least one matter, false otherwise
 */
export function conversationIsLinked(context: ConversationContext): boolean {
  return listMatterLinks(context).length >= 1;
}

/**
 * Checks if the current conversation is linked to multiple matters
 * @param context The conversation context
 * @returns {boolean} True if the conversation is linked to multiple matters, false otherwise
 */
export function conversationIsLinkedToMultipleMatters(
  context: ConversationContext,
): boolean {
  return listMatterLinks(context).length > 1;
}

/**
 * Returns a single ApplicationLink pointing at a Finch Matter URL
 * @param context The conversation context
 * @returns {ApplicationLink | null} The first ApplicationLink or null if no links are found, or if there are multiple links
 */
export function getMatterLink(
  context: ConversationContext,
): ApplicationLink | null {
  // if there is only one link, return it
  const links = listMatterLinks(context);
  if (links.length === 1) {
    return links[0];
  }

  return null;
}

/**
 * Extracts the matter ID from the first matter link in the current conversation
 * @param context The conversation context
 * @returns {string | null} The extracted matter ID or null if no links are found
 */
export function getMatterId(context: ConversationContext): string | null {
  const link = getMatterLink(context);
  if (!link) return null;

  const url = link.externalUrl;
  const matches = url.match(/\/matters\/([^/]+)/);
  return matches?.[1] || null;
}

/**
 * Builds a matter URL from a matter ID
 * @param id The matter ID
 * @returns {string} The matter URL
 */
export function buildMatterUrl(id: string): string {
  return `${window.location.origin}/matters/${id}`;
}

/**
 * Adds a matter link to the conversation
 * @param context The conversation context
 * @param matter The matter to link
 * @returns {Promise<MatterLinkResult>} Result of the operation
 */
export async function addMatterLink(
  context: ConversationContext,
  matter: BaseMatter,
  force = false,
): Promise<MatterLinkResult> {
  if (!isSingleConversationContext(context)) {
    return {
      success: false,
      links: [],
      error: 'Invalid context',
    };
  }

  if (conversationIsLinked(context) && !force) {
    return {
      success: false,
      links: [],
      error: 'Matter link already exists',
    };
  }

  try {
    const matterUrl = buildMatterUrl(matter.id);
    await context.addLink(matterUrl, `${matter.name} - ${matter.firm.name}`);

    return {
      success: true,
      links: listMatterLinks(context),
    };
  } catch (err) {
    const error =
      err instanceof Error ? err.message : 'Failed to add matter link';
    return {
      success: false,
      links: listMatterLinks(context),
      error,
    };
  }
}

/**
 * Removes all matter links from the conversation
 * @param context The conversation context
 * @returns {Promise<MatterLinkResult>} Result of the operation
 */
export async function removeMatterLinks(
  context: ConversationContext,
): Promise<MatterLinkResult> {
  if (!isSingleConversationContext(context)) {
    return {
      success: false,
      links: [],
      error: 'Invalid context',
    };
  }

  try {
    const existingLinks = listMatterLinks(context);
    if (existingLinks.length === 0) {
      return {
        success: true,
        links: [],
      };
    }

    await Promise.all(existingLinks.map((link) => context.removeLink(link.id)));

    return {
      success: true,
      links: [],
    };
  } catch (err) {
    const error =
      err instanceof Error ? err.message : 'Failed to remove matter links';
    return {
      success: false,
      links: listMatterLinks(context),
      error,
    };
  }
}

/**
 * Lists all attachments from all messages in a conversation
 * @param context The conversation context
 * @param paginationToken Optional pagination token for fetching more messages
 * @returns {Promise<MessageAttachment[]>} Array of attachments with their message IDs
 */
export async function listConversationAttachments(
  context: ConversationContext,
  paginationToken?: ApplicationPaginationToken,
): Promise<MessageAttachment[]> {
  if (!isSingleConversationContext(context)) return [];

  try {
    // Fetch messages from the conversation
    const messageList: ApplicationMessageList =
      await context.listMessages(paginationToken);

    // Extract attachments from each message
    const attachments: MessageAttachment[] = messageList.results
      .filter((message) => message.content !== undefined)
      .flatMap((message) =>
        message.content!.attachments.map((attachment) => ({
          message_id: message.id,
          attachment: {
            id: attachment.id,
            name: attachment.name,
            size: attachment.size,
            content_type: attachment.contentType,
          },
        })),
      );

    // If there are more messages, recursively fetch them
    if (messageList.nextPageToken) {
      const moreAttachments = await listConversationAttachments(
        context,
        messageList.nextPageToken,
      );
      return [...attachments, ...moreAttachments];
    }

    return attachments;
  } catch (error) {
    console.error('Error fetching conversation attachments:', error);
    return [];
  }
}

/**
 * Downloads an attachment from a conversation
 * @param context The conversation context
 * @param messageId ID of the message containing the attachment
 * @param attachmentId ID of the attachment to download
 * @returns {Promise<File | undefined>} The downloaded file or undefined if download failed
 */
export async function downloadConversationAttachment(
  context: ConversationContext,
  messageId: ApplicationMessageId | ApplicationCommentId,
  attachmentId: ApplicationAttachmentId,
): Promise<File | undefined> {
  if (!isSingleConversationContext(context)) return undefined;

  try {
    return await context.downloadAttachment(messageId, attachmentId);
  } catch (error) {
    console.error('Error downloading attachment:', error);
    return undefined;
  }
}

/**
 * Lists all recipients in a conversation
 * @param context The conversation context
 * @param paginationToken Optional pagination token for fetching more recipients
 * @returns {Promise<ApplicationRecipient[]>} Array of recipients in the conversation
 */
export async function listConversationRecipients(
  context: ConversationContext,
  paginationToken?: ApplicationPaginationToken,
): Promise<ApplicationRecipient[]> {
  if (!isSingleConversationContext(context)) return [];

  try {
    // Fetch recipients from the conversation
    const recipientList: ApplicationRecipientList =
      await context.listRecipients(paginationToken);

    // Extract recipients from the list
    const recipients: ApplicationRecipient[] = [...recipientList.results];

    // If there are more recipients, recursively fetch them
    if (recipientList.nextPageToken) {
      const moreRecipients = await listConversationRecipients(
        context,
        recipientList.nextPageToken,
      );
      return [...recipients, ...moreRecipients];
    }

    return recipients;
  } catch (error) {
    console.error('Error fetching conversation recipients:', error);
    return [];
  }
}

/**
 * Gets all messages in a conversation
 * @param context The conversation context
 * @param paginationToken Optional pagination token for fetching more messages
 * @returns {Promise<ApplicationMessage[]>} Array of messages in the conversation
 */
export async function getConversationMessages(
  context: ConversationContext,
  paginationToken?: ApplicationPaginationToken,
): Promise<ApplicationMessage[]> {
  if (!isSingleConversationContext(context)) return [];

  try {
    // Fetch messages from the conversation
    const messageList: ApplicationMessageList =
      await context.listMessages(paginationToken);

    // Get messages from the list
    const messages: ApplicationMessage[] = [...messageList.results];

    // If there are more messages, recursively fetch them
    if (messageList.nextPageToken) {
      const moreMessages = await getConversationMessages(
        context,
        messageList.nextPageToken,
      );
      return [...messages, ...moreMessages];
    }

    return messages;
  } catch (error) {
    console.error('Error fetching conversation messages:', error);
    return [];
  }
}

/**
 * Extracts URLs from all messages in a conversation
 * @param context The conversation context
 * @returns {Promise<string[]>} Array of unique URLs found in the conversation
 */
export async function extractUrlsFromConversation(
  context: ConversationContext,
): Promise<string[]> {
  if (!isSingleConversationContext(context)) return [];

  try {
    const messages = await getConversationMessages(context);
    return extractUrlsFromMessages(messages);
  } catch (error) {
    console.error('Error extracting URLs from conversation:', error);
    return [];
  }
}
