import { MessageAttachment } from '../frontService';
import type { Form } from './form-types';
import type { MatterClient } from './matter-client-types';
import type {
  ClientEmploymentNote,
  DamageNote,
  EmergencyContactNote,
  IncidentNote,
  InsuranceNote,
  InvolvedPersonNote,
  LawEnforcementNote,
  MedicalNote,
  PriorLawsuitNote,
  PriorMedicalNote,
} from './matter-notes-types';
import type {
  CanonicalTaskGroup,
  CanonicalTaskStatus,
  CanonicalTaskType,
} from './task-types';
import type { User } from './user-types';

export enum CrashDocsStatus {
  REPORT_FOUND = 'REPORT_FOUND',
  REPORT_NOT_FOUND = 'REPORT_NOT_FOUND',
  ERROR = 'ERROR',
}

export interface CrashDocsLookupResponse {
  link?: string;
  status: CrashDocsStatus;
  lookup_id?: string;
}

export interface CrashDocsLookupStatusResponse {
  lookup_id: string;
  status: string; // 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'FAILED'
  message?: string;
  report_url?: string;
  created_at: string;
  updated_at: string;
  search_details: {
    state: string;
    jurisdiction: string;
    last_name: string;
    incident_date: string;
    report_id: string | null;
  };
  task_id: string;
  estimated_seconds_remaining?: number;
}

interface TaskAssignee {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface TaskTypeAction {
  id: string;
  name: string;
  order: number;
  template: string;
}

interface TaskSearchResults {
  count: number;
  next: string | null;
  previous: string | null;
  results: Task[];
}

interface PhoneCallAction {
  template: string;
  phone_number: string;
  provider: string;
  interrupt: boolean;
  bland_call_id?: string;
  call_status?: string;
}

interface TaskActionResult {
  url: string;
  status: string;
}

interface TaskStatus {
  id: string;
  order: number;
  name: string;
  canonical_status: CanonicalTaskStatus;
  description: string;
}

interface TaskType {
  id: string;
  order: number;
  name: string;
  group: string; // id of the group this task type belongs to
  description: string;
  canonical_type: CanonicalTaskType;
}

interface TaskGroup {
  id: string;
  order: number;
  name: string;
  description: string;
  canonical_group: CanonicalTaskGroup;
}

// Types for Task and TaskUpdate
interface Task {
  id: string;
  matter: string;
  name: string;
  description?: string;
  start_date?: string;
  due_date?: string;
  completed_at?: string;
  // This parameter is confusingly named. We're using it to mark tasks as "urgent"
  // and sort them to the top of the list.
  order?: number;
  created_at: string;
  modified_at: string;
  type: TaskType;
  group: TaskGroup;
  status: TaskStatus;
  attachments: Blob[];
  firm_name: string;
  firm: string;
  matter_name: string;
  assignee: TaskAssignee | null;
}

interface TaskUpdate {
  id: string;
  task: string;
  update: string;
  visibility: string;
  agent: string | null;
  user: string;
  created_at: string;
  modified_at: string;
  attachments: Blob[];
}

interface UpdateTaskPayload {
  name?: string | null;
  description?: string | null;
  due_date?: string | null;
  start_date?: string | null;
  completed_at?: string | null;
  order?: number | null;
  type_id?: string | null;
  group_id?: string | null;
  status_id?: string | null;
  attachments?: string[] | null;
  assignee_id?: string | null;
}

export enum TaskUpdateVisibility {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
}

interface CreateTaskUpdatePayload {
  update: string;
  visibility: TaskUpdateVisibility;
  attachments?: string[] | null;
}

interface SharedMatter {
  expires_at: string;
  status: string;
}

interface ShareMatterLink {
  token: string;
  matter_id: string;
  email: string;
  created_at: string;
  last_accessed_at: string | null;
  expires_at: string;
}

interface BlobUpdateRequest {
  id: string;
  name?: string;
  collection?: string;
}

interface BlobCreateRequest {
  name: string;
  file: string;
  original_filename: string | null;
  collection: string;
  file_mime_type: string;
}

interface Blob {
  id: string;
  collection_details: CollectionDetails;
  created_at: string;
  modified_at: string;
  name: string;
  original_filename?: string | null;
  download_url: string;
  file_hash: string;
  file_size: number;
  file_extension?: string | null;
  file_mime_type?: string | null;
}

interface CollectionDetails {
  id: string;
  name: string;
  parent_id: string | null;
  matter_id: string;
  depth: number;
  path: string;
  child_count: number;
  blob_count: number;
  created_at: string;
  modified_at: string;
}

interface Collection {
  id: string;
  created_at: string;
  modified_at: string;
  path: string;
  depth: number;
  numchild: number;
  name: string;
}

interface CollectionNode {
  id: string;
  name: string;
  parent_id: string | null;
  matter_id: string;
  depth: number;
  path: string;
  child_count: number;
  blob_count: number;
  created_at: string;
  modified_at: string;
  children?: CollectionNode[];
}

interface Engagement {
  id: string;
  status: string;
  letter_path: string;
  matter: string;
  client: string;
}

interface Conversation {
  id: string;
  format: string;
  name: string;
  transcript_path: string;
  audio_path: string;
  duration: number;
  firm_user: string;
  start_timestamp: string;
  end_timestamp: string;
  matter: string;
  client: string;
}

type MatterKeyConsiderationSeverity = 'Green' | 'Yellow' | 'Red' | 'Gray';

export const MatterKeyConsiderationSeverities: MatterKeyConsiderationSeverity[] =
  ['Green', 'Yellow', 'Red', 'Gray'];

export enum MatterKeyConsiderationCategory {
  // Mandatory Flags
  CLEAR_LIABILITY = 'Clear Liability',
  SIGNIFICANT_INJURIES = 'Significant Injuries',
  INSURANCE_COVERAGE = 'Insurance Coverage',

  // Yellow Flags
  POTENTIALLY_TOUGH_CLIENT = 'Potentially Tough Client',
  CLIENT_IS_A_MINOR = 'Client is a Minor',
  HAD_PREVIOUS_REPRESENTATION = 'Had Previous Representation',
  PRE_EXISTING_INJURIES = 'Pre-existing Injuries',
  MINIMAL_PROPERTY_DAMAGE = 'Minimal Property Damage',
  PRIOR_LEGAL_ISSUES = 'Prior Legal Issues',
  SUBSTANCE_USE = 'Substance Use',
  INCONSISTENT_STATEMENTS = 'Inconsistent Statements',
  INSUFFICIENT_EVIDENCE = 'Insufficient Evidence',

  // Red Flags
  CLIENT_CRIMINAL_ACTIVITY = 'Client Criminal Activity',
  EVIDENCE_CONTRADICTS_CLIENT = 'Evidence Contradicts Client',
  STATUTE_OF_LIMITATIONS = 'Statute of Limitations',
  MEDICAL_TREATMENT_DELAYED = 'Medical Treatment Delayed',
  PAST_MEDICAL_HISTORY = 'Past Medical History',
}

export const MandatoryFlags: MatterKeyConsiderationCategory[] = [
  MatterKeyConsiderationCategory.CLEAR_LIABILITY,
  MatterKeyConsiderationCategory.SIGNIFICANT_INJURIES,
  MatterKeyConsiderationCategory.INSURANCE_COVERAGE,
];

interface MatterKeyConsideration {
  id: string;
  category: MatterKeyConsiderationCategory;
  severity: MatterKeyConsiderationSeverity;
  content: string;
}

interface MatterSummary {
  id: string;
  category: 'Accident' | 'Damages';
  content: string;
}

interface ActionItem {
  id: string;
  content: string;
  matter: string;
}

export interface Firm {
  id: string;
  name: string;
}

export enum MatterType {
  MOTOR_VEHICLE_ACCIDENT = 'Motor Vehicle Accident',
  MEDICAL_MALPRACTICE = 'Medical Malpractice',
  MISCELLANEOUS = 'Miscellaneous',
  BREACH_OF_EMPLOYMENT_CONTRACT = 'Breach of Employment Contract',
  SELLER_NON_DISCLOSURE = 'Seller Non-Disclosure',
  PREMISES_LIABILITY = 'Premises Liability',
  BROKER_NEGLIGENCE = 'Broker Negligence',
  UNKNOWN = 'Unknown',
}

interface BaseMatter {
  id: string;
  name: string;
  type: string;
  matter_type?: MatterType;
  status?: string;
  firm: Firm;
  date_of_loss?: string;
  date_of_birth?: string;
  city?: string;
  state?: string;
  statute_of_limitations_date?: string;
}

interface BaseDetailedMatter extends BaseMatter {
  creator?: User;
  engagements: Engagement[];
  clients: MatterClient[];
  conversations: Conversation[];
  key_considerations: MatterKeyConsideration[];
  summaries: MatterSummary[];
  action_items: ActionItem[];
  form?: Form;
  intake_note?: string;
  incident_note?: IncidentNote;
  emergency_contact_note?: EmergencyContactNote;
  prior_medical_notes?: PriorMedicalNote[];
  prior_lawsuit_notes?: PriorLawsuitNote[];
  client_employment_note?: ClientEmploymentNote;
  involved_person_notes?: InvolvedPersonNote[];
  law_enforcement_notes?: LawEnforcementNote[];
  medical_notes?: MedicalNote[];
  insurance_notes?: InsuranceNote[];
  damage_notes: DamageNote[];
  details_type?: string;
  details?: any;
  modified_at?: string;
}

type DetailedMatter = BaseDetailedMatter;

type MatterUpdate = Partial<
  Pick<BaseMatter, 'name' | 'matter_type' | 'status'>
> & {
  intake_note?: string;
};

export enum MatterStatus {
  DEMAND_PREPARATION = 'Demand Preparation',
  NEW_LEAD = 'New Lead',
  DEMAND_LETTER = 'Demand Letter',
  INTAKE = 'Intake',
  HIDDEN = 'Hidden',
  TREATMENT = 'Treatment',
  READY_FOR_DEMAND = 'Ready for Demand',
  CASE_SETUP = 'Case Setup',
  CLIENT_DROPPED = 'Client Dropped',
  IN_PROGRESS = 'In Progress',
  TREATMENT_COMPLETE = 'Treatment Complete',
}

export interface CreateMatterRequest {
  firm: string;
  name: string;
  status?: MatterStatus;
  type?: string;
  creator: string;
  clients: { phone_numbers: { phone_number: string }[] }[];
  front_attachments?: MessageAttachment[];
  front_attachment_urls?: string[];
}

export type {
  BaseMatter,
  Blob,
  BlobCreateRequest,
  BlobUpdateRequest,
  Collection,
  CollectionDetails,
  CollectionNode,
  Conversation,
  CreateTaskUpdatePayload,
  DetailedMatter,
  Engagement,
  MatterKeyConsideration,
  MatterKeyConsiderationSeverity,
  MatterSummary,
  MatterUpdate,
  PhoneCallAction,
  SharedMatter,
  ShareMatterLink,
  Task,
  TaskActionResult,
  TaskGroup,
  TaskSearchResults,
  TaskStatus,
  TaskType,
  TaskTypeAction,
  TaskUpdate,
  UpdateTaskPayload,
};
