import { useCallback, useRef, useState } from 'react';

import { OperatorTemplate } from '../../../services/types/operator-templates-types';
import { deepClone } from '../../../utils/comparison';

interface UseOperatorTemplateStateProps {
  initialTemplate?: OperatorTemplate | null;
  onTemplateChange?: (template: OperatorTemplate | null) => void;
}

export const useOperatorTemplateState = ({
  initialTemplate = null,
  onTemplateChange,
}: UseOperatorTemplateStateProps = {}) => {
  const [template, setTemplate] = useState<OperatorTemplate | null>(
    initialTemplate,
  );
  const [renderedTemplate, setRenderedTemplate] = useState<string>('');

  // Store editor content separately from main template
  const [editorContent, setEditorContent] = useState<{
    text: string;
    html: string;
    json: string;
    renderedText: string;
  }>({
    text: initialTemplate?.prompt_text || '',
    html: '',
    json: initialTemplate?.prompt_json || '',
    renderedText: '',
  });

  const originalTemplateRef = useRef<OperatorTemplate | null>(
    initialTemplate ? deepClone(initialTemplate) : null,
  );

  // This won't trigger template change callbacks
  const handleContentChange = useCallback(
    ({
      text,
      html,
      json,
      renderedText,
    }: {
      text: string;
      html: string;
      json: string;
      renderedText: string;
    }) => {
      setEditorContent({
        text,
        html,
        json,
        renderedText,
      });
      setRenderedTemplate(renderedText);
    },
    [],
  );

  // Use this to get the current state with latest editor content
  const getCurrentTemplateState = useCallback(() => {
    if (!template) return null;

    // Make sure we're returning a new object with the latest editor content
    return {
      ...template,
      prompt_text: editorContent.text,
      prompt_json: editorContent.json,
    };
  }, [template, editorContent]);

  const getOriginalTemplate = useCallback(() => {
    // Return a copy of the original template to prevent accidental mutations
    return originalTemplateRef.current
      ? { ...originalTemplateRef.current }
      : null;
  }, []);

  const setTemplateAndOriginal = useCallback(
    (newTemplate: OperatorTemplate | null) => {
      // Update both the template and original template
      setTemplate(newTemplate);

      // Also update editor content when template changes
      if (newTemplate) {
        // Preserve the rendered text when updating from the same template
        // (like after saving) by checking if the IDs match
        const isSameTemplate = template && template.id === newTemplate.id;

        setEditorContent((prevContent) => ({
          text: newTemplate.prompt_text || '',
          html: '',
          json: newTemplate.prompt_json || '',
          // Keep the existing rendered text if it's the same template (e.g., after saving)
          renderedText: isSameTemplate ? prevContent.renderedText : '',
        }));

        // Store a deep copy of the original template to prevent reference issues
        originalTemplateRef.current = deepClone(newTemplate);
      } else {
        originalTemplateRef.current = null;
      }

      if (onTemplateChange) {
        onTemplateChange(newTemplate);
      }
    },
    [onTemplateChange, template],
  );

  return {
    template,
    setTemplate: setTemplateAndOriginal,
    handleContentChange,
    getOriginalTemplate,
    getRenderedTemplate: () => renderedTemplate,
    getCurrentTemplateState,
    editorContent,
  };
};
