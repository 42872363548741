import { useRef } from 'react';

import { Command } from '@/components/ui/command';
import {
  FrontMatterLinksProvider,
  useFrontMatterLinks,
} from '@/providers/FrontMatterLinksContext';

import FrontMatterHeaderWithSearch from './FrontMatterHeaderWithSearch';
import { MatterDetailsSection } from './MatterDetailsSection';

// Component content
const FrontMatterPanelContent = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { selectedMatter, error, matterDetailsLoading, matterDetails } =
    useFrontMatterLinks();

  const formattedDetailsError = error
    ? new Error(
        String(
          typeof error === 'object' && error !== null && 'message' in error
            ? (error as { message: string }).message
            : 'Error loading details',
        ),
      )
    : null;

  return (
    <div className="flex max-w-xl flex-col overflow-hidden rounded-md border-b border-gray-200">
      <Command>
        <FrontMatterHeaderWithSearch searchInputRef={searchInputRef} />
        <div className="relative">
          {selectedMatter && (
            <div className="opacity-100 transition-all duration-300">
              <MatterDetailsSection
                detailsLoading={matterDetailsLoading}
                detailsError={formattedDetailsError}
                matterDetails={matterDetails || null}
              />
            </div>
          )}
        </div>
      </Command>
    </div>
  );
};

// Wrap the main export with the provider
export const FrontMatterPanel = () => {
  return (
    <FrontMatterLinksProvider>
      <FrontMatterPanelContent />
    </FrontMatterLinksProvider>
  );
};
