import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { Modal } from '../base/Modal';
import LoggedInUser from '../LoggedInUser';

interface ProfileSettingsModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const ProfileSettingsModal: React.FC<ProfileSettingsModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Account</Typography>
        <Divider />
        <LoggedInUser />
      </Stack>
    </Modal>
  );
};

export const ProfileSettings: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="flex items-center gap-2 bg-transparent hover:bg-transparent h-16 px-4">
            <AccountCircleIcon sx={{ color: 'white' }} />
            <h3 className="text-primary-light hover:bg-transparent font-bold">
              Account
            </h3>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48 mt-1 bg-white rounded-md shadow-lg">
          <DropdownMenuItem
            onClick={() => setIsOpen(true)}
            className="px-4 py-2.5 text-sm hover:bg-gray-100 cursor-pointer font-medium text-gray-700"
          >
            Profile Settings
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => navigate('/template-editor')}
            className="px-4 py-2.5 text-sm hover:bg-gray-100 cursor-pointer font-medium text-gray-700"
          >
            Bland Templates
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => navigate('/operator-template-editor')}
            className="px-4 py-2.5 text-sm hover:bg-gray-100 cursor-pointer font-medium text-gray-700"
          >
            Operator Templates
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ProfileSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
