import { EditorOptions } from '@tiptap/core';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

import { TemplateNodeExtension } from '../extensions/template-node-extension';

// Default content for the editor
export const DEFAULT_CONTENT_JSON = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'You are a paralegal calling from ',
        },
        {
          type: 'template',
          attrs: {
            id: 'firmName',
          },
        },
        {
          type: 'text',
          text: '.',
        },
      ],
    },
  ],
};

export const DEFAULT_CONTENT_TEXT =
  'You are a paralegal calling from [firmName].';

export const DEFAULT_CONTENT_HTML =
  '<p>You are a paralegal calling from <span data-type="template" data-id="firmName"></span>.</p>';

// Extensions configuration
export const extensions = [
  Document,
  Text,
  Paragraph,
  TemplateNodeExtension,
  History,
];

// Complete editor configuration
export const templateEditorConfig: Partial<EditorOptions> = {
  content: DEFAULT_CONTENT_JSON,
  editable: true,
  autofocus: true,
  injectCSS: true,
  extensions,
  editorProps: {
    attributes: {
      class:
        'p-3 text-xs focus:outline-none bg-gray-100 border border-gray-200 rounded-md',
    },
  },
  enableInputRules: true,
  enablePasteRules: true,
  enableContentCheck: true,
  enableCoreExtensions: true,
};
