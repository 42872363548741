import { AlertTriangle, Check } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { Task } from '@/services/types/client-intake-types';
import { CanonicalTaskStatus } from '@/services/types/task-types';

interface TaskWarningDropdownProps {
  task: Task;
  onUrgentToggle: (urgent: boolean) => void;
}

export const TaskWarningDropdown = ({
  task,
  onUrgentToggle,
}: TaskWarningDropdownProps) => {
  const isActive =
    task.status.canonical_status !== CanonicalTaskStatus.COMPLETED &&
    task.status.canonical_status !== CanonicalTaskStatus.NOT_APPLICABLE;
  const isUrgent = isActive && task.order !== undefined && task.order < 0;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className={cn(
            'h-8 gap-1 px-2 text-xs font-bold rounded focus-visible:ring-0 focus-visible:outline-none min-w-[100px] border',
            isUrgent
              ? 'text-purple-700 hover:bg-purple-100'
              : 'hover:bg-gray-100',
          )}
        >
          {isUrgent && <AlertTriangle size={16} className="text-purple-700" />}
          {isUrgent ? 'Urgent' : 'Set Priority'}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="w-[180px] bg-white border border-gray-200"
      >
        <DropdownMenuItem
          onClick={() => onUrgentToggle(true)}
          className={cn(
            'cursor-pointer gap-2',
            isUrgent && 'bg-purple-100 text-purple-700',
          )}
        >
          <Check size={16} className={isUrgent ? 'opacity-100' : 'opacity-0'} />
          <span className="font-semibold">Mark as Urgent</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onUrgentToggle(false)}
          className={cn(
            'cursor-pointer gap-2',
            !isUrgent && isActive && 'bg-gray-100',
          )}
        >
          <Check
            size={16}
            className={!isUrgent && isActive ? 'opacity-100' : 'opacity-0'}
          />
          <span className="font-semibold">Normal Priority</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
