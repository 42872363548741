import { Info } from 'lucide-react';
import React from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

import { ScheduleDescriptionResponse } from './types';
import { formatDateTime } from './utils';

interface AdvancedDetailsProps {
  scheduleDetails: ScheduleDescriptionResponse;
}

export const AdvancedDetails: React.FC<AdvancedDetailsProps> = ({
  scheduleDetails,
}) => {
  const renderStatusBadge = () => {
    if (!scheduleDetails) return null;

    const isPaused = scheduleDetails.temporal_schedule?.state?.paused || false;
    let badgeClass = 'px-2 text-xs';
    let statusText = scheduleDetails.database_schedule.status;

    if (isPaused) {
      badgeClass += ' bg-amber-50 text-amber-700 border-amber-200';
      statusText = 'Paused';
    } else if (scheduleDetails.database_schedule.status === 'active') {
      badgeClass += ' bg-green-50 text-green-700 border-green-200';
    } else {
      badgeClass += ' bg-red-50 text-red-700 border-red-200';
    }

    return (
      <Badge variant="outline" className={cn(badgeClass)}>
        {statusText}
      </Badge>
    );
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="bg-white rounded-lg shadow-sm"
    >
      <AccordionItem value="advanced-details" className="border-none">
        <AccordionTrigger className="px-3 py-2 hover:no-underline">
          <div className="flex items-center">
            <Info className="h-4 w-4 mr-2 text-primary flex-shrink-0" />
            <h4 className="font-medium">Advanced Details</h4>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-3 pb-3">
          <div className="space-y-2 text-xs">
            <div className="grid grid-cols-2 gap-1">
              <div className="text-muted-foreground">Schedule ID:</div>
              <div className="font-mono truncate">
                {scheduleDetails.database_schedule.id}
              </div>

              <div className="text-muted-foreground">Temporal ID:</div>
              <div className="font-mono truncate">
                {scheduleDetails.temporal_schedule?.id || 'N/A'}
              </div>

              <div className="text-muted-foreground">Start Date:</div>
              <div>
                {scheduleDetails.temporal_schedule?.start
                  ? formatDateTime(scheduleDetails.temporal_schedule.start)
                  : 'N/A'}
              </div>

              <div className="text-muted-foreground">End Date:</div>
              <div>
                {scheduleDetails.temporal_schedule?.end
                  ? formatDateTime(scheduleDetails.temporal_schedule.end)
                  : 'N/A'}
              </div>

              <div className="text-muted-foreground">Status:</div>
              <div className="flex items-center">{renderStatusBadge()}</div>

              {scheduleDetails.temporal_schedule?.info && (
                <>
                  <div className="text-muted-foreground">Total Actions:</div>
                  <div>
                    {scheduleDetails.temporal_schedule.info.action_count || 0}
                  </div>
                </>
              )}

              {scheduleDetails.temporal_schedule?.state?.note && (
                <>
                  <div className="text-muted-foreground">Note:</div>
                  <div className="truncate">
                    {scheduleDetails.temporal_schedule.state.note}
                  </div>
                </>
              )}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
