import { Phone } from 'lucide-react';
import * as React from 'react';

import { Button } from '@/components/ui/button';

interface CallClientButtonProps {
  phoneNumber: string;
}

export const CallClientButton: React.FC<CallClientButtonProps> = ({
  phoneNumber,
}) => {
  if (!phoneNumber) return null;

  return (
    <Button
      variant="outline"
      className="font-semibold text-sm cursor-pointer hover:bg-gray-100"
      asChild
    >
      <a href={`tel:${phoneNumber}`} className="flex items-center gap-2">
        <Phone className="h-4 w-4 p-0" />
        Call Client
      </a>
    </Button>
  );
};
