import * as Sentry from '@sentry/react';

import { BASE_API_URL } from '../../utils/constants';

export const audioService = {
  async fetchPresignedUrl(filePath: string): Promise<string | null> {
    try {
      // Remove the "s3://" prefix from the filePath
      const cleanedFilePath = filePath.replace('s3://', '');

      // Fetch the presigned URL from the Django backend
      const response = await fetch(
        `${BASE_API_URL}/api/files/presigned-url/${cleanedFilePath}/`,
        {
          credentials: 'include',
        },
      );

      if (!response.ok) {
        Sentry.captureException('Error fetching the presigned URL');

        return null;
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      Sentry.captureException(error);

      return null;
    }
  },

  async fetchFileFromS3(url: string): Promise<Blob | null> {
    try {
      // Fetch the actual file from S3 using the presigned URL
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the file from S3');
      }

      return await response.blob();
    } catch (error) {
      Sentry.captureException(error);

      return null;
    }
  },

  // Generate a presigned url and fetch the audio file from S3 with
  // the generated URL
  async fetchAudioFile(fileKey: string): Promise<Blob | null> {
    try {
      const url = await this.fetchPresignedUrl(fileKey);

      if (!url) {
        throw new Error('No presigned URL available');
      }

      return await this.fetchFileFromS3(url);
    } catch (error) {
      Sentry.captureException(error);

      return null;
    }
  },
};
