import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { usePatchMatterKeyConsiderationMutation } from '../../../services/api';
import {
  DetailedMatter,
  MatterKeyConsideration,
} from '../../../services/types/client-intake-types';
import EditableTextArea from '../../base/EditableTextArea';
import { useNotification } from '../../contexts/NotificationContext';
import { KeyConsiderationHeader } from './KeyConsiderationHeader';

export const KeyConsideration: React.FC<{
  selectedMatterData: DetailedMatter;
  consideration: MatterKeyConsideration;
}> = ({ selectedMatterData, consideration }) => {
  const [content, setContent] = useState(consideration.content);
  const [severity, setSeverity] = useState(consideration.severity);
  const [isHovered, setIsHovered] = useState(false);
  const notify = useNotification();
  const [patchMatterKeyConsideration] =
    usePatchMatterKeyConsiderationMutation();

  useEffect(() => {
    setContent(consideration.content);
    setSeverity(consideration.severity);
  }, [consideration.content, consideration.severity]);

  const handleSave = useCallback(async () => {
    try {
      await patchMatterKeyConsideration({
        matter_id: selectedMatterData.id,
        id: consideration.id,
        patch: { content, severity },
      }).unwrap();
      notify('Key consideration updated', 'success', 3000);
    } catch {
      notify('Error updating key consideration', 'error', 3000);
    }
  }, [
    content,
    notify,
    patchMatterKeyConsideration,
    selectedMatterData.id,
    consideration.id,
    severity,
  ]);

  useEffect(() => {
    if (
      content !== consideration.content ||
      severity !== consideration.severity
    ) {
      handleSave();
    }
  }, [
    content,
    handleSave,
    severity,
    consideration.content,
    consideration.severity,
  ]);

  const handleTextAreaSave = async (value: string) => {
    setContent(value);
  };

  const handleChange = (changes: Partial<MatterKeyConsideration>) => {
    if (changes.severity) {
      setSeverity(changes.severity);
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      key={consideration.id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <KeyConsiderationHeader
        matterId={selectedMatterData.id}
        consideration={{ ...consideration, severity }}
        onChange={handleChange}
        isHovered={isHovered}
      />
      <EditableTextArea
        hideBorder
        noPadding
        value={content}
        onSave={handleTextAreaSave}
      />
    </Grid>
  );
};
