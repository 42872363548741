import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetLiveTranscriptQuery } from '../../services/api/callService';
import Transcript from '../transcript/Transcript';

export const Call: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: transcript } = useGetLiveTranscriptQuery(id ?? '');

  if (!transcript) {
    return <div>Loading...</div>;
  }

  // The Call Details UI should be its own component
  return (
    <div>
      {transcript.call && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Call Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Start Time:</strong> {transcript.call.start_timestamp}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>End Time:</strong> {transcript.call.end_timestamp}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Attorney Number:</strong>{' '}
                {transcript.call.attorney_number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Client Number:</strong> {transcript.call.client_number}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Transcript transcript={transcript.transcript_segments} />
    </div>
  );
};
