import { ChevronDownIcon } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { useGetTaskStatusesQuery } from '@/services/api';
import { TaskStatus } from '@/services/types/client-intake-types';
import { TaskStatusColors } from '@/services/types/task-types';

export const TaskStatusBadge = ({
  status,
  onStatusChange,
  className,
}: {
  status: TaskStatus;
  onStatusChange: (status: TaskStatus) => void;
  className?: string;
}) => {
  const { data: statuses } = useGetTaskStatusesQuery();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
        <span
          className={cn(
            `min-w-[100px] flex flex-row justify-between items-center gap-1 px-2 py-2 rounded text-xs font-bold cursor-pointer ${TaskStatusColors[status.canonical_status]}`,
            className,
          )}
        >
          {status.name}
          <ChevronDownIcon size={12} />
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="bg-white rounded-sm border-gray-300"
        onClick={(e) => e.stopPropagation()}
      >
        {statuses?.map((statusOption) => (
          <DropdownMenuItem
            key={statusOption.id}
            onClick={() => onStatusChange(statusOption)}
            className={cn(
              'cursor-pointer mb-1 font-bold text-xs hover:bg-gray-100/50',
              TaskStatusColors[statusOption.canonical_status],
            )}
          >
            {statusOption.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
