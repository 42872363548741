import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import {
  selectLoggedIn,
  selectRedirectPath,
} from '../../state/selectors/root-selectors';

export const PublicRoute: React.FC = () => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const redirectPath = useSelector(selectRedirectPath);

  if (isLoggedIn) {
    // If user is logged in, redirect to the intended URL or default route
    return <Navigate to={redirectPath || '/'} replace />;
  }

  return <Outlet />;
};
