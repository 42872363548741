import equal from 'fast-deep-equal';
import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';

import {
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from '@/services/api/matterNotesService';
import { BaseMatterNote, NoteType } from '@/services/types/matter-notes-types';

import {
  MatterNoteConfig,
  MatterNoteFieldConfig,
  NoteCardinality,
} from '../types';
import { DisplayField } from './components/DisplayField';
import { FormActions } from './components/FormActions';
import { FormField } from './components/FormField';

export interface MatterNoteFormProps {
  config: MatterNoteConfig;
  matterId: string;
  note?: BaseMatterNote | null;
  onSaved?: (note: BaseMatterNote) => void;
  onCancelled?: () => void;
  onDeleted?: () => void;
}

// Shared layout component for both display and edit modes
interface FieldsLayoutProps {
  fields: MatterNoteFieldConfig[];
  renderField: (field: MatterNoteFieldConfig, index: number) => React.ReactNode;
}

const FieldsLayout: React.FC<FieldsLayoutProps> = ({ fields, renderField }) => {
  return (
    <dl className="divide-y divide-gray-100">
      {fields.map((field, index) => (
        <div key={field.name} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-900">{field.label}</dt>
          <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
            {renderField(field, index)}
          </dd>
        </div>
      ))}
    </dl>
  );
};

// Display component using shared layout
interface DisplayMatterNoteProps {
  fields: MatterNoteFieldConfig[];
  values: Record<string, any>;
}

const DisplayMatterNote: React.FC<DisplayMatterNoteProps> = ({
  fields,
  values,
}) => {
  const nonEmptyFields = fields.filter((field) => {
    const value = values[field.name];
    return (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      // Handle arrays and objects
      (Array.isArray(value) ? value.length > 0 : true) &&
      (typeof value === 'object' && value !== null
        ? Object.keys(value).length > 0
        : true)
    );
  });

  const hiddenFieldsCount = fields.length - nonEmptyFields.length;

  return (
    <>
      {nonEmptyFields.length > 0 ? (
        <FieldsLayout
          fields={nonEmptyFields}
          renderField={(field) => (
            <div className={field.multiline ? 'whitespace-pre-wrap' : ''}>
              <DisplayField field={field} value={values[field.name]} />
            </div>
          )}
        />
      ) : (
        <p className="text-sm text-gray-500 italic pb-2 ">Nothing here yet.</p>
      )}

      {hiddenFieldsCount > 0 && (
        <div className="border-t border-gray-100 pt-2">
          <p className="text-xs text-gray-400 italic">
            {hiddenFieldsCount} empty{' '}
            {hiddenFieldsCount === 1 ? 'field' : 'fields'} not shown.
          </p>
        </div>
      )}
    </>
  );
};

// Edit component using shared layout
interface EditMatterNoteProps {
  fields: MatterNoteFieldConfig[];
  values: Record<string, any>;
  noteId: string;
  onFieldChange: (name: string, value: any) => void;
}

const EditMatterNote: React.FC<EditMatterNoteProps> = ({
  fields,
  values,
  noteId,
  onFieldChange,
}) => {
  return (
    <FieldsLayout
      fields={fields}
      renderField={(field) => (
        <FormField
          note_id={noteId}
          field={field}
          value={values[field.name]}
          onChange={(value) => onFieldChange(field.name, value)}
        />
      )}
    />
  );
};

export const MatterNoteForm: React.FC<MatterNoteFormProps> = ({
  config,
  matterId,
  note,
  onSaved,
  onCancelled,
  onDeleted,
}) => {
  const isNew = !note?.id;
  const initialValues = note || { matter: matterId };
  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialValues);
  const [isEditing, setIsEditing] = useState(isNew);
  const [hasChanges, setHasChanges] = useState(false);

  const [createNote, { isLoading: isCreating }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isUpdating }] = useUpdateNoteMutation();
  const [deleteNote, { isLoading: isDeleting }] = useDeleteNoteMutation();

  // Reset form when note changes
  useEffect(() => {
    if (note) {
      setFormValues(note);
    }
  }, [note]);

  // Track if form has changes compared to original note
  useEffect(() => {
    if (!isNew) {
      setHasChanges(!equal(note, formValues));
    }
  }, [formValues, note, isNew]);

  const handleFieldChange = (name: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    if (isNew) {
      onCancelled?.();
    } else {
      setFormValues(note || { matter: matterId });
      setIsEditing(false);
    }
  };

  const handleSave = async () => {
    try {
      let savedNote;

      if (isNew) {
        savedNote = await createNote({
          matterId,
          noteType: config.type as NoteType,
          note: formValues,
        }).unwrap();
        toast.success(`${config.title} created successfully`);
      } else if (hasChanges && note?.id) {
        savedNote = await updateNote({
          matterId,
          noteId: note.id,
          noteType: config.type as NoteType,
          note: formValues,
        }).unwrap();
        toast.success(`${config.title} updated successfully`);
      } else {
        savedNote = note;
      }

      setIsEditing(false);
      if (savedNote) {
        onSaved?.(savedNote);
      }
    } catch (error) {
      toast.error(
        error instanceof Error
          ? `Error: ${error.message}`
          : `Failed to save ${config.title.toLowerCase()}`,
      );
    }
  };

  const handleDelete = async () => {
    if (!note?.id) return;

    try {
      await deleteNote({
        matterId,
        noteId: note.id,
        noteType: config.type as NoteType,
      }).unwrap();

      toast.success(`${config.title} deleted successfully`);
      onDeleted?.();
    } catch (error) {
      toast.error(
        error instanceof Error
          ? `Error: ${error.message}`
          : `Failed to delete ${config.title.toLowerCase()}`,
      );
    }
  };

  return (
    <div>
      <div className="rounded-lg border shadow-sm overflow-hidden group">
        <div
          className={`transition-all duration-200 ${
            !isEditing && !isNew
              ? 'opacity-70 group-hover:opacity-100'
              : 'opacity-100'
          }`}
        >
          <FormActions
            isEditing={isEditing}
            isNew={isNew}
            cardinality={config.cardinality}
            onEdit={handleEdit}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={note?.id ? handleDelete : undefined}
            isSaving={isCreating || isUpdating}
            isDeleting={isDeleting}
            title={config.title}
          />
        </div>
        <div className="p-4 sm:p-6 pt-2 sm:pt-0">
          <div>
            {isEditing ? (
              <EditMatterNote
                fields={config.fields}
                values={formValues}
                noteId={note?.id || ''}
                onFieldChange={handleFieldChange}
              />
            ) : (
              <DisplayMatterNote fields={config.fields} values={formValues} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
