import { Close, Description, Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DragEvent, useEffect, useRef, useState } from 'react';

import { CollectionNode } from '../../../../services/types/client-intake-types';
import { UploadDialogProps } from '../types';

export const UploadDialog = ({
  open,
  onClose,
  collectionId,
  collectionTree,
  onUpload,
}: UploadDialogProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedCollectionId, setSelectedCollectionId] =
    useState(collectionId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedCollectionId(collectionId);
  }, [collectionId]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
    }
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      await onUpload(selectedFiles, selectedCollectionId);
      onClose();
      setSelectedFiles([]);
    }
  };

  const renderCollectionOptions = (node: CollectionNode, level = 0) => {
    const options = [];
    const prefix = '\u00A0'.repeat(level * 2);

    options.push(
      <MenuItem key={node.id} value={node.id}>
        {prefix + (node.name === 'root' ? 'All Files' : node.name)}
      </MenuItem>,
    );

    if (node.children) {
      node.children.forEach((child) => {
        options.push(...renderCollectionOptions(child, level + 1));
      });
    }

    return options;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ m: 2 }}>
          <InputLabel>Upload to</InputLabel>
          <Select
            value={selectedCollectionId}
            onChange={(e) => setSelectedCollectionId(e.target.value)}
            label="Upload to"
          >
            {renderCollectionOptions(collectionTree)}
          </Select>
        </FormControl>

        <Box
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current?.click()}
          sx={{
            border: '2px dashed',
            borderColor: isDragging ? 'primary.main' : 'divider',
            borderRadius: 1,
            p: 4,
            m: 4,
            textAlign: 'center',
            cursor: 'pointer',
            bgcolor: isDragging ? 'primary.lighter' : 'background.default',
            transition: 'all 0.2s ease',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            multiple
          />
          <Upload
            sx={{ fontSize: 48, mb: 2, color: 'text.secondary', opacity: 0.5 }}
          />
          <Typography variant="body1" gutterBottom>
            Drag and drop files here or click to browse
          </Typography>
        </Box>

        {selectedFiles.length > 0 && (
          <List dense sx={{ mt: 2 }}>
            {selectedFiles.map((file, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFiles((files) =>
                        files.filter((_, i) => i !== index),
                      );
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <Description fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondary={`${(file.size / 1024).toFixed(1)} KB`}
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleUpload}
          variant="contained"
          disabled={selectedFiles.length === 0}
        >
          Upload{' '}
          {selectedFiles.length > 0 &&
            `(${selectedFiles.length} ${selectedFiles.length === 1 ? 'file' : 'files'})`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
