import { zodResolver } from '@hookform/resolvers/zod';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

const formSchema = z.object({
  state: z.string().min(1, { message: 'State is required' }),
  jurisdiction: z.string().min(1, { message: 'Jurisdiction is required' }),
  last_name: z.string().min(1, { message: 'Last name is required' }),
  incident_date: z.string().min(1, { message: 'Incident date is required' }),
  report_id: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

interface CrashReportLookupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
  isLoading?: boolean;
}

export const CrashReportLookupModal: React.FC<CrashReportLookupModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      state: '',
      jurisdiction: '',
      last_name: '',
      incident_date: '',
      report_id: '',
    },
  });

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onFormSubmit = (data: FormValues) => {
    onSubmit(data);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Police Report Lookup</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="state" className="text-right">
                State
              </Label>
              <div className="col-span-3">
                <Input
                  id="state"
                  placeholder="TX - Texas"
                  {...register('state')}
                  className={errors.state ? 'border-red-500' : ''}
                />
                {errors.state && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.state.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="jurisdiction" className="text-right">
                Jurisdiction
              </Label>
              <div className="col-span-3">
                <Input
                  id="jurisdiction"
                  placeholder="Hurst Police Department"
                  {...register('jurisdiction')}
                  className={errors.jurisdiction ? 'border-red-500' : ''}
                />
                {errors.jurisdiction && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.jurisdiction.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="last_name" className="text-right">
                Last Name
              </Label>
              <div className="col-span-3">
                <Input
                  id="last_name"
                  placeholder="Smith"
                  {...register('last_name')}
                  className={errors.last_name ? 'border-red-500' : ''}
                />
                {errors.last_name && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.last_name.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="incident_date" className="text-right">
                Incident Date
              </Label>
              <div className="col-span-3">
                <Input
                  id="incident_date"
                  type="date"
                  {...register('incident_date')}
                  className={errors.incident_date ? 'border-red-500' : ''}
                />
                {errors.incident_date && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.incident_date.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="report_id" className="text-right">
                Report ID
              </Label>
              <div className="col-span-3">
                <Input
                  id="report_id"
                  placeholder="Optional"
                  {...register('report_id')}
                />
                <p className="text-xs text-gray-500 mt-1">Optional</p>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Searching...' : 'Search'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
