import { Grid } from '@mui/material';

import { IncidentNote } from '../../../../services/types/matter-notes-types';
import { hasPopulatedFields } from '../../../../utils/noteUtils';
import ReadOnlyNoteField from './ReadOnlyNoteField';
import ReadOnlyNoteSection from './ReadOnlyNoteSection';

interface ReadOnlyIncidentNoteSectionProps {
  note?: IncidentNote;
}

const getDayOfWeek = (dateString: string | null | undefined): string => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  } catch (error) {
    return '';
  }
};

const ReadOnlyIncidentNoteSection = ({
  note,
}: ReadOnlyIncidentNoteSectionProps) => {
  if (!note || !hasPopulatedFields(note)) return null;

  return (
    <ReadOnlyNoteSection title="Accident Details">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ReadOnlyNoteField label="DATE OF LOSS" value={note.date_of_loss} />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyNoteField label="LOCATION" value={note.location} />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyNoteField
            label="DAY OF WEEK"
            value={getDayOfWeek(note.date_of_loss)}
          />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyNoteField label="TIME OF LOSS" value={note.time_of_loss} />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyNoteField
            label="WEATHER CONDITIONS"
            value={note.weather_conditions}
          />
        </Grid>
        <Grid item xs={6}>
          <ReadOnlyNoteField label="ACCIDENT TYPE" value={note.incident_type} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="DESCRIPTION" value={note.description} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField
            label="INJURIES DESCRIBED"
            value={note.injuries_described}
          />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="HOSPITAL" value={note.hospital} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="AMBULANCE" value={note.ambulance} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField
            label="CURRENT TREATMENT"
            value={note.current_treatment}
          />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="POLICE REPORT" value={note.police_report} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="WITNESSES" value={note.witnesses} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="STATEMENTS" value={note.statements} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField label="PHOTOS" value={note.photos} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyNoteField
            label="REPRESENTATION"
            value={note.representation}
          />
        </Grid>
      </Grid>
    </ReadOnlyNoteSection>
  );
};

export default ReadOnlyIncidentNoteSection;
