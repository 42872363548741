import {
  ApplicationAttachment,
  ApplicationMessageId,
} from '@frontapp/plugin-sdk';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import * as z from 'zod';

import { Combobox } from '@/components/tasks/CreateTaskModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useFrontContext } from '@/providers/frontContext';
import { useGetFirmsQuery } from '@/services/api/firmsService';
import { useCreateMatterMutation } from '@/services/api/mattersService';
import { selectUser } from '@/state/selectors/root-selectors';
import { isPhoneNumberValid } from '@/utils/validation';

import { DebugPanel } from './DebugPanel';
import { FileSelector } from './FileSelector';
import { FrontDebugPanel } from './FrontDebugPanel';

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  phoneNumber: z.string().refine((val) => !val || isPhoneNumberValid(val), {
    message: 'Please enter a valid phone number (e.g. 303-217-0796)',
  }),
  firm: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

type FormValues = z.infer<typeof formSchema>;

export const CreateMatterForm = () => {
  const [createMatter, { isLoading }] = useCreateMatterMutation();
  const { data: firms } = useGetFirmsQuery();
  const user = useSelector(selectUser);
  const frontContext = useFrontContext();

  // Selected files state
  const [selectedFiles, setSelectedFiles] = useState<{
    attachments: {
      id: string;
      attachment: ApplicationAttachment;
      messageId: ApplicationMessageId;
    }[];
    urls: string[];
  }>({ attachments: [], urls: [] });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      phoneNumber: '',
      firm: null,
    },
  });

  // Handle file selection change from FileSelector component
  const handleFileSelectionChange = (selection: {
    attachments: {
      id: string;
      attachment: ApplicationAttachment;
      messageId: ApplicationMessageId;
    }[];
    urls: string[];
  }) => {
    setSelectedFiles(selection);
  };

  // Prepare the payload that would be sent to the API
  const getPayload = () => {
    if (!user || !form.getValues('firm')) return null;
    const firm = form.getValues('firm');
    if (!firm) return null;

    return {
      name: form.getValues('name'),
      firm: firm.id,
      creator: user.id,
      clients: [
        {
          phone_numbers: form.getValues('phoneNumber')
            ? [{ phone_number: form.getValues('phoneNumber') }]
            : [],
        },
      ],
      front_attachments: selectedFiles.attachments.map(
        ({ attachment, messageId }) => ({
          message_id: messageId,
          attachment: {
            id: attachment.id,
            name: attachment.name,
            size: attachment.size,
            content_type: attachment.contentType,
          },
        }),
      ),
      front_attachment_urls: selectedFiles.urls,
    };
  };

  const onSubmit = async (values: FormValues) => {
    if (!user || !values.firm) {
      toast.error(user ? 'Please select a firm' : 'User is not logged in');
      return;
    }

    const payload = getPayload();
    if (!payload) {
      toast.error('Failed to construct payload');
      return;
    }

    try {
      await createMatter(payload).unwrap();
      toast.success('Matter created successfully!');
      form.reset();
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : 'Failed to create matter',
      );
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex max-w-xl flex-col overflow-hidden rounded-md border-b border-gray-200">
        <div className="p-4">
          <h2 className="text-md mb-4 font-bold">Create New Matter</h2>
          <div className="space-y-4">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="firm"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold">Firm</FormLabel>
                      <FormControl>
                        <Combobox
                          placeholder="Select a firm"
                          options={
                            firms?.map((firm) => ({
                              id: firm.id,
                              name: firm.name,
                            })) || []
                          }
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold">Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter matter name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold">Phone Number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter phone number"
                          type="tel"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* File Selector Component */}
                <FileSelector onSelectionChange={handleFileSelectionChange} />

                {/* Debug Panel for the homies */}
                {/* <DebugPanel getPayload={getPayload} /> */}

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    variant="outline"
                    disabled={isLoading || !form.getValues('firm')}
                    className="hover:text-primary"
                  >
                    {isLoading ? 'Creating...' : 'Create Matter'}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
      {/* another debug panel for the homies */}
      <FrontDebugPanel frontContext={frontContext} />
    </div>
  );
};
