import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';

import {
  useDocumentDownload,
  useDocumentPreview,
} from '../../hooks/useLettersPreview';
import {
  useResendDocumentForSigningMutation,
  useSendDocumentForSigningMutation,
} from '../../services/api/lettersService';
import { DetailedMatter } from '../../services/types/client-intake-types';
import {
  DocumentInstance,
  DocumentInstanceStatus,
} from '../../services/types/letters-types';
import { PrimaryButton } from '../base/Buttons';
import { PDFPreview } from '../base/PDFPreview';
import { useNotification } from '../contexts/NotificationContext';

interface PreviewLetterProps {
  open: boolean;
  onClose: () => void;
  document: DocumentInstance;
  matter: DetailedMatter;
}

export const PreviewLetter = ({
  open,
  onClose,
  document,
  matter,
}: PreviewLetterProps) => {
  const requiresSignature = document.document_template.requires_signatures;
  const notification = useNotification();
  const { pdfBlob, isLoading } = useDocumentPreview({
    open,
    matterId: matter.id,
    documentId: document.id,
  });

  const { wordBlob, isLoading: isDownloading } = useDocumentDownload({
    open,
    matterId: matter.id,
    documentId: document.id,
  });

  const [sendForSigning, { isLoading: isSending }] =
    useSendDocumentForSigningMutation();
  const [resendForSigning, { isLoading: isResending }] =
    useResendDocumentForSigningMutation();

  const handleSendForSigning = async () => {
    try {
      if (document.status === DocumentInstanceStatus.SENT) {
        await resendForSigning({
          matterId: matter.id,
          documentInstanceId: document.id,
        }).unwrap();
      } else {
        await sendForSigning({
          matterId: matter.id,
          documentInstanceId: document.id,
        }).unwrap();
      }
    } catch (error) {
      notification('Failed to send for signing', 'error', 5000);
    }
  };

  const handleDownload = (blob: Blob, filename: string) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const link = window.document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const renderSignatureButton = () => {
    if (!requiresSignature) return null;

    const isCreated = document.status === DocumentInstanceStatus.CREATED;
    const isSent = document.status === DocumentInstanceStatus.SENT;
    const isSigningInProgress = isSending || isResending;

    return (
      <PrimaryButton
        variant="contained"
        color="primary"
        startIcon={<SendIcon />}
        onClick={handleSendForSigning}
        disabled={
          isSigningInProgress ||
          document.status === DocumentInstanceStatus.COMPLETED
        }
      >
        {isCreated && (isSending ? 'Sending...' : 'Send for Signature')}
        {isSent && (isResending ? 'Resending...' : 'Resend for Signature')}
        {document.status === DocumentInstanceStatus.COMPLETED && 'Signed'}
        {document.status === DocumentInstanceStatus.VOIDED && 'Voided'}
      </PrimaryButton>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100vh - 128px)',
          maxWidth: '50rem',
          position: 'fixed',
          bottom: 0,
          marginBottom: 0,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 6, py: 3 }}
        >
          <Typography variant="h2">
            {document.document_template.name}
          </Typography>
          <Stack direction="row" spacing={6}>
            <Stack direction="row" spacing={4}>
              {wordBlob && (
                <Button
                  onClick={() => handleDownload(wordBlob, 'document.docx')}
                  disabled={isDownloading || !wordBlob}
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.25)',
                    background: '#FFF',
                    color: '#000',
                    fontFamily: 'area-normal, sans-serif',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '1px',
                    padding: '8px 12px',
                    textTransform: 'uppercase',
                    '&:disabled': {
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    },
                  }}
                  size="small"
                >
                  Download Template
                </Button>
              )}

              {pdfBlob && (
                <Button
                  onClick={() => handleDownload(pdfBlob, 'document.pdf')}
                  disabled={isLoading || !pdfBlob}
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.25)',
                    background: '#FFF',
                    color: '#000',
                    fontFamily: 'area-normal, sans-serif',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '1px',
                    padding: '8px 12px',
                    textTransform: 'uppercase',
                    '&:disabled': {
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    },
                  }}
                  size="small"
                >
                  Download PDF
                </Button>
              )}
            </Stack>
            {renderSignatureButton()}
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <PDFPreview blob={pdfBlob || null} width="100%" height="100%" />
      </Box>
    </Dialog>
  );
};
