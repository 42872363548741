import {
  ApplicationAttachment,
  ApplicationAttachmentId,
  ApplicationMessageId,
} from '@frontapp/plugin-sdk';
import { ExternalLink } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { useFrontContext } from '@/providers/frontContext';
import * as frontService from '@/services/frontService';
import { MessageAttachment } from '@/services/frontService';
import { isGoogleDriveUrl, normalizeUrl } from '@/services/urlExtractor';

interface FileSelectorProps {
  onSelectionChange: (selected: {
    attachments: {
      id: string;
      attachment: ApplicationAttachment;
      messageId: ApplicationMessageId;
    }[];
    urls: string[];
  }) => void;
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  onSelectionChange,
}) => {
  const frontContext = useFrontContext();
  const [attachments, setAttachments] = useState<MessageAttachment[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(false);

  // Memoized toggle function to prevent recreating on every render
  const toggleItem = useCallback((id: string) => {
    setSelected((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  }, []);

  // Memoized selection change handler
  const handleSelectionChange = useCallback(() => {
    const selectedAttachments = attachments
      .filter((a) => selected.has(a.attachment.id))
      .map((a) => ({
        id: a.attachment.id as unknown as ApplicationAttachmentId,
        attachment: {
          id: a.attachment.id as unknown as ApplicationAttachmentId,
          name: a.attachment.name,
          size: a.attachment.size,
          contentType: a.attachment.content_type,
          inlineCid: undefined,
        } as unknown as ApplicationAttachment,
        messageId: a.message_id,
      }));
    const selectedUrls = urls.filter((url) => selected.has(url));
    onSelectionChange({ attachments: selectedAttachments, urls: selectedUrls });
  }, [attachments, urls, selected, onSelectionChange]);

  const allItems = [...attachments.map((a) => a.attachment.id), ...urls];
  const allSelected =
    allItems.length > 0 && allItems.every((id) => selected.has(id));

  const toggleAll = () => {
    if (allSelected) {
      setSelected(new Set());
    } else {
      setSelected(new Set(allItems));
    }
  };

  // Update parent when selection changes
  useEffect(() => {
    handleSelectionChange();
  }, [selected, handleSelectionChange]);

  // Load items on mount
  useEffect(() => {
    const loadItems = async () => {
      if (!frontContext) return;
      setIsLoading(true);

      try {
        const [newAttachments, allUrls] = await Promise.all([
          frontService.listConversationAttachments(frontContext),
          frontService.extractUrlsFromConversation(frontContext),
        ]);

        // Clean and filter Google Drive URLs
        const driveUrls = allUrls
          .map(normalizeUrl)
          .filter(
            (url): url is string => url !== null && isGoogleDriveUrl(url),
          );

        setAttachments(newAttachments);
        setUrls(driveUrls);
        setSelected(
          new Set([
            ...newAttachments.map((a) => a.attachment.id),
            ...driveUrls,
          ]),
        );
      } catch (err) {
        console.error('Error loading items:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadItems();
  }, [frontContext]);

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading files...</div>;
  }

  if (attachments.length === 0 && urls.length === 0) {
    return (
      <div className="text-sm text-gray-500">
        No files found in conversation
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label className="font-bold">Include Files</Label>
        {allItems.length > 0 && (
          <button
            type="button"
            onClick={toggleAll}
            className="text-sm text-blue-600 hover:text-blue-700"
          >
            {allSelected ? 'Deselect All' : 'Select All'}
          </button>
        )}
      </div>

      <div className="space-y-2">
        {attachments.map(({ attachment }) => (
          <div
            key={attachment.id}
            className="flex items-center gap-2 rounded p-1 hover:bg-gray-50"
          >
            <Checkbox
              id={attachment.id}
              checked={selected.has(attachment.id)}
              onCheckedChange={() => toggleItem(attachment.id)}
              className="border-gray-300 data-[state=checked]:border-blue-600 data-[state=checked]:bg-blue-600 data-[state=checked]:text-white"
            />
            <Label
              htmlFor={attachment.id}
              className="flex cursor-pointer items-center gap-2 text-sm"
            >
              <span className="truncate">{attachment.name}</span>
              <span className="text-xs whitespace-nowrap text-gray-500">
                ({Math.round(attachment.size / 1024)} KB)
              </span>
            </Label>
          </div>
        ))}

        {urls.map((url) => (
          <div
            key={url}
            className="flex items-center gap-2 rounded p-1 hover:bg-gray-50"
          >
            <Checkbox
              id={url}
              checked={selected.has(url)}
              onCheckedChange={() => toggleItem(url)}
              className="border-gray-300 data-[state=checked]:border-blue-600 data-[state=checked]:bg-blue-600 data-[state=checked]:text-white"
            />
            <Label
              htmlFor={url}
              className="flex w-full cursor-pointer items-center justify-between text-sm"
            >
              <span className="max-w-[300px] truncate">{url}</span>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(url, '_blank', 'noopener,noreferrer');
                }}
                className="ml-2 rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              >
                <ExternalLink className="h-4 w-4" />
              </button>
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};
