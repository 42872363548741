import { useEffect, useState } from 'react';

import { useNotification } from '../components/contexts/NotificationContext';

interface UseBlobFetchProps {
  url: string;
  enabled: boolean;
}

interface UseDocumentPreviewProps {
  open: boolean;
  matterId: string;
  documentId: string;
}

const MAX_RETRIES = 3;
const INITIAL_DELAY = 3000; // 3 seconds

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const useBlobFetch = ({ url, enabled }: UseBlobFetchProps) => {
  const [blob, setBlob] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const fetchWithRetry = async (retryCount = 0): Promise<Blob | null> => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch blob');
        }

        return await response.blob();
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          const delay = INITIAL_DELAY * 2 ** retryCount;
          await sleep(delay);
          return fetchWithRetry(retryCount + 1);
        }
        throw error;
      }
    };

    const fetchBlob = async () => {
      if (!enabled) {
        setBlob(null);
        return;
      }

      setIsLoading(true);
      try {
        const fetchedBlob = await fetchWithRetry();
        setBlob(fetchedBlob);
      } catch (error) {
        notification(
          'Failed to load blob after multiple attempts',
          'error',
          5000,
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlob();

    return () => {
      setBlob(null);
    };
  }, [url, enabled, notification]);

  return { blob, isLoading };
};

export const useDocumentPreview = ({
  open,
  matterId,
  documentId,
}: UseDocumentPreviewProps) => {
  const url = `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/matters/${matterId}/documents/${documentId}/preview/`;
  const { blob: pdfBlob, isLoading } = useBlobFetch({
    url,
    enabled: open,
  });

  return { pdfBlob, isLoading };
};

export const useDocumentDownload = ({
  open,
  matterId,
  documentId,
}: UseDocumentPreviewProps) => {
  const url = `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/matters/${matterId}/documents/${documentId}/download/`;
  const { blob: wordBlob, isLoading } = useBlobFetch({
    url,
    enabled: open,
  });

  return { wordBlob, isLoading };
};
