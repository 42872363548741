import FilterListIcon from '@mui/icons-material/FilterList';
import { useLocalStorage } from '@uidotdev/usehooks';
import {
  AlertTriangle,
  ArrowDown,
  ArrowUp,
  Check,
  ChevronRightIcon,
  X as XIcon,
} from 'lucide-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn, filterStaffUsers, taskIsPastDue, taskIsUrgent } from '@/lib/utils';
import {
  useGetTaskGroupsQuery,
  useGetTasksQuery,
  useUpdateTaskMutation,
} from '@/services/api';
import { useGetStaffUsersQuery } from '@/services/api/userService';
import { Task } from '@/services/types/client-intake-types';
import { CanonicalTaskStatus } from '@/services/types/task-types';
import { selectUser } from '@/state/selectors/root-selectors';

import { CreateTaskModal } from './CreateTaskModal';
import { TaskDetail } from './task-detail';
import { TaskAssignee } from './TaskAssignee';
import { TaskDueDate } from './TaskDueDate';
import { TaskStatusBadge } from './TaskStatusBadge';
import { TaskWarningDropdown } from './TaskWarningDropdown';

export const TaskWarningBadge = ({ task }: { task: Task }) => {
  const isPastDue = taskIsPastDue(task);
  const isUrgent = taskIsUrgent(task);

  if (!isPastDue && !isUrgent) {
    return null;
  }

  return (
    <span
      className={cn(
        'px-2 py-1 rounded text-xs font-bold min-w-[80px] text-center line-clamp-1',
        isPastDue && 'bg-orange-100 text-orange-700',
        isUrgent && 'bg-purple-100 text-purple-700',
      )}
    >
      {!isUrgent && isPastDue && 'PAST DUE'}
      {isUrgent === true && 'URGENT'}
    </span>
  );
};

const FilterCombobox = <T extends { id: string; name: string }>({
  label,
  options,
  value,
  onChange,
}: {
  label: string;
  options: T[];
  value: string | null;
  onChange: (value: string | null) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <div className="flex flex-row">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className={cn(
                'justify-between border border-black bg-white rounded-sm',
                value && 'bg-black rounded-r-none text-white hover:bg-gray-700',
              )}
            >
              <div className="flex items-center gap-2">
                <FilterListIcon />
                <span className="font-semibold">{label}</span>
              </div>
            </Button>
            {value && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
                className="rounded-l-none bg-black text-white hover:bg-gray-700"
                aria-label={`Clear ${label} filter`}
              >
                <XIcon className="h-3 w-3" />
              </Button>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-0 bg-white">
          <Command>
            <CommandInput placeholder={`Search ${label.toLowerCase()}...`} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.id}
                    value={option.name}
                    onSelect={() => {
                      onChange(option.id);
                      setOpen(false);
                    }}
                    className="hover:bg-gray-100"
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value === option.id ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {option.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const MultiSelectCombobox = ({
  label,
  options,
  selectedValues,
  onChange,
  presets,
}: {
  label: string;
  options: { id: string; name: string }[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  presets?: { name: string; values: string[] }[];
}) => {
  const [open, setOpen] = React.useState(false);
  const commandId = React.useId();

  return (
    <div className="flex gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <div className="flex">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className={cn(
                'justify-between border border-black bg-white rounded-sm',
                selectedValues.length > 0 &&
                  'bg-black rounded-r-none text-white hover:bg-gray-700',
              )}
            >
              <div className="flex items-center gap-2">
                <FilterListIcon />
                <span className="font-semibold">{label}</span>
              </div>
            </Button>
            {selectedValues.length > 0 && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange([]);
                }}
                className="rounded-l-none bg-black text-white hover:bg-gray-700"
                aria-label={`Clear ${label} filter`}
              >
                <XIcon className="h-3 w-3" />
              </Button>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-0 bg-white">
          <Command id={commandId}>
            <CommandInput placeholder={`Search ${label.toLowerCase()}...`} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              {presets && (
                <>
                  <CommandGroup heading="Presets">
                    {presets.map((preset) => {
                      const isAllSelected =
                        preset.values.every((value) =>
                          selectedValues.includes(value),
                        ) && selectedValues.length === preset.values.length;
                      return (
                        <CommandItem
                          key={preset.name}
                          value={preset.name}
                          onSelect={() => {
                            if (isAllSelected) {
                              onChange(
                                selectedValues.filter(
                                  (v) => !preset.values.includes(v),
                                ),
                              );
                            } else {
                              onChange(preset.values);
                            }
                          }}
                          className="hover:bg-gray-100"
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              isAllSelected ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                          {preset.name}
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.id}
                    value={option.name}
                    onSelect={() => {
                      onChange(
                        selectedValues.includes(option.id)
                          ? selectedValues.filter((v) => v !== option.id)
                          : [...selectedValues, option.id],
                      );
                    }}
                    className="hover:bg-gray-100"
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedValues.includes(option.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {option.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

// Define interface for filter state
interface TaskFilterState {
  search: string;
  status: string[];
  firm: string | null;
  matter: string | null;
  assignee: string | null;
  group: string | null;
  ordering:
    | 'due_date'
    | '-due_date'
    | 'order,due_date'
    | '-order,due_date'
    | null;
  offset: number;
}

const Tasks: React.FC = () => {
  const navigate = useNavigate();
  // If matter_id is not provided, we are on /tasks
  // Otherwise, we are on /mattters and should adjust the UI accordingly
  const { task_id, matter_id } = useParams();

  // Get task statuses from API
  const { data: taskGroups = [] } = useGetTaskGroupsQuery();

  // Create active and inactive status IDs
  const ACTIVE_STATUSES = [
    CanonicalTaskStatus.IN_PROGRESS,
    CanonicalTaskStatus.BLOCKED,
    CanonicalTaskStatus.PENDING,
  ];

  const INACTIVE_STATUSES = [
    CanonicalTaskStatus.COMPLETED,
    CanonicalTaskStatus.NOT_APPLICABLE,
  ];

  const currentUser = useSelector(selectUser);

  // Define default filter state

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultFilterState: TaskFilterState = {
    search: '',
    status: ACTIVE_STATUSES,
    firm: null,
    matter: matter_id || null,
    assignee: null,
    group: null,
    ordering: null,
    offset: 0,
  };

  // Use the useLocalStorage hook with our default state
  const [filterState, setFilterState] = useLocalStorage<TaskFilterState>(
    'tasks_filter_state',
    defaultFilterState,
  );

  // Ensure filter state has all required properties with fallbacks to defaults
  const safeFilterState: TaskFilterState = {
    ...defaultFilterState,
    ...filterState,
  };

  // Destructure filter state for easier access
  const {
    search: searchQuery,
    status: statusFilter,
    firm: firmFilter,
    matter: matterFilter,
    assignee: assigneeFilter,
    group: groupFilter,
    ordering,
    offset,
  } = safeFilterState;

  const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const pageSize = matter_id ? 10 : 100;

  // Helper to update a single filter property
  const updateFilter = React.useCallback(
    <K extends keyof TaskFilterState>(
      key: K,
      value: TaskFilterState[K],
      resetOffset = true,
    ) => {
      setFilterState((prev) => {
        // Ensure prev is never undefined by providing the default state
        const currentState = prev ? { ...prev } : { ...defaultFilterState };
        return {
          ...currentState,
          [key]: value,
          ...(resetOffset ? { offset: 0 } : {}),
        };
      });
    },
    [setFilterState, defaultFilterState],
  );

  useEffect(() => {
    if (matter_id && matterFilter !== matter_id) {
      updateFilter('matter', matter_id);
    }
  }, [matter_id, matterFilter, updateFilter]);

  const { data: staffUsers, isLoading: isStaffUsersLoading } =
    useGetStaffUsersQuery();

  const filteredStaffUsers = React.useMemo(() => {
    return filterStaffUsers(staffUsers || []);
  }, [staffUsers]);

  const { data: tasks, isLoading: isTasksLoading } = useGetTasksQuery({
    search: searchQuery,
    statuses: statusFilter.length > 0 ? statusFilter : undefined,
    matter: matterFilter || undefined,
    matter__firm: firmFilter || undefined,
    ordering: ordering || 'order,due_date',
    assignee: assigneeFilter || undefined,
    limit: pageSize,
    group: groupFilter || undefined,
    offset,
  });

  useEffect(() => {
    if (currentUser?.id && assigneeFilter === null) {
      updateFilter('assignee', currentUser.id);
    }
    // Only run this effect on login, otherwise it will override assigneeFilter when correctly cleared
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  useEffect(() => {
    if (tasks?.count && offset > tasks?.count) {
      updateFilter('offset', 0, false);
    }
  }, [tasks?.count, offset, pageSize, updateFilter]);

  useEffect(() => {
    if (tasks?.results && (selectedTask || task_id)) {
      setSelectedTask(
        tasks.results.find(
          (task) => task.id === (selectedTask?.id || task_id),
        ) || null,
      );
    }
  }, [tasks?.results, selectedTask, task_id]);

  const uniqueFirms = React.useMemo(() => {
    if (!tasks?.results) return [];
    return Array.from(
      new Map(
        tasks.results.map((task) => [
          task.firm,
          { id: task.firm, name: task.firm_name },
        ]),
      ).values(),
    );
  }, [tasks?.results]);

  const uniqueMatters = React.useMemo(() => {
    if (!tasks?.results) return [];
    return Array.from(
      new Map(
        tasks.results.map((task) => [
          task.matter,
          { id: task.matter, name: task.matter_name },
        ]),
      ).values(),
    );
  }, [tasks?.results]);

  const [updateTask] = useUpdateTaskMutation();

  if (isTasksLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-4 border-primary border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="space-y-4 m-8">
      <div className="flex items-center gap-3">
        <Input
          placeholder="Filter tasks"
          className="max-w-sm border-gray-400 shadow-none rounded-sm"
          type="search"
          value={searchQuery}
          onChange={(e) => updateFilter('search', e.target.value)}
        />
        <MultiSelectCombobox
          label="Status"
          options={Object.values(CanonicalTaskStatus).map((status) => ({
            id: status,
            name: status,
          }))}
          selectedValues={statusFilter}
          onChange={(values) => updateFilter('status', values)}
          presets={[
            {
              name: 'Active',
              values: ACTIVE_STATUSES,
            },
            {
              name: 'Inactive',
              values: INACTIVE_STATUSES,
            },
          ]}
        />
        <FilterCombobox<{ id: string; name: string }>
          label="Assignee"
          options={
            filteredStaffUsers?.map((user) => ({
              id: user.id,
              name: `${user.first_name} ${user.last_name}`,
            })) || []
          }
          value={assigneeFilter}
          onChange={(value) => updateFilter('assignee', value)}
        />
        {!matter_id && (
          <FilterCombobox<{ id: string; name: string }>
            label="Firm"
            options={uniqueFirms}
            value={firmFilter}
            onChange={(value) => updateFilter('firm', value)}
          />
        )}
        {!matter_id && (
          <FilterCombobox<{ id: string; name: string }>
            label="Matter"
            options={uniqueMatters}
            value={matterFilter}
            onChange={(value) => updateFilter('matter', value)}
          />
        )}
        <FilterCombobox<{ id: string; name: string }>
          label="Group"
          options={taskGroups.map((group) => ({
            id: group.id,
            name: group.name,
          }))}
          value={groupFilter}
          onChange={(value) => updateFilter('group', value)}
        />
        <Button
          variant="default"
          className="ml-auto text-white font-bold rounded-sm"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add Task
        </Button>
      </div>
      <Card className="border border-[#0000000F] rounded-md shadow-none pb-1">
        {tasks && tasks.count > pageSize && (
          <div className="flex items-center justify-between px-4 py-3 border-[#0000000F]">
            <div className="flex items-center gap-2">
              <p className="text-sm text-gray-700">
                Showing {offset + 1} to{' '}
                {Math.min(offset + pageSize, tasks.count)} of {tasks.count}{' '}
                tasks
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => updateFilter('offset', offset - pageSize, false)}
                disabled={offset === 0}
                className="text-sm font-semibold hover:bg-gray-100"
              >
                Previous
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => updateFilter('offset', offset + pageSize, false)}
                disabled={offset + pageSize >= tasks.count}
                className="text-sm font-semibold hover:bg-gray-100"
              >
                Next
              </Button>
            </div>
          </div>
        )}
        <Table className="border-none rounded-md overflow-hidden">
          <TableHeader className="rounded-md">
            <TableRow className="bg-background-gray-dark border-b-[#0000000F] rounded-md text-[#00000080]">
              <TableHead
                className="cursor-pointer"
                onClick={() => {
                  switch (ordering) {
                    case 'due_date':
                      updateFilter('ordering', '-due_date', false);
                      break;
                    case '-due_date':
                      updateFilter('ordering', 'order,due_date', false);
                      break;
                    case 'order,due_date':
                      updateFilter('ordering', 'due_date', false);
                      break;
                    default:
                      updateFilter('ordering', 'due_date', false);
                  }
                }}
              >
                <div className="flex flex-row items-center gap-2 max-w-[150px]">
                  <h3 className="pl-2 font-semibold">Target</h3>
                  {ordering === 'due_date' && (
                    <ArrowDown className="pt-1" size={16} />
                  )}
                  {ordering === '-due_date' && (
                    <ArrowUp className="pt-1" size={16} />
                  )}
                </div>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Task</h3>
              </TableHead>
              <TableHead>
                <h3 className="font-semibold">Group</h3>
              </TableHead>
              {!matter_id && (
                <>
                  <TableHead>
                    <h3 className="font-semibold">Matter</h3>
                  </TableHead>
                  <TableHead>
                    <h3 className="font-semibold">Firm</h3>
                  </TableHead>
                </>
              )}
              <TableHead className="w-[150px]">
                <h3 className="font-semibold">Status</h3>
              </TableHead>
              <TableHead className="w-[60px]">
                <h3 className="font-semibold">Assignee</h3>
              </TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {tasks?.results.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} className="text-center p-4">
                  No tasks found
                </TableCell>
              </TableRow>
            )}
            {tasks?.results.map((task) => (
              <TableRow
                className={cn(
                  task.status.canonical_status ===
                    CanonicalTaskStatus.NOT_APPLICABLE && 'opacity-50',
                  'cursor-pointer hover:bg-gray-100',
                )}
                key={task.id}
                onClick={() =>
                  navigate(
                    matter_id
                      ? `/matters/${matter_id}/tasks/${task.id}`
                      : `/tasks/${task.id}`,
                  )
                }
              >
                <TableCell className="text-left w-[150px]">
                  <TaskDueDate
                    date={task.due_date}
                    onDateChange={(date) => {
                      if (!date) return;
                      updateTask({
                        matterId: task.matter,
                        taskId: task.id,
                        task: { due_date: date.toISOString() },
                      });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2 h-full">
                    <TaskWarningBadge task={task} />
                    <h3 className="font-bold">{task.name}</h3>
                  </div>
                </TableCell>
                <TableCell className="opacity-50 font-semibold">
                  {task.group.name}
                </TableCell>
                {!matter_id && (
                  <>
                    <TableCell className="opacity-50 font-semibold">
                      {task.matter_name}
                    </TableCell>
                    <TableCell className="opacity-50 font-semibold">
                      {task.firm_name}
                    </TableCell>
                  </>
                )}
                <TableCell className="w-[150px]">
                  <TaskStatusBadge
                    status={task.status}
                    onStatusChange={(status) => {
                      updateTask({
                        matterId: task.matter,
                        taskId: task.id,
                        task: {
                          status_id: status.id,
                          completed_at:
                            status.canonical_status ===
                            CanonicalTaskStatus.COMPLETED
                              ? new Date().toISOString()
                              : null,
                        },
                      });
                    }}
                  />
                </TableCell>
                <TableCell className="w-[60px]">
                  <TaskAssignee
                    staffUsers={filteredStaffUsers || []}
                    isLoading={isStaffUsersLoading}
                    assignee={task.assignee}
                    onAssigneeChange={(userId) => {
                      updateTask({
                        matterId: task.matter,
                        taskId: task.id,
                        task: {
                          assignee_id: userId,
                        },
                      });
                    }}
                  />
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end">
                    <ChevronRightIcon className="p-1 opacity-50" size={24} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {selectedTask && (
        <TaskDetail
          taskId={selectedTask.id}
          matterId={selectedTask.matter}
          onClose={() => setSelectedTask(null)}
          isModal
        />
      )}

      <CreateTaskModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </div>
  );
};

export default Tasks;
