import { ChevronDown, ChevronUp, MessageSquareText } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';

interface CallPromptProps {
  prompt: string | null;
}

export const CallPrompt: React.FC<CallPromptProps> = ({ prompt }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!prompt) return null;

  // Determine if prompt is large (more than 100 characters or contains newlines)
  const isLargePrompt = prompt.length > 100 || prompt.includes('\n');

  // Create truncated version (first 100 chars or first line)
  let truncatedPrompt = prompt;

  if (isLargePrompt) {
    if (prompt.includes('\n')) {
      const firstLine = prompt.split('\n')[0];
      truncatedPrompt = `${firstLine}...`;
    } else {
      truncatedPrompt = `${prompt.substring(0, 100)}...`;
    }
  }

  return (
    <div className="bg-white rounded-lg p-3 shadow-sm">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <MessageSquareText className="h-4 w-4 mr-2 text-primary" />
          <h4 className="font-medium">Call Prompt</h4>
        </div>
        {isLargePrompt && (
          <Button
            variant="ghost"
            size="sm"
            className="h-6 px-2 text-xs"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <>
                <ChevronUp className="h-3 w-3 mr-1" /> Collapse
              </>
            ) : (
              <>
                <ChevronDown className="h-3 w-3 mr-1" /> Expand
              </>
            )}
          </Button>
        )}
      </div>
      <div className="p-2 bg-slate-50 rounded border text-sm">
        <p className="whitespace-pre-wrap">
          {isExpanded ? prompt : truncatedPrompt}
        </p>
      </div>
    </div>
  );
};
