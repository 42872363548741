import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider } from '@mui/material';
import { createColumnHelper, FilterFn, Row } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { BaseMatter } from '../../services/types/client-intake-types';
import {
  StyledActionButton,
  StyledMenu,
  StyledMenuItem,
} from './MattersTable.styles';

interface MatterActionButtonProps {
  onViewDetails: (matter: BaseMatter) => void;
  matter: BaseMatter;
}

const MatterActionButton = ({
  onViewDetails,
  matter,
}: MatterActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledActionButton
        variant="text"
        size="small"
        disableRipple
        onClick={handleClick}
        aria-controls={open ? 'matter-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon />
      </StyledActionButton>
      <StyledMenu
        id="matter-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <StyledMenuItem onClick={() => onViewDetails(matter)}>
          View Matter
        </StyledMenuItem>
        <Divider />
      </StyledMenu>
    </>
  );
};

export const fuzzyFilter: FilterFn<BaseMatter> = (
  row,
  columnId,
  value: string[],
) => {
  // If no value is selected, show all rows
  if (!value?.length) return true;

  // Check if the row's value is in the selected values array
  const cellValue = row.getValue(columnId);
  return value.includes(cellValue as string);
};

const columnHelper = createColumnHelper<BaseMatter>();

interface UseColumnsProps {
  onViewDetails: (matter: BaseMatter) => void;
}

const ArrayFilter = (
  row: Row<BaseMatter>,
  columnId: string,
  filterValue: string[],
) => {
  return filterValue.includes(row.getValue(columnId));
};

export const useColumns = ({ onViewDetails }: UseColumnsProps) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Client Name',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        enableSorting: true,
      }),
      columnHelper.accessor('firm.name', {
        header: 'Firm Name',
        cell: (info) => info.getValue(),
        filterFn: ArrayFilter,
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('date_of_loss', {
        header: 'Date of Loss',
        cell: (info) => info.getValue(),
        filterFn: ArrayFilter,
        enableColumnFilter: false,
        enableSorting: true,
      }),
      columnHelper.accessor('matter_type', {
        header: 'Case Type',
        cell: (info) => info.getValue(),
        filterFn: ArrayFilter,
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => info.getValue(),
        filterFn: ArrayFilter,
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <MatterActionButton
            onViewDetails={onViewDetails}
            matter={info.row.original}
          />
        ),
        enableSorting: false,
      }),
    ];

    return columns;
  }, [onViewDetails]);
};
