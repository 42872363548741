import { NOTE_TYPES, NoteType } from '@/services/types/matter-notes-types';

import {
  MatterNoteConfig,
  MatterNoteFieldType,
  NoteCardinality,
} from './types';

export const INSURANCE_POLICY_TYPE_OPTIONS = [
  { value: 'FIRST_PARTY_PIP', label: 'PIP / 1P Insurance' },
  { value: 'THIRD_PARTY_BI', label: 'BI / 3P Insurance' },
  { value: 'PERSONAL_HEALTH_INSURANCE', label: 'Personal Health Insurance' },
];

export const EMERGENCY_CONTACT_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.EMERGENCY_CONTACT,
  title: 'Emergency Contact',
  fields: [
    // First row - core identity and primary contact
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'email',
      label: 'Email',
    },
    // Second row - additional details and description
    {
      name: 'date_of_birth',
      label: 'Date of Birth',
      type: MatterNoteFieldType.DATE,
    },
    {
      name: 'last_four_of_ssn',
      label: 'Last 4 of SSN',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.SINGLE,
} satisfies MatterNoteConfig;

export const INCIDENT_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.INCIDENT,
  title: 'Accident Details',
  fields: [
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
    {
      name: 'date_of_loss',
      label: 'Date of Loss',
      type: MatterNoteFieldType.DATE,
    },
    {
      name: 'time_of_loss',
      label: 'Time of Loss',
    },
    {
      name: 'incident_type',
      label: 'Incident Type',
    },
    {
      name: 'location',
      label: 'Location',
    },
    {
      name: 'weather_conditions',
      label: 'Weather Conditions',
    },
    {
      name: 'police_report',
      label: 'Police Report #',
    },
    {
      name: 'photos',
      label: 'Photos/Evidence',
    },
    {
      name: 'witnesses',
      label: 'Witnesses',
    },
    {
      name: 'statements',
      label: 'Statements',
    },
    {
      name: 'injuries_described',
      label: 'Injuries Described',
    },
    {
      name: 'current_treatment',
      label: 'Current Treatment',
    },
    {
      name: 'ambulance',
      label: 'Ambulance',
    },
    {
      name: 'hospital',
      label: 'Hospital',
    },
    {
      name: 'representation',
      label: 'Representation',
    },
  ],
  cardinality: NoteCardinality.SINGLE,
} satisfies MatterNoteConfig;

export const MEDICAL_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.MEDICAL,
  title: 'Medical Information',
  fields: [
    {
      name: 'name',
      label: 'Provider Name',
    },
    {
      name: 'treatment',
      label: 'Treatment',
    },
    {
      name: 'dates_of_service',
      label: 'Dates of Service',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'date_of_records_request',
      label: 'Date of Records Request',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const DAMAGE_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.DAMAGE,
  title: 'Damages',
  fields: [
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
    {
      name: 'amount',
      label: 'Amount',
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const PRIOR_MEDICAL_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.PRIOR_MEDICAL,
  title: 'Prior Medical History',
  fields: [
    {
      name: 'name',
      label: 'Provider Name',
    },
    {
      name: 'treatment',
      label: 'Treatment',
    },
    {
      name: 'dates_of_service',
      label: 'Dates of Service',
    },
    {
      name: 'is_pcp',
      label: 'Primary Care Provider',
      type: MatterNoteFieldType.CHECKBOX,
    },
    {
      name: 'still_treating',
      label: 'Still Treating',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const INSURANCE_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.INSURANCE,
  title: 'Insurance',
  fields: [
    {
      name: 'policy_type',
      label: 'Policy Type',
      type: MatterNoteFieldType.SELECT,
      options: INSURANCE_POLICY_TYPE_OPTIONS,
    },
    {
      name: 'holder',
      label: 'Holder',
    },
    {
      name: 'carrier',
      label: 'Carrier',
    },
    {
      name: 'policy_number',
      label: 'Policy Number',
    },
    {
      name: 'claim_number',
      label: 'Claim Number',
    },
    {
      name: 'member_id',
      label: 'Member ID',
    },
    {
      name: 'group_id',
      label: 'Group ID',
    },
    {
      name: 'date_filed',
      label: 'Date Filed',
      type: MatterNoteFieldType.DATE,
    },
    {
      name: 'vehicle_description',
      label: 'Vehicle Description',
    },
    {
      name: 'policy_limitations',
      label: 'Policy Limitations',
    },
    {
      name: 'adjuster_name',
      label: 'Adjuster Name',
    },
    {
      name: 'adjuster_phone',
      label: 'Adjuster Phone',
    },
    {
      name: 'adjuster_email',
      label: 'Adjuster Email',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const LAW_ENFORCEMENT_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.LAW_ENFORCEMENT,
  title: 'Police',
  fields: [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'police_report_number',
      label: 'Report Number',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const INVOLVED_PERSON_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.INVOLVED_PERSON,
  title: 'Additional Parties',
  fields: [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const CLIENT_EMPLOYMENT_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.CLIENT_EMPLOYMENT,
  title: 'Employment',
  fields: [
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
    {
      name: 'employer',
      label: 'Employer',
    },
    {
      name: 'role',
      label: 'Role',
    },
    {
      name: 'wage',
      label: 'Wage',
    },
    {
      name: 'length_of_employment',
      label: 'Length of Employment',
    },
    {
      name: 'lost_time_from_work',
      label: 'Lost Time from Work',
      type: MatterNoteFieldType.CHECKBOX,
    },
  ],
  cardinality: NoteCardinality.SINGLE,
} satisfies MatterNoteConfig;

export const PRIOR_LAWSUIT_MATTER_NOTE_CONFIG = {
  type: NOTE_TYPES.PRIOR_LAWSUIT,
  title: 'Prior Lawsuits',
  fields: [
    {
      name: 'description',
      label: 'Description',
      multiline: true,
    },
    {
      name: 'attorney',
      label: 'Attorney',
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'settlement',
      label: 'Settlement',
    },
  ],
  cardinality: NoteCardinality.MANY,
} satisfies MatterNoteConfig;

export const MATTER_NOTE_CONFIGS = [
  EMERGENCY_CONTACT_MATTER_NOTE_CONFIG,
  INCIDENT_MATTER_NOTE_CONFIG,
  MEDICAL_MATTER_NOTE_CONFIG,
  DAMAGE_MATTER_NOTE_CONFIG,
  PRIOR_MEDICAL_MATTER_NOTE_CONFIG,
  INSURANCE_MATTER_NOTE_CONFIG,
  LAW_ENFORCEMENT_MATTER_NOTE_CONFIG,
  INVOLVED_PERSON_MATTER_NOTE_CONFIG,
  CLIENT_EMPLOYMENT_MATTER_NOTE_CONFIG,
  PRIOR_LAWSUIT_MATTER_NOTE_CONFIG,
].reduce<Record<NoteType, MatterNoteConfig>>(
  (acc, config) => ({
    ...acc,
    [config.type]: config,
  }),
  {} as Record<NoteType, MatterNoteConfig>,
);

export function getMatterNoteConfig(type: NoteType): MatterNoteConfig {
  return MATTER_NOTE_CONFIGS[type];
}
