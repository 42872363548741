import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithErrorHandling } from './baseQuery';
// Define base types
interface BaseEntity {
  id: string;
  created_at: string;
  modified_at: string;
}

// Thread types
export interface MatterThread extends BaseEntity {
  title: string;
  current_message_id: string | null;
}

export interface CreateMatterThreadRequest {
  title: string;
}

export interface UpdateMatterThreadRequest {
  title: string;
}

// Message content types
export interface MessageContent {
  text_content: string | null;
  image_content: string | null;
}

// Message types
export enum MessageRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export enum MessageStatus {
  LOADING = 'loading',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
}

export interface MatterMessage extends BaseEntity {
  parent_id: string | null;
  role: MessageRole;
  status: MessageStatus;
  content: MessageContent[];
}

interface CreateTextMessageContent {
  type: 'text';
  text: string;
}

type CreateMessageContent = CreateTextMessageContent;

export interface CreateMatterMessageRequest extends BaseEntity {
  parent_id: string | null;
  role: MessageRole;
  status: MessageStatus;
  content: CreateMessageContent[];
}

export interface CreateAndRunMatterMessageRequest {
  message: CreateMatterMessageRequest;
}

// Stream response types
export interface StreamMessageEvent {
  type: string;
  data: any;
}

export const messageAdapter = createEntityAdapter<MatterMessage>({});

// API definition
export const matterAssistantApi = createApi({
  reducerPath: 'matterAssistantApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['MatterThread', 'MatterMessage'],
  endpoints: (builder) => ({
    // Thread endpoints
    getMatterThreads: builder.query<MatterThread[], string>({
      query: (matterId) => `api/matters/${matterId}/threads/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'MatterThread' as const,
                id,
              })),
              { type: 'MatterThread', id: 'LIST' },
            ]
          : [{ type: 'MatterThread', id: 'LIST' }],
    }),

    getMatterThread: builder.query<
      MatterThread,
      { matterId: string; threadId: string }
    >({
      query: ({ matterId, threadId }) =>
        `api/matters/${matterId}/threads/${threadId}/`,
      providesTags: (result, error, { threadId }) =>
        result ? [{ type: 'MatterThread', id: threadId }] : [],
    }),

    createMatterThread: builder.mutation<
      MatterThread,
      { matterId: string; data: CreateMatterThreadRequest }
    >({
      query: ({ matterId, data }) => ({
        url: `api/matters/${matterId}/threads/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'MatterThread', id: 'LIST' }],
    }),

    updateMatterThread: builder.mutation<
      MatterThread,
      { matterId: string; threadId: string; data: UpdateMatterThreadRequest }
    >({
      query: ({ matterId, threadId, data }) => ({
        url: `api/matters/${matterId}/threads/${threadId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { threadId }) => [
        { type: 'MatterThread', id: threadId },
        { type: 'MatterThread', id: 'LIST' },
      ],
    }),

    deleteMatterThread: builder.mutation<
      void,
      { matterId: string; threadId: string }
    >({
      query: ({ matterId, threadId }) => ({
        url: `api/matters/${matterId}/threads/${threadId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { threadId }) => [
        { type: 'MatterThread', id: threadId },
        { type: 'MatterThread', id: 'LIST' },
      ],
    }),

    // Message endpoints
    getMatterMessages: builder.query<
      EntityState<MatterMessage, string>,
      { matterId: string; threadId: string }
    >({
      query: ({ matterId, threadId }) =>
        `api/matters/${matterId}/threads/${threadId}/messages/`,
      transformResponse: (response: MatterMessage[]) => {
        return messageAdapter.setAll(
          messageAdapter.getInitialState(),
          response,
        );
      },
      providesTags: (result, error, { threadId }) =>
        result
          ? [
              ...result.ids.map((id) => ({
                type: 'MatterMessage' as const,
                id,
                threadId,
              })),
              { type: 'MatterMessage', id: 'LIST', threadId },
            ]
          : [{ type: 'MatterMessage', id: 'LIST', threadId }],
    }),

    createMatterMessage: builder.mutation<
      MatterMessage,
      { matterId: string; threadId: string; data: CreateMatterMessageRequest }
    >({
      query: ({ matterId, threadId, data }) => ({
        url: `api/matters/${matterId}/threads/${threadId}/messages/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { threadId }) => [
        { type: 'MatterMessage', id: 'LIST', threadId },
      ],
    }),

    // Special endpoint for running the assistant - this is handled differently due to streaming
    runMatterAssistant: builder.mutation<
      void,
      { matterId: string; threadId: string; data: CreateMatterMessageRequest }
    >({
      // For streaming endpoints, normal usage patterns don't apply
      // You should use this to initiate the stream, then manage the SSE connection separately
      query: ({ matterId, threadId, data }) => ({
        url: `api/matters/${matterId}/threads/${threadId}/messages/run/`,
        method: 'POST',
        body: data,
        responseHandler: 'text', // Don't try to parse JSON for SSE
      }),
      // When a run completes, we should invalidate the message list
      invalidatesTags: (result, error, { threadId }) => [
        { type: 'MatterMessage', id: 'LIST', threadId },
      ],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetMatterThreadsQuery,
  useGetMatterThreadQuery,
  useCreateMatterThreadMutation,
  useUpdateMatterThreadMutation,
  useDeleteMatterThreadMutation,
  useGetMatterMessagesQuery,
  useCreateMatterMessageMutation,
  useRunMatterAssistantMutation,
} = matterAssistantApi;
