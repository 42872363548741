import React from 'react';

import { MatterNoteConfig } from '../../types';
import { SectionActions } from './SectionActions';

interface SectionHeaderProps {
  title: string;
  onAddNew?: () => void;
  showAddButton?: boolean;
  matterId: string;
  config: MatterNoteConfig;
  isEmptyState?: boolean;
  onCleared?: () => void;
  isLoading?: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  onAddNew,
  showAddButton = false,
  matterId,
  config,
  isEmptyState = false,
  onCleared,
  isLoading = false,
}) => {
  return (
    <div>
      <div
        className={`flex items-center justify-between leading-none py-2 border-b ${isEmptyState ? 'border-dashed border-gray-200' : 'border-gray-100/80'}`}
      >
        <div className="flex items-baseline gap-4">
          <h3
            className={`text-lg font-medium ${isEmptyState ? 'text-gray-500' : 'text-gray-800'}`}
          >
            {title}
          </h3>
          <SectionActions
            matterId={matterId}
            config={config}
            onAddNew={isLoading ? undefined : onAddNew}
            showAddButton={isLoading ? false : showAddButton}
            isEmptyState={isEmptyState}
            onCleared={onCleared}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
