import { zodResolver } from '@hookform/resolvers/zod';
import { Calendar, Plus, RefreshCw } from 'lucide-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { useTemplateState } from '@/components/template-editor/hooks/useTemplateState';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogTitle,
  RightDialogContent,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useInitiateTaskCallMutation } from '@/services/api/blandCallService';
import {
  useCreateBlandScheduleMutation,
  useUpdateBlandScheduleMutation,
} from '@/services/api/blandScheduleService';
import { useGetBlandTemplatesQuery } from '@/services/api/blandtemplateService';
import { useGetMatterContextQuery } from '@/services/api/matterContextService';
import {
  BlandSchedule,
  CreateBlandScheduleRequest,
  SendCallRequest,
  UpdateBlandScheduleRequest,
} from '@/services/types/bland-schedules-types';
import { PhoneCallAction, Task } from '@/services/types/client-intake-types';
import {
  ContactType,
  TASKS_WITH_ATTACHED_INSURANCE_POLICY,
} from '@/services/types/task-types';
import { isPhoneNumberValid } from '@/utils/validation';

import { TASKS_WITH_ATTACHED_MEDICAL_PROVIDER } from '../../services/types/task-types';
import { ContactSelector } from './ContactSelector';
import { PromptSelector } from './PromptSelector';
import { ScheduleForm } from './schedule-form';
import { TaskSchedulesList } from './TaskSchedulesList';

const formSchema = z.object({
  provider: z
    .object({
      id: z.string(),
      name: z.string(),
      phone: z.string(),
      type: z.nativeEnum(ContactType),
    })
    .nullable()
    .optional(),
  phoneNumber: z
    .string({
      required_error: 'Phone number is required',
      invalid_type_error: 'Phone number must be a valid string',
    })
    .min(1, 'Phone number is required')
    .refine((val) => isPhoneNumberValid(val), {
      message: 'Please enter a valid phone number (e.g. 303-217-0796)',
    }),
});

type FormValues = z.infer<typeof formSchema>;

interface VoiceAICallModalProps {
  task: Task;
  onClose: () => void;
  handleSubmit: (action: PhoneCallAction) => void;
}

interface RefreshContextButtonProps {
  onRefresh: () => Promise<any>;
}

const RefreshContextButton: React.FC<RefreshContextButtonProps> = ({
  onRefresh,
}) => {
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  return (
    <div className="flex justify-start my-2">
      <Button
        variant="ghost"
        size="sm"
        type="button"
        onClick={async () => {
          setIsRefreshing(true);
          try {
            await onRefresh();
            toast.success('Context refreshed');
          } catch (error) {
            toast.error('Failed to refresh context');
          } finally {
            setIsRefreshing(false);
          }
        }}
        disabled={isRefreshing}
        className="h-7  text-xs text-gray-500 hover:text-gray-900"
      >
        <RefreshCw
          className={`h-3 w-3 mr-1 ${isRefreshing ? 'animate-spin' : ''}`}
        />
        refresh context
      </Button>
    </div>
  );
};

export const VoiceAICallModal: React.FC<VoiceAICallModalProps> = ({
  task,
  onClose,
  handleSubmit,
}) => {
  const [initiateCall] = useInitiateTaskCallMutation();
  const [createSchedule, { isLoading: isCreating }] =
    useCreateBlandScheduleMutation();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('immediate');

  const isMedicalTask = TASKS_WITH_ATTACHED_MEDICAL_PROVIDER.includes(
    task.type as any,
  );
  const isInsuranceTask = TASKS_WITH_ATTACHED_INSURANCE_POLICY.includes(
    task.type as any,
  );

  const { data: templates = [], isLoading: isLoadingTemplates } =
    useGetBlandTemplatesQuery();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const selectedContact = form.watch('provider');
  const phoneNumber = form.watch('phoneNumber');

  // Query for matter context
  const queryParams = {
    matterId: task.matter,
    ...(selectedContact?.type === ContactType.MEDICAL_PROVIDER && {
      medicalNoteId: selectedContact.id,
    }),
    ...(selectedContact?.type === ContactType.INSURANCE_ADJUSTER && {
      insuranceNoteId: selectedContact.id,
    }),
  };

  const { data: matterContext, refetch: refetchMatterContext } =
    useGetMatterContextQuery(queryParams);

  const {
    template: selectedTemplate,
    setTemplate: setSelectedTemplate,
    handleContentChange,
    handleSettingsChange,
    getRenderedTemplate,
  } = useTemplateState({});

  // Transform matterContext to ensure all values are strings
  const templateValues = React.useMemo(() => {
    if (!matterContext) return {};
    return Object.entries(matterContext).reduce(
      (acc, [key, value]) => {
        acc[key] = value || '';
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [matterContext]);

  // Add a ref to store the validation state
  const hasUnpopulatedVariablesRef = React.useRef(false);

  // Prepare the template data for scheduling
  const prepareTemplateForSchedule = (): SendCallRequest => {
    if (!selectedTemplate) {
      return {
        phone_number: phoneNumber || '',
      };
    }

    return {
      phone_number: phoneNumber || '',
      task: getRenderedTemplate(),
      prompt: selectedTemplate.prompt || '',
      prompt_html: selectedTemplate.prompt_html || '',
      prompt_json: selectedTemplate.prompt_json || '',
      voice: selectedTemplate.voice,
      model: selectedTemplate.model,
      temperature: selectedTemplate.temperature,
      first_sentence: selectedTemplate.first_sentence,
      wait_for_greeting: selectedTemplate.wait_for_greeting,
      block_interruptions: selectedTemplate.block_interruptions,
      interruption_threshold: selectedTemplate.interruption_threshold,
      background_track: selectedTemplate.background_track,
      noise_cancellation: selectedTemplate.noise_cancellation,
      max_duration: selectedTemplate.max_duration,
      voicemail_action: selectedTemplate.voicemail_action,
      voicemail_message: selectedTemplate.voicemail_message,
      available_tags: selectedTemplate.available_tags,
    };
  };

  // Simplified schedule handling - only creating schedules
  const handleCreateSchedule = async (
    scheduleData: CreateBlandScheduleRequest,
  ) => {
    try {
      // Ensure retry settings are properly set
      const scheduleWithRetry: CreateBlandScheduleRequest = {
        ...scheduleData,
        retry_enabled: scheduleData.retry_enabled ?? false,
        max_retries: scheduleData.retry_enabled
          ? (scheduleData.max_retries ?? 3)
          : 0,
        retry_delay_seconds: scheduleData.retry_enabled
          ? (scheduleData.retry_delay_seconds ?? 300)
          : 0,
      };

      await createSchedule(scheduleWithRetry).unwrap();
      toast.success('Schedule created successfully');
      onClose(); // Close modal after successful creation
    } catch (error) {
      console.error('Error creating schedule:', error);
      toast.error('Failed to create schedule');
    }
  };

  return (
    <Dialog open onOpenChange={onClose} modal={false}>
      <RightDialogContent className="bg-white h-[95vh] overflow-y-scroll flex flex-col gap-4">
        <DialogTitle className="sr-only">Voice AI Call</DialogTitle>
        <Form {...form}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1 border-b pb-4 border-gray-200">
              <span className="text-xs text-gray-500 font-semibold uppercase">
                TASK - {task.type.name}
              </span>
              <h2 className="text-2xl font-bold">Voice AI Call</h2>
            </div>

            <div className="flex flex-col gap-3">
              <ContactSelector
                form={form}
                matterId={task.matter}
                isMedicalTask={isMedicalTask}
                isInsuranceTask={isInsuranceTask}
              />

              <PromptSelector
                templates={templates}
                isLoadingTemplates={isLoadingTemplates}
                taskType={task.type}
                isMedicalTask={isMedicalTask}
                isInsuranceTask={isInsuranceTask}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                handleContentChange={handleContentChange}
                handleSettingsChange={handleSettingsChange}
                onHasUnpopulatedVariablesChange={(hasUnpopulated) => {
                  hasUnpopulatedVariablesRef.current = hasUnpopulated;
                }}
                templateValues={templateValues}
              />
              <RefreshContextButton onRefresh={refetchMatterContext} />
            </div>

            <Tabs
              defaultValue="immediate"
              value={activeTab}
              onValueChange={setActiveTab}
              className="w-full"
            >
              <TabsList className="grid grid-cols-2 w-64 mb-4">
                <TabsTrigger value="immediate">Immediate Call</TabsTrigger>
                <TabsTrigger value="schedule">Schedule Call</TabsTrigger>
              </TabsList>

              <TabsContent value="immediate" className="space-y-4">
                <div className="flex justify-end">
                  <Button
                    className="text-white bg-primary font-bold"
                    size="lg"
                    type="button"
                    onClick={form.handleSubmit(
                      async (values) => {
                        // Block submission if there are unpopulated variables
                        if (hasUnpopulatedVariablesRef.current) {
                          toast.error(
                            'Please fill in all required template variables before proceeding',
                          );
                          return;
                        }

                        if (!selectedTemplate) {
                          toast.error(
                            'Please select a template before proceeding',
                          );
                          return;
                        }

                        try {
                          setIsSubmitting(true);

                          // Call the bland call API
                          const response = await initiateCall({
                            task_id: task.id,
                            send_call_request: {
                              phone_number: values.phoneNumber,
                              task: getRenderedTemplate(),
                              prompt: selectedTemplate.prompt || '',
                              prompt_html: selectedTemplate.prompt_html || '',
                              prompt_json: selectedTemplate.prompt_json || '',
                              voice: selectedTemplate.voice,
                              model: selectedTemplate.model,
                              temperature: selectedTemplate.temperature,
                              first_sentence: selectedTemplate.first_sentence,
                              wait_for_greeting:
                                selectedTemplate.wait_for_greeting,
                              block_interruptions:
                                selectedTemplate.block_interruptions,
                              interruption_threshold:
                                selectedTemplate.interruption_threshold,
                              background_track:
                                selectedTemplate.background_track,
                              noise_cancellation:
                                selectedTemplate.noise_cancellation,
                              max_duration: selectedTemplate.max_duration,
                              voicemail_action:
                                selectedTemplate.voicemail_action,
                              voicemail_message:
                                selectedTemplate.voicemail_message,
                              available_tags: selectedTemplate.available_tags,
                            },
                          }).unwrap();

                          handleSubmit({
                            template: selectedTemplate.id,
                            phone_number: values.phoneNumber,
                            provider: values.provider?.name || 'Unknown',
                            interrupt: false,
                            bland_call_id: response.bland_call_id,
                            call_status: response.status || 'initiated',
                          });

                          toast.success('Call initiated successfully');
                          onClose();
                        } catch (err) {
                          console.error('Failed to initiate call:', err);
                          toast.error(
                            'Failed to initiate call. Please try again.',
                          );
                          return;
                        } finally {
                          setIsSubmitting(false);
                        }
                      },
                      (errors) => {
                        console.error('Form validation errors:', errors);
                        // Handle validation errors
                        if (errors.phoneNumber) {
                          toast.error(
                            errors.phoneNumber.message ||
                              'Please enter a valid phone number',
                          );
                        }
                        if (errors.provider) {
                          toast.error(
                            'Please select a valid contact or clear the selection',
                          );
                        }
                        // If no specific errors were caught, show a generic message
                        if (
                          !errors.phoneNumber &&
                          !errors.provider &&
                          Object.keys(errors).length > 0
                        ) {
                          toast.error(
                            'Please fix the form errors before submitting',
                          );
                        }
                      },
                    )}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Call Now'}
                  </Button>
                </div>
              </TabsContent>

              <TabsContent value="schedule" className="space-y-4">
                {/* Simplified schedule section - only show form for creating */}
                {!selectedTemplate && (
                  <div className="text-center p-4 border rounded bg-yellow-50 mb-4">
                    <p>Please select a template first to schedule a call</p>
                  </div>
                )}

                {selectedTemplate && !phoneNumber && (
                  <div className="text-center p-4 border rounded bg-yellow-50 mb-4">
                    <p>
                      Please enter a phone number before creating a schedule
                    </p>
                  </div>
                )}

                {selectedTemplate && phoneNumber && (
                  <ScheduleForm
                    taskId={task.id}
                    template={prepareTemplateForSchedule()}
                    onSubmit={handleCreateSchedule}
                    onCancel={onClose}
                  />
                )}
              </TabsContent>
            </Tabs>
          </div>
        </Form>
      </RightDialogContent>
    </Dialog>
  );
};
