import { addDays, format, getDaysInMonth, isWeekend, setDate } from 'date-fns';

import { CreateBlandScheduleRequest } from '@/services/types/bland-schedules-types';

import { ScheduleFormValues } from './types';

// Custom CSS for calendar animations
export const calendarStyles = `
  .day-selected {
    box-shadow: 0 0 0 2px hsl(var(--primary));
    transition: box-shadow 0.1s ease-in-out;
  }
`;

// Function to get the next 5 business days (excluding weekends)
export const getNextBusinessDays = (count = 5): Date[] => {
  const result: Date[] = [];
  let currentDate = new Date();

  while (result.length < count) {
    currentDate = addDays(currentDate, 1);
    if (!isWeekend(currentDate)) {
      // Clone the date to avoid reference issues
      result.push(new Date(currentDate));
    }
  }

  return result;
};

// Function to get all business days in the current month
export const getAllBusinessDaysInMonth = (): Date[] => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const daysInMonth = getDaysInMonth(today);

  const businessDays: Date[] = [];

  for (let day = 1; day <= daysInMonth; day += 1) {
    const date = new Date(year, month, day);
    if (!isWeekend(date)) {
      businessDays.push(date);
    }
  }

  return businessDays;
};

// Convert calendar_day_of_month array to Date objects for the form
export const getInitialDates = (days: number[] = []): Date[] => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  return days.map((day) => {
    // Create a date object for each day in the current month/year
    return setDate(new Date(currentYear, currentMonth, 1), day);
  });
};

// Default to 1st and 15th of the month if no days specified
export const getDefaultDates = (): Date[] => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  return [
    new Date(currentYear, currentMonth, 1), // 1st of the month
    new Date(currentYear, currentMonth, 15), // 15th of the month
  ];
};

// Function to extract day of month values from Date objects
export const getDaysOfMonth = (dates: Date[]): number[] => {
  return dates.map((date) => date.getDate()).sort((a, b) => a - b);
};

// Function to build schedule config from form values
export const buildScheduleConfig = (
  values: ScheduleFormValues,
  taskId: string,
  template: any,
  initialScheduleId?: string,
): CreateBlandScheduleRequest & { id?: string } => {
  // Parse the time
  const [hour, minute] = values.time.split(':').map((n) => parseInt(n, 10));

  // Create the schedule configuration
  const scheduleConfig: CreateBlandScheduleRequest & { id?: string } = {
    phone_number: template.phone_number, // Use phone number from template
    task_id: taskId,
    bland_payload: template,
    timezone: values.timezone,
    calendar_minute: [minute || 0],
    calendar_hour: [hour || 0],
    retry_enabled: values.retryEnabled,
    max_retries: values.retryEnabled ? values.maxRetries : 0,
    retry_delay_seconds: values.retryEnabled ? values.retryDelay : 0,
  };

  // Set either days of month or days of week based on selection mode
  if (values.selectionMode === 'dates' && values.selectedDates) {
    // Extract days of month from Date objects
    const daysOfMonth = getDaysOfMonth(values.selectedDates);
    scheduleConfig.calendar_day_of_month = daysOfMonth;
    scheduleConfig.calendar_day_of_week = null;

    // Find earliest and latest selected dates for schedule range
    const sortedDates = [...values.selectedDates].sort(
      (a, b) => a.getTime() - b.getTime(),
    );
    const earliestDate = sortedDates[0];
    const latestDate = sortedDates[sortedDates.length - 1];

    // Set time components on the dates (using the selected hour/minute)
    const startDate = new Date(earliestDate);
    startDate.setHours(hour, minute, 0, 0);

    const endDate = new Date(latestDate);
    endDate.setHours(23, 59, 59, 999); // End of the last selected day

    scheduleConfig.schedule_start_at = startDate.toISOString();
    scheduleConfig.schedule_end_at = endDate.toISOString();
  } else if (
    values.selectionMode === 'daysOfWeek' &&
    values.selectedDaysOfWeek
  ) {
    scheduleConfig.calendar_day_of_week = values.selectedDaysOfWeek;
    scheduleConfig.calendar_day_of_month = null;

    // For days of week, use today as start date
    const today = new Date();
    const startDate = new Date(today);
    startDate.setHours(hour, minute, 0, 0);

    // Use the selected end date or default to today + 7 days if not provided
    let endDate: Date;
    if (values.endDate) {
      // Clone the date to avoid modifying the original
      endDate = new Date(values.endDate);
    } else {
      // Default to today + 7 days
      endDate = new Date(today);
      endDate.setDate(endDate.getDate() + 7);
    }

    // Set the time to end of day
    endDate.setHours(23, 59, 59, 999);

    scheduleConfig.schedule_start_at = startDate.toISOString();
    scheduleConfig.schedule_end_at = endDate.toISOString();
  }

  // Add schedule ID if editing an existing schedule
  if (initialScheduleId) {
    scheduleConfig.id = initialScheduleId;
  }

  return scheduleConfig;
};
