import { AlertTriangle } from 'lucide-react';
import * as React from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { taskIsPastDue } from '@/lib/utils';
import { Task } from '@/services/types/client-intake-types';

import { TaskDueDate } from '../TaskDueDate';

const OverdueWarning: React.FC = () => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <AlertTriangle size={16} className="text-amber-500" />
      </TooltipTrigger>
      <TooltipContent>
        <span>This task is overdue</span>
      </TooltipContent>
    </Tooltip>
  );
};

const TaskDatePicker: React.FC<{
  date: string | undefined;
  label: string;
  onDateChange: (date: Date | undefined) => void;
  overdue?: boolean;
}> = ({ date, label, onDateChange, overdue = false }) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-row gap-2 items-center justify-between">
        <span
          id="target-date-label"
          className="text-sm text-neutral-500 font-bold"
        >
          {label}
        </span>
        {overdue && <OverdueWarning />}
      </div>
      <TaskDueDate date={date} onDateChange={onDateChange} />
    </div>
  );
};

interface TaskDatesProps {
  task: Task;
  onStartDateChange: (date: Date | undefined) => void;
  onDueDateChange: (date: Date | undefined) => void;
  onCompletedDateChange: (date: Date | undefined) => void;
}

export const TaskDates: React.FC<TaskDatesProps> = ({
  task,
  onStartDateChange,
  onDueDateChange,
  onCompletedDateChange,
}) => {
  return (
    <div className="flex flex-row gap-2 justify-between mb-2 w-full border-b border-gray-200 pt-2 pb-4">
      <div className="flex items-start">
        <TaskDatePicker
          date={task.start_date}
          label="Start Date"
          onDateChange={onStartDateChange}
        />
      </div>
      <div className="flex items-center">
        <TaskDatePicker
          date={task.due_date}
          label="Target Date"
          onDateChange={onDueDateChange}
          overdue={taskIsPastDue(task)}
        />
      </div>
      <div className="flex items-end">
        <TaskDatePicker
          date={task.completed_at}
          label="Completed Date"
          onDateChange={onCompletedDateChange}
        />
      </div>
    </div>
  );
};
