import { Check } from 'lucide-react';
import * as React from 'react';
import { useState } from 'react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { User } from '@/services/types/user-types';

import { Button } from '../ui/button';

interface AssigneeProps {
  assignee: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  } | null;
  staffUsers: User[];
  isLoading: boolean;
  onAssigneeChange: (userId: string | null) => void;
}

export const getInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
};

export const TaskAssignee: React.FC<AssigneeProps> = ({
  assignee,
  onAssigneeChange,
  staffUsers,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 animate-pulse" />
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <PopoverTrigger onClick={(e) => e.stopPropagation()} asChild>
              <Button
                type="reset"
                size="icon"
                className={cn(
                  'flex hover:bg-gray-200 border border-gray-200 items-center justify-center w-8 h-8 rounded-full text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
                  'bg-gray-100 text-primary-foreground',
                )}
              >
                <span className="text-gray-600 font-semibold">
                  {assignee
                    ? getInitials(assignee.first_name, assignee.last_name)
                    : '—'}
                </span>
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          {assignee && (
            <TooltipContent>
              <p>{assignee.email}</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <PopoverContent className="p-0 w-[200px] bg-white" align="center">
        <Command>
          <CommandInput
            placeholder="Search users..."
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <CommandList
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CommandEmpty>No users found.</CommandEmpty>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  onAssigneeChange(null);
                  setOpen(false);
                }}
                className="hover:bg-gray-100 cursor-pointer"
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    !assignee ? 'opacity-100' : 'opacity-0',
                  )}
                />
                <span>Unassigned</span>
              </CommandItem>
              {staffUsers?.map((user) => (
                <CommandItem
                  key={user.id}
                  onSelect={() => {
                    onAssigneeChange(user.id);
                    setOpen(false);
                  }}
                  className="flex items-center hover:bg-gray-100 cursor-pointer"
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      assignee?.id === user.id ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  <div className="flex items-center">
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
