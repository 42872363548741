import { TextField, TextFieldProps } from '@mui/material';
import { merge } from 'lodash-es';
import { forwardRef, useMemo } from 'react';

import { EmailRegex } from '../../../utils/validation';

export const EmailFormField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ value, slotProps, ...rest }, ref) => {
    const mergedSlotProps = useMemo(
      () =>
        merge(
          {
            htmlInput: {
              pattern: EmailRegex,
            },
          },
          slotProps,
        ),
      [slotProps],
    );

    return (
      <TextField
        ref={ref}
        fullWidth
        name="email"
        autoComplete="email"
        slotProps={mergedSlotProps}
        {...rest}
      />
    );
  },
);

EmailFormField.displayName = 'EmailFormField';
