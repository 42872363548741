import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useGetMatterContextQuery } from '@/services/api/matterContextService';
import {
  PhoneCallAction,
  Task,
  TaskUpdateVisibility,
} from '@/services/types/client-intake-types';
import { CanonicalTaskType } from '@/services/types/task-types';

import { CallClientButton } from '../CallClientButton';
import { CrashReportLookupModal } from '../CrashReportLookupModal';
import { OperatorMessageModal } from '../OperatorMessageModal';
import { VoiceAICallModal } from '../VoiceAICallModal';

interface TaskActionsProps {
  task: Task;
  handleSubmit: (action: PhoneCallAction) => void;
  handleLookupCrashDocs?: (formData?: any) => Promise<void>;
  createTaskUpdate: (params: {
    matterId: string;
    taskId: string;
    update: {
      update: string;
      visibility: TaskUpdateVisibility;
    };
  }) => void;
  refetchTaskUpdates?: () => void;
  isLoading?: boolean;
}

export const TaskActions: React.FC<TaskActionsProps> = ({
  task,
  handleSubmit,
  handleLookupCrashDocs: propHandleLookupCrashDocs,
  createTaskUpdate,
  refetchTaskUpdates,
  isLoading = false,
}) => {
  const [showVoiceAIModal, setShowVoiceAIModal] = React.useState(false);
  const [showOperatorMessageModal, setShowOperatorMessageModal] =
    React.useState(false);
  const [showCrashReportLookupModal, setShowCrashReportLookupModal] =
    React.useState(false);
  const { matter_id } = useParams();
  const { data: matterContext } = useGetMatterContextQuery({
    matterId: task.matter,
  });

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-lg font-semibold">Actions</h3>
      <div className="flex flex-row gap-2">
        <Button
          className="font-semibold text-sm cursor-pointer "
          onClick={() => setShowVoiceAIModal(true)}
        >
          Voice AI Call
        </Button>
        <Button
          variant="outline"
          className="font-semibold text-sm  "
          onClick={() => setShowOperatorMessageModal(true)}
        >
          Send to External Ops
        </Button>
        {matterContext?.clientPhone && (
          <CallClientButton phoneNumber={matterContext.clientPhone} />
        )}
        {task.type.canonical_type === CanonicalTaskType.POLICE_REPORT && (
          <Button
            variant="outline"
            className="font-semibold text-sm"
            onClick={() => setShowCrashReportLookupModal(true)}
          >
            Lookup Online
          </Button>
        )}
      </div>
      {showVoiceAIModal && (
        <VoiceAICallModal
          task={task}
          onClose={() => setShowVoiceAIModal(false)}
          handleSubmit={handleSubmit}
        />
      )}
      {showOperatorMessageModal && (
        <OperatorMessageModal
          task={task}
          onClose={() => {
            setShowOperatorMessageModal(false);
            // Refresh task updates when the modal is closed
            if (refetchTaskUpdates) {
              refetchTaskUpdates();
            }
          }}
          handleSubmit={() => {
            // We're not using this anymore since the backend is handling the update
          }}
        />
      )}
      {showCrashReportLookupModal && (
        <CrashReportLookupModal
          isOpen={showCrashReportLookupModal}
          onClose={() => setShowCrashReportLookupModal(false)}
          onSubmit={(formData) => {
            if (propHandleLookupCrashDocs) {
              propHandleLookupCrashDocs(formData);
              setShowCrashReportLookupModal(false);
            }
          }}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
