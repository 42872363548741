import * as React from 'react';

import { TemplateEditor } from '@/components/template-editor/components/TemplateEditor';
import {
  TemplateVariablesProvider,
  useTemplateVariables,
} from '@/components/template-editor/context/TemplateContext';
import { GroupedCombobox } from '@/components/ui/grouped-combobox';
import { Label } from '@/components/ui/label';
import { TaskType } from '@/services/types/client-intake-types';
import {
  CanonicalTaskType,
  TASKS_WITH_ATTACHED_INSURANCE_POLICY,
  TASKS_WITH_ATTACHED_MEDICAL_PROVIDER,
} from '@/services/types/task-types';

import { BlandCallTemplate } from '../../services/types/bland-templates.types';

interface PromptSelectorProps {
  templates: BlandCallTemplate[];
  isLoadingTemplates: boolean;
  taskType: TaskType;
  isMedicalTask: boolean;
  isInsuranceTask: boolean;
  selectedTemplate: BlandCallTemplate | null;
  setSelectedTemplate: (template: BlandCallTemplate | null) => void;
  handleContentChange: (content: any) => void;
  handleSettingsChange: (settings: any) => void;
  onHasUnpopulatedVariablesChange: (hasUnpopulated: boolean) => void;
  templateValues: Record<string, string>;
}

interface TemplateContentProps {
  selectedTemplate: BlandCallTemplate;
  handleContentChange: (content: any) => void;
  handleSettingsChange: (settings: any) => void;
  onHasUnpopulatedVariablesChange: (hasUnpopulated: boolean) => void;
}

const TemplateContent: React.FC<TemplateContentProps> = ({
  selectedTemplate,
  handleContentChange,
  handleSettingsChange,
  onHasUnpopulatedVariablesChange,
}) => {
  const { hasUnpopulatedVariables, getUnpopulatedVariables } =
    useTemplateVariables();
  const unpopulatedVars = getUnpopulatedVariables();

  React.useEffect(() => {
    onHasUnpopulatedVariablesChange(hasUnpopulatedVariables);
  }, [hasUnpopulatedVariables, onHasUnpopulatedVariablesChange]);

  return (
    <>
      {hasUnpopulatedVariables && (
        <div className="p-3 border-b border-red-200 bg-red-50">
          <h4 className="font-medium text-red-700">Missing Information</h4>
          <p className="mt-1 text-red-600/80 text-xs">
            Some required information is missing. Please either remove these
            fields from the prompt or add the missing details:
          </p>
          <div className="mt-2 grid grid-cols-2 gap-1 text-xs">
            {unpopulatedVars.map((variable: string) => (
              <div key={variable} className="text-red-600">
                {variable}
              </div>
            ))}
          </div>
        </div>
      )}
      <TemplateEditor
        template={selectedTemplate}
        onContentChange={handleContentChange}
        onSettingsChange={handleSettingsChange}
      />
    </>
  );
};

// Helper function to format the date in a human-readable way
const formatDate = (date: string) => {
  const d = new Date(date);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - d.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) return 'just now';
  if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
  if (diffInHours < 24) return `${diffInHours}h ago`;
  if (diffInDays < 7) return `${diffInDays}d ago`;

  return d.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: d.getFullYear() !== now.getFullYear() ? 'numeric' : undefined,
  });
};

export const PromptSelector: React.FC<PromptSelectorProps> = ({
  templates,
  isLoadingTemplates,
  taskType,
  isMedicalTask,
  isInsuranceTask,
  selectedTemplate,
  setSelectedTemplate,
  handleContentChange,
  handleSettingsChange,
  onHasUnpopulatedVariablesChange,
  templateValues,
}) => {
  // Categorize templates into suggested and other
  const categorizedTemplates = React.useMemo(() => {
    // Exact task-specific templates
    const taskSpecific = templates
      .filter(
        (template) => template.finch_task_type === taskType.canonical_type,
      )
      .sort((a, b) => a.title.localeCompare(b.title));

    // Templates for medical tasks
    const medicalTemplates = isMedicalTask
      ? templates
          .filter(
            (template) =>
              template.finch_task_type !== taskType.canonical_type && // Don't include already included task-specific ones
              TASKS_WITH_ATTACHED_MEDICAL_PROVIDER.includes(
                template.finch_task_type as CanonicalTaskType,
              ),
          )
          .sort((a, b) => a.title.localeCompare(b.title))
      : [];

    // Templates for insurance tasks
    const insuranceTemplates = isInsuranceTask
      ? templates
          .filter(
            (template) =>
              template.finch_task_type !== taskType.canonical_type && // Don't include already included task-specific ones
              TASKS_WITH_ATTACHED_INSURANCE_POLICY.includes(
                template.finch_task_type as CanonicalTaskType,
              ),
          )
          .sort((a, b) => a.title.localeCompare(b.title))
      : [];

    // Combine all suggested templates
    const suggested = [
      ...taskSpecific,
      ...medicalTemplates,
      ...insuranceTemplates,
    ];

    // All other templates go into allOthers
    const allOthers = templates
      .filter(
        (template) => !suggested.some((s) => s.id === template.id), // Exclude all suggested templates
      )
      .sort((a, b) => a.title.localeCompare(b.title));

    return {
      taskSpecific,
      medicalTemplates,
      insuranceTemplates,
      suggested,
      allOthers,
      all: templates,
    };
  }, [templates, taskType, isMedicalTask, isInsuranceTask]);

  // Set initial template when templates are loaded
  React.useEffect(() => {
    if (templates.length > 0 && !selectedTemplate) {
      let initialTemplate = templates[0];

      const [firstTaskSpecific] = categorizedTemplates.taskSpecific;
      const [firstSuggested] = categorizedTemplates.suggested;

      if (firstTaskSpecific) {
        initialTemplate = firstTaskSpecific;
      } else if (firstSuggested) {
        initialTemplate = firstSuggested;
      }

      setSelectedTemplate(initialTemplate);
    }
  }, [templates, selectedTemplate, categorizedTemplates, setSelectedTemplate]);

  return (
    <>
      <GroupedCombobox<BlandCallTemplate>
        label={
          <div className="flex items-center justify-between w-full">
            <span>Prompt</span>
            <a
              href="/template-editor"
              className="text-xs text-muted-foreground hover:text-primary transition-colors flex items-center gap-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Manage prompts</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ml-0.5"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                <polyline points="15 3 21 3 21 9" />
                <line x1="10" y1="14" x2="21" y2="3" />
              </svg>
            </a>
          </div>
        }
        groups={[
          ...(categorizedTemplates.suggested.length > 0
            ? [
                {
                  label: 'Suggested Prompts',
                  options: categorizedTemplates.suggested,
                },
              ]
            : []),
          ...(categorizedTemplates.allOthers.length > 0
            ? [
                {
                  label: 'All Prompts',
                  options: categorizedTemplates.allOthers,
                },
              ]
            : []),
        ]}
        value={selectedTemplate}
        onChange={(value) => {
          setSelectedTemplate(value);
        }}
        placeholder={
          isLoadingTemplates ? 'Loading templates...' : 'Select a template'
        }
        displayValue={(template) => template.title}
        renderItem={({ item: template, displayValue }) => (
          <div className="flex flex-col min-w-0">
            <div className="font-medium truncate text-gray-900">
              {displayValue}
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-[11px] text-gray-500">
                {template.finch_task_type}
              </span>
              <span className="text-[11px] text-gray-400">
                Updated {formatDate(template.modified_at)}
              </span>
            </div>
          </div>
        )}
        renderSelection={({ item: template, displayValue }) => (
          <div className="flex flex-col gap-0.5 min-w-0">
            <div className="font-medium text-sm truncate text-left">
              {displayValue}
            </div>
            <div className="flex items-center gap-2 text-left">
              <span className="text-[11px] text-gray-500">
                {template.finch_task_type}
              </span>
              <span className="text-[11px] text-gray-400">
                • Updated {formatDate(template.modified_at)}
              </span>
            </div>
          </div>
        )}
      />

      <div>
        <Label className="font-bold">Instructions</Label>
        <div className="border rounded-lg">
          <TemplateVariablesProvider
            values={templateValues}
            setValues={() => {}}
          >
            {selectedTemplate ? (
              <TemplateContent
                selectedTemplate={selectedTemplate}
                handleContentChange={handleContentChange}
                handleSettingsChange={handleSettingsChange}
                onHasUnpopulatedVariablesChange={
                  onHasUnpopulatedVariablesChange
                }
              />
            ) : (
              <div className="p-4 text-gray-500">
                Please select a template to proceed
              </div>
            )}
          </TemplateVariablesProvider>
        </div>
      </div>
    </>
  );
};
