/* eslint-disable no-console */
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import {
  CreateMatterMessageRequest,
  MatterMessage,
  MessageRole,
  MessageStatus,
} from '../services/api/matterAssistant';
import {
  addMessage,
  MessageContentDelta,
  updateMessage,
  updateMessageContent,
} from '../state/selectors/matter-assistant-selectors';
import { SSEParser } from '../utils/sse-parser';

interface MessageCreated {
  data: MatterMessage;
  type: 'message_created';
}

interface MessageInProgress {
  data: MatterMessage;
  type: 'message_in_progress';
}
export interface MessageDelta {
  data: MessageContentDelta;
  type: 'message_delta';
}

interface MessageCompleted {
  data: MatterMessage;
  type: 'message_completed';
}

interface MessageIncomplete {
  data: MatterMessage;
  type: 'message_incomplete';
}

type MessageEvent =
  | MessageCreated
  | MessageInProgress
  | MessageDelta
  | MessageCompleted
  | MessageIncomplete;

// SSE Parser class to handle streaming responses
// Moved to src/utils/sse-parser.ts

const messageToRequest = (
  message: MatterMessage,
): CreateMatterMessageRequest => {
  return {
    id: message.id,
    parent_id: message.parent_id,
    role: message.role,
    status: message.status,
    created_at: message.created_at,
    modified_at: message.modified_at,
    content: message.content.map((content) => ({
      type: 'text',
      text: content.text_content || '',
    })),
  };
};

export const useSendMessage = (matterId: string, threadId: string) => {
  const sendMessage = async (
    parentMessageId: string | null,
    message: string,
  ) => {
    const newMessage: MatterMessage = {
      id: uuidv4(),
      parent_id: parentMessageId,
      role: MessageRole.USER,
      status: MessageStatus.COMPLETED,
      content: [
        {
          text_content: message,
          image_content: null,
        },
      ],
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
    };

    // Optimistically add the message to the UI
    addMessage(matterId, threadId, newMessage);

    // Send the message to the API
    const newMessageRequest = messageToRequest(newMessage);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/matters/${matterId}/threads/${threadId}/messages/run/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken') || '',
          },
          credentials: 'include',
          body: JSON.stringify({
            message: newMessageRequest,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      // Get a reader for the response body stream
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get reader from response');
      }

      // Create SSE parser with the reader and subscribe to events
      let currentMessageId: string | null = null;

      const parser = new SSEParser(reader);

      parser.subscribe((event) => {
        if (event.type === 'message_created') {
          addMessage(matterId, threadId, event.data);
          currentMessageId = event.data.id;
        }

        if (event.type === 'message_in_progress') {
          updateMessage(matterId, threadId, event.data.id, {
            status: MessageStatus.IN_PROGRESS,
          });
        }

        if (event.type === 'message_completed') {
          updateMessage(matterId, threadId, event.data.id, {
            status: MessageStatus.COMPLETED,
          });
        }

        if (event.type === 'message_incomplete') {
          updateMessage(matterId, threadId, event.data.id, {
            status: MessageStatus.INCOMPLETE,
          });
        }

        if (event.type === 'message_delta') {
          updateMessageContent(matterId, threadId, event.data.id, event.data);
        }
      });

      await parser.start();
    } catch (error) {
      console.error('Error sending message:', error);
      // Here you might want to handle the error, e.g., update the message status
    }

    return newMessage.id;
  };

  return { sendMessage };
};
