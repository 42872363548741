import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Slider } from '@/components/ui/slider';
import { Switch } from '@/components/ui/switch';

import { ScheduleFormValues } from './types';

export const RetrySettings = () => {
  const form = useFormContext<ScheduleFormValues>();
  const retryEnabled = form.watch('retryEnabled');

  return (
    <>
      {/* Try again if no answer option */}
      <FormField
        control={form.control}
        name="retryEnabled"
        render={({ field }) => (
          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
            <div className="space-y-0.5">
              <FormLabel>Try again if no answer</FormLabel>
              <p className="text-sm text-muted-foreground">
                Call back (same day) if the call isn&apos;t answered
              </p>
            </div>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
          </FormItem>
        )}
      />

      {/* Retry Settings (shown conditionally) */}
      {retryEnabled && (
        <div className="grid grid-cols-2 gap-4 pl-3 border-l-2 border-l-muted-foreground/20">
          <FormField
            control={form.control}
            name="maxRetries"
            render={({ field }) => (
              <FormItem>
                <FormLabel>How many attempts?</FormLabel>
                <FormControl>
                  <div className="flex items-center w-32 mx-auto">
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      className="h-9 w-9 rounded-full"
                      onClick={() => {
                        const value = Math.max(1, field.value - 1);
                        field.onChange(value);
                      }}
                      disabled={field.value <= 1}
                    >
                      <span className="sr-only">Decrease</span>
                      <span className="text-lg font-semibold">-</span>
                    </Button>
                    <div className="flex-1 text-center font-medium text-xl px-3">
                      {field.value}
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      className="h-9 w-9 rounded-full"
                      onClick={() => {
                        const value = Math.min(10, field.value + 1);
                        field.onChange(value);
                      }}
                      disabled={field.value >= 10}
                    >
                      <span className="sr-only">Increase</span>
                      <span className="text-lg font-semibold">+</span>
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="retryDelay"
            render={({ field }) => {
              // Format the display value
              const displayValue =
                field.value < 3600
                  ? `${Math.round(field.value / 60)} minutes`
                  : `${(field.value / 3600).toFixed(1)} hours`;

              return (
                <FormItem>
                  <div className="flex justify-between mb-2">
                    <FormLabel>Wait before retry</FormLabel>
                    <span className="text-sm text-muted-foreground">
                      {displayValue}
                    </span>
                  </div>
                  <FormControl>
                    <Slider
                      min={300}
                      max={10800}
                      step={300}
                      value={[field.value]}
                      onValueChange={(vals) => field.onChange(vals[0])}
                      className="pt-2 cursor-pointer"
                    />
                  </FormControl>
                  <div className="flex justify-between mt-1 text-xs text-muted-foreground">
                    <span>5 min</span>
                    <span>1 hour</span>
                    <span>3 hours</span>
                  </div>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
      )}
    </>
  );
};
