import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BlandSchedule,
  BlandScheduleStatus,
  CreateBlandScheduleRequest,
  FilterBlandSchedulesRequest,
  ScheduleActionRequest,
  ScheduleDescriptionResponse,
  SendCallRequest,
  UpdateBlandScheduleRequest,
} from '../types/bland-schedules-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const blandScheduleApi = createApi({
  reducerPath: 'blandScheduleApi',
  tagTypes: ['BlandSchedule'],
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    // Get all schedules
    getBlandSchedules: builder.query<BlandSchedule[], void>({
      query: () => 'api/bland/schedules/',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'BlandSchedule' as const,
                id,
              })),
              { type: 'BlandSchedule', id: 'LIST' },
            ]
          : [{ type: 'BlandSchedule', id: 'LIST' }],
    }),

    // Get single schedule by ID
    getBlandScheduleById: builder.query<BlandSchedule, string>({
      query: (id) => `api/bland/schedules/${id}/`,
      providesTags: (result, error, id) => [{ type: 'BlandSchedule', id }],
    }),

    // Get schedules by task ID
    getBlandSchedulesByTaskId: builder.query<BlandSchedule[], string>({
      query: (taskId) => `api/bland/schedules/by-task/${taskId}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'BlandSchedule' as const,
                id,
              })),
              { type: 'BlandSchedule', id: 'LIST' },
            ]
          : [{ type: 'BlandSchedule', id: 'LIST' }],
    }),

    // Create a new schedule
    createBlandSchedule: builder.mutation<
      BlandSchedule,
      CreateBlandScheduleRequest
    >({
      query: (body) => ({
        url: 'api/bland/schedules/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BlandSchedule', id: 'LIST' }],
    }),

    // Update a schedule
    updateBlandSchedule: builder.mutation<
      BlandSchedule,
      UpdateBlandScheduleRequest
    >({
      query: ({ id, ...body }) => ({
        url: `api/bland/schedules/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'BlandSchedule', id },
        { type: 'BlandSchedule', id: 'LIST' },
      ],
    }),

    // Delete a schedule
    deleteBlandSchedule: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/bland/schedules/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'BlandSchedule', id },
        { type: 'BlandSchedule', id: 'LIST' },
      ],
    }),

    // Pause a schedule
    pauseBlandSchedule: builder.mutation<
      BlandSchedule,
      { id: string; request?: ScheduleActionRequest }
    >({
      query: ({ id, request }) => ({
        url: `api/bland/schedules/${id}/pause/`,
        method: 'POST',
        body: request || {},
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'BlandSchedule', id },
        { type: 'BlandSchedule', id: 'LIST' },
      ],
    }),

    // Resume a schedule
    resumeBlandSchedule: builder.mutation<
      BlandSchedule,
      { id: string; request?: ScheduleActionRequest }
    >({
      query: ({ id, request }) => ({
        url: `api/bland/schedules/${id}/resume/`,
        method: 'POST',
        body: request || {},
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'BlandSchedule', id },
        { type: 'BlandSchedule', id: 'LIST' },
      ],
    }),

    // Trigger a schedule immediately
    triggerBlandSchedule: builder.mutation<{ status: string }, string>({
      query: (id) => ({
        url: `api/bland/schedules/${id}/trigger/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'BlandSchedule', id }],
    }),

    // Get detailed information about a schedule
    describeBlandSchedule: builder.query<ScheduleDescriptionResponse, string>({
      query: (id) => `api/bland/schedules/${id}/describe/`,
      providesTags: (result, error, id) => [{ type: 'BlandSchedule', id }],
    }),

    // Filter schedules by various criteria
    filterBlandSchedules: builder.query<
      BlandSchedule[],
      FilterBlandSchedulesRequest
    >({
      query: (params) => {
        // Build query string from params
        const queryParams = new URLSearchParams();
        if (params.task_id) queryParams.append('task_id', params.task_id);
        if (params.status) queryParams.append('status', params.status);
        if (params.created_by_id)
          queryParams.append('created_by_id', params.created_by_id);
        if (params.phone_number)
          queryParams.append('phone_number', params.phone_number);

        return `api/bland/schedules/filter/?${queryParams.toString()}`;
      },
      providesTags: [{ type: 'BlandSchedule', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBlandSchedulesQuery,
  useGetBlandScheduleByIdQuery,
  useCreateBlandScheduleMutation,
  useUpdateBlandScheduleMutation,
  useDeleteBlandScheduleMutation,
  usePauseBlandScheduleMutation,
  useResumeBlandScheduleMutation,
  useTriggerBlandScheduleMutation,
  useDescribeBlandScheduleQuery,
  useFilterBlandSchedulesQuery,
  useGetBlandSchedulesByTaskIdQuery,
} = blandScheduleApi;
