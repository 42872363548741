import { AlertCircle } from 'lucide-react';
import React from 'react';

interface ErrorMessageProps {
  errorMessage: string | null;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
  if (!errorMessage) return null;

  return (
    <div className="bg-red-50 border border-red-200 rounded-lg p-3">
      <div className="flex items-center mb-1">
        <AlertCircle className="h-4 w-4 mr-2 text-red-600 flex-shrink-0" />
        <h4 className="font-medium text-red-700">Error</h4>
      </div>
      <div className="ml-6">
        <p className="text-red-600 text-xs">{errorMessage}</p>
      </div>
    </div>
  );
};
