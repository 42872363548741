import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BaseBlandCallTemplate,
  BlandCallTemplate,
} from '../types/bland-templates.types';
import { Task } from '../types/client-intake-types';
import { baseQueryWithErrorHandling } from './baseQuery';

// Types
export interface BlandCall {
  id: string;
  created_at: string;
  updated_at: string;
  task: Task;
  template: BlandCallTemplate;
  bland_call_id: string;
  to_number: string;
  from_number: string;
  transferred_to: string | null;
  started_at: string | null;
  call_length: number | null;
  corrected_duration: number | null;
  status: string | null;
  completed: boolean | null;
  error_message: string | null;
  disposition_tag: string | null;
  prompt: string | null;
  summary: string | null;
  concatenated_transcript: string | null;
  record: boolean | null;
  recording_url: string | null;
  recording_expiration: string | null;
  finch_metadata: Record<string, any>;
  raw_request_data: Record<string, any>;
  raw_response_data: Record<string, any>;
  price: number | null;
  events: any[]; // TODO: Define proper events type
}

// Additional fields specific to send call request that aren't in BaseBlandCallTemplate
interface SendCallRequestExtras {
  phone_number: string;
  task?: string;
}

export interface InitiateTaskCallRequest {
  task_id: string;
  send_call_request: Partial<BaseBlandCallTemplate> & SendCallRequestExtras;
}

export const blandCallApi = createApi({
  reducerPath: 'blandCallApi',
  tagTypes: ['BlandCall'],
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    // Get all calls
    getBlandCalls: builder.query<BlandCall[], void>({
      query: () => 'api/bland/calls/',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'BlandCall' as const,
                id,
              })),
              { type: 'BlandCall', id: 'LIST' },
            ]
          : [{ type: 'BlandCall', id: 'LIST' }],
    }),

    // Get single call by ID
    getBlandCallById: builder.query<BlandCall, string>({
      query: (id) => `api/bland/calls/${id}/`,
      providesTags: (result, error, id) => [{ type: 'BlandCall', id }],
    }),

    // Initiate a call with a task
    initiateTaskCall: builder.mutation<BlandCall, InitiateTaskCallRequest>({
      query: (body) => ({
        url: 'api/bland/calls/initiate_task_call/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BlandCall', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetBlandCallsQuery,
  useGetBlandCallByIdQuery,
  useInitiateTaskCallMutation,
} = blandCallApi;
