import {
  ChevronRight,
  Delete,
  Edit,
  Folder,
  MoreVert,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { DragEvent, useState } from 'react';

import {
  useCreateChildCollectionMutation,
  useDestroyCollectionMutation,
  useUpdateCollectionMutation,
} from '../../../../services/api/collectionService';
import { FolderItemProps } from '../types';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';

export const FolderItem = ({
  node,
  selectedId,
  setSelectedCollectionId,
  level = 0,
  rootNodeId,
  onUploadToCollection,
}: FolderItemProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [folderName, setFolderName] = useState(node.name);
  const [error, setError] = useState<string | null>(null);

  const [createChildCollection] = useCreateChildCollectionMutation();
  const [updateCollection] = useUpdateCollectionMutation();
  const [destroyCollection] = useDestroyCollectionMutation();

  const isSelected =
    (node.name === 'root' && selectedId === 'root') || node.id === selectedId;
  const isRoot = node.id === rootNodeId;
  const hasChildren = node.children && node.children.length > 0;

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleRenameFolder = async () => {
    if (!folderName.trim()) return;

    try {
      await updateCollection({
        matterId: node.matter_id,
        collectionId: node.id,
        name: folderName.trim(),
      }).unwrap();
      setRenameFolderDialogOpen(false);
      setError(null);
    } catch (e) {
      setError('Failed to rename folder');
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    try {
      await createChildCollection({
        matterId: node.matter_id,
        parentId: node.id,
        name: newFolderName.trim(),
      }).unwrap();
      setNewFolderName('');
      setNewFolderDialogOpen(false);
      setError(null);
    } catch (e) {
      setError('Failed to create folder');
    }
  };

  const handleDeleteFolder = async () => {
    try {
      await destroyCollection({
        matterId: node.matter_id,
        collectionId: node.id,
      }).unwrap();
      setDeleteFolderDialogOpen(false);
      setSelectedCollectionId(rootNodeId || 'unset');
      setError(null);
    } catch (e) {
      setError('Failed to delete folder');
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      setSelectedCollectionId(node.id);
      await onUploadToCollection(files, node.id);
    }
  };

  return (
    <>
      <ListItem
        disablePadding
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        component="div"
      >
        <ListItemButton
          disableRipple
          selected={isSelected}
          onClick={() => setSelectedCollectionId(node.id)}
          sx={{
            backgroundColor: 'transparent',
            borderRadius: 1,
            pl: `${level * 24}px`,
            position: 'relative',
            '&.Mui-selected': {
              color: 'primary.main',
            },
            ...(isDraggingOver && {
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(25, 118, 210, 0.08)',
                border: '2px dashed #1976d2',
                borderRadius: 1,
                pointerEvents: 'none',
              },
            }),
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0} width="100%">
            {hasChildren && (
              <IconButton
                disableRipple
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
                sx={{
                  transform: isExpanded ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.2s',
                }}
              >
                <ChevronRight fontSize="small" />
              </IconButton>
            )}
            {!hasChildren && <Box sx={{ width: 30 }} />}
            <ListItemIcon sx={{ minWidth: 36 }}>
              <Folder
                fontSize="small"
                color={isSelected ? 'inherit' : 'action'}
              />
            </ListItemIcon>
            <ListItemText
              primary={isRoot ? 'All Files' : node.name}
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: isSelected ? 600 : 400,
                  fontSize: 14,
                },
              }}
            />
            <IconButton
              size="small"
              onClick={handleMenuClick}
              sx={{ ml: 1 }}
              disableRipple
            >
              <MoreVert fontSize="small" />
            </IconButton>
          </Stack>
        </ListItemButton>
      </ListItem>

      {/* Collection Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {!isRoot && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setRenameFolderDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setNewFolderDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <Folder fontSize="small" />
          </ListItemIcon>
          <ListItemText>New Subfolder</ListItemText>
        </MenuItem>
        {!isRoot && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setDeleteFolderDialogOpen(true);
            }}
            sx={{ color: 'error.main' }}
          >
            <ListItemIcon>
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete Folder</ListItemText>
          </MenuItem>
        )}
      </Menu>

      {/* Rename Folder Dialog */}
      <Dialog
        open={renameFolderDialogOpen}
        onClose={() => setRenameFolderDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFolderDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleRenameFolder}
            variant="contained"
            disabled={!folderName.trim()}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Folder Dialog */}
      <Dialog
        open={newFolderDialogOpen}
        onClose={() => setNewFolderDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewFolderDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolderName.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Folder Dialog */}
      <DeleteConfirmationDialog
        open={deleteFolderDialogOpen}
        onClose={() => setDeleteFolderDialogOpen(false)}
        onConfirm={handleDeleteFolder}
        itemCount={1}
        itemName={node.name}
      />

      {hasChildren && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {node.children?.map((child) => (
            <FolderItem
              key={child.id}
              node={child}
              selectedId={selectedId}
              setSelectedCollectionId={setSelectedCollectionId}
              level={child.depth - 1}
              rootNodeId={rootNodeId}
              onUploadToCollection={onUploadToCollection}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};
