import { createSelector } from '@reduxjs/toolkit';
import React from 'react';

import {
  matterAssistantApi,
  MatterMessage,
  messageAdapter,
  MessageStatus,
} from '../../services/api/matterAssistant';
import { RootState, store } from '../state';

export const selectMatterMessagesResult = createSelector(
  [
    (state: RootState) => state,
    (state: RootState, matterId: string) => matterId,
    (state: RootState, matterId: string, threadId: string) => threadId,
  ],
  (state, matterId, threadId) =>
    matterAssistantApi.endpoints.getMatterMessages.select({
      matterId,
      threadId,
    })(state),
);

export const addMessage = (
  matterId: string,
  threadId: string,
  message: MatterMessage,
) => {
  // Update the RTK Query cache
  store.dispatch(
    matterAssistantApi.util.updateQueryData(
      'getMatterMessages',
      { matterId, threadId },
      (draft) => {
        return messageAdapter.addOne(draft, message);
      },
    ),
  );
};

export const updateMessage = (
  matterId: string,
  threadId: string,
  messageId: string,
  message: Partial<MatterMessage>,
) => {
  store.dispatch(
    matterAssistantApi.util.updateQueryData(
      'getMatterMessages',
      { matterId, threadId },
      (draft) => {
        const update = messageAdapter.updateOne(draft, {
          id: messageId,
          changes: message,
        });
        return update;
      },
    ),
  );
};

export const updateMessageContent = (
  matterId: string,
  threadId: string,
  messageId: string,
  content: Partial<MessageContentDelta>,
) => {
  store.dispatch(
    matterAssistantApi.util.updateQueryData(
      'getMatterMessages',
      { matterId, threadId },
      (draft) => {
        const message = draft.entities[messageId];
        if (!message) {
          return draft;
        }

        // Create a deep copy of the message content to avoid direct mutation
        const updatedContent = [...message.content];

        // Process all text deltas efficiently
        (content.delta || []).forEach((delta) => {
          if (
            delta.type === 'text' &&
            delta.text &&
            typeof delta.index === 'number'
          ) {
            const contentItem = updatedContent[delta.index];
            if (contentItem) {
              // Create a new content item with updated text
              updatedContent[delta.index] = {
                ...contentItem,
                text_content: contentItem.text_content + delta.text,
              };
            } else {
              // Create a new entry if no content exists at this index
              updatedContent[delta.index] = {
                text_content: delta.text,
                image_content: null,
              };
            }
          }
        });

        // Return the updated draft with only the specific changes
        return messageAdapter.updateOne(draft, {
          id: messageId,
          changes: {
            content: updatedContent,
          },
        });
      },
    ),
  );
};

export const useMatterMessageSelector = (
  matterId: string,
  threadId: string,
) => {
  const selector = React.useMemo(() => {
    return createSelector(
      matterAssistantApi.endpoints.getMatterMessages.select({
        matterId,
        threadId,
      }),
      (state) => state.data ?? messageAdapter.getInitialState(),
    );
  }, [matterId, threadId]);

  const entitySelectors = messageAdapter.getSelectors(selector);

  return {
    selectAll: entitySelectors.selectAll,
    selectById: entitySelectors.selectById,
    selectIds: entitySelectors.selectIds,
    selectEntities: entitySelectors.selectEntities,
    selectTotal: entitySelectors.selectTotal,
  };
};
export interface TextMessageContentDelta {
  index: number;
  text: string;
  type: 'text';
}
export interface MessageContentDelta {
  id: string;
  parent_id: string | null;
  status: MessageStatus;
  delta: TextMessageContentDelta[];
}
