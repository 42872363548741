import { BaseBlandCallTemplate } from './bland-templates.types';
import { Task } from './client-intake-types';

// Import SendCallRequestExtras from blandCallService
// Define SendCallRequest type which combines BaseBlandCallTemplate and SendCallRequestExtras
export interface SendCallRequestExtras {
  phone_number: string;
  task?: string;
}

export type SendCallRequest = Partial<BaseBlandCallTemplate> &
  SendCallRequestExtras;

// Enum for schedule status matching BlandScheduleStatus from backend
export enum BlandScheduleStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  COMPLETED = 'completed',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

// Base interface for BlandSchedule
export interface BlandSchedule {
  id: string;
  task_id: string | null;
  phone_number: string;

  // Schedule configuration
  calendar_minute: number[];
  calendar_hour: number[];
  calendar_day_of_month?: number[] | null;
  calendar_day_of_week?: number[] | null;
  interval_period: string | null;
  schedule_start_at: string;
  schedule_end_at: string | null;
  timezone: string;

  // Status
  status: BlandScheduleStatus;
  error_message: string | null;

  // Call configuration - update to use SendCallRequest type
  bland_payload: SendCallRequest;

  // Tracking
  created_by_id: string | null;

  // Retry configuration
  retry_enabled: boolean;
  retry_count: number;
  max_retries: number;
  retry_delay_seconds: number;

  // Timestamps
  created_at: string;
  modified_at: string;
}

// Interface for creating a new schedule
export interface CreateBlandScheduleRequest {
  phone_number: string;
  task_id: string;

  // Schedule configuration (with defaults matching backend)
  calendar_minute?: number[];
  calendar_hour?: number[];
  calendar_day_of_month?: number[] | null;
  calendar_day_of_week?: number[] | null;
  interval_period?: string | null;
  schedule_start_at?: string;
  schedule_end_at?: string | null;
  timezone?: string;

  // Status
  status?: BlandScheduleStatus;

  // Call configuration - update to use SendCallRequest type
  bland_payload: SendCallRequest;

  // Retry configuration
  retry_enabled?: boolean;
  retry_count?: number;
  max_retries?: number;
  retry_delay_seconds?: number;
}

// Interface for updating a schedule
export interface UpdateBlandScheduleRequest {
  id: string;
  phone_number?: string;
  task_id?: string;

  // Schedule configuration
  calendar_minute?: number[];
  calendar_hour?: number[];
  calendar_day_of_month?: number[] | null;
  calendar_day_of_week?: number[] | null;
  interval_period?: string | null;
  schedule_start_at?: string;
  schedule_end_at?: string | null;
  timezone?: string;

  // Status
  status?: BlandScheduleStatus;
  error_message?: string | null;

  // Call configuration - update to use SendCallRequest type
  bland_payload?: SendCallRequest;

  // Retry configuration
  retry_enabled?: boolean;
  retry_count?: number;
  max_retries?: number;
  retry_delay_seconds?: number;
}

// Interface for filtering schedules
export interface FilterBlandSchedulesRequest {
  task_id?: string;
  status?: BlandScheduleStatus;
  created_by_id?: string;
  phone_number?: string;
}

// Interface for pause/resume request
export interface ScheduleActionRequest {
  note?: string;
}

// Interface for schedule description response
export interface ScheduleDescriptionResponse {
  schedule: BlandSchedule;
  next_execution_time?: string;
  execution_history?: {
    execution_time: string;
    status: string;
    result?: string;
    error?: string;
  }[];
}
