import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';

interface ClientFormActionsProps {
  isEditing: boolean;
  isNew: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  isSaving: boolean;
  isDeleting: boolean;
  title: string;
}

export const ClientFormActions: React.FC<ClientFormActionsProps> = ({
  isEditing,
  isNew,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  isSaving,
  isDeleting,
  title,
}) => {
  const showDeleteButton = !isNew && onDelete;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete();
    }
    setIsPopoverOpen(false);
  };

  return (
    <div className="bg-muted/20 border-b rounded-t-lg flex items-center justify-between p-2">
      <div>
        {title && (
          <h3 className="text-base font-semibold text-gray-900 px-2">
            {title}
          </h3>
        )}
      </div>
      <div className="flex items-center gap-2">
        {isEditing ? (
          <>
            <Button
              variant="ghost"
              size="sm"
              onClick={onCancel}
              disabled={isSaving}
              className="text-muted-foreground/70 hover:text-foreground px-2 py-1 h-auto"
            >
              <span className="text-xs">Cancel</span>
            </Button>
            <Separator orientation="vertical" className="h-5 opacity-50" />
            <Button
              variant="ghost"
              size="sm"
              onClick={onSave}
              disabled={isSaving}
              className="text-primary/80 hover:text-primary hover:bg-primary/10 px-2 py-1 h-auto"
            >
              <span className="text-xs">{isSaving ? 'Saving...' : 'Save'}</span>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="ghost"
              size="sm"
              onClick={onEdit}
              className="text-muted-foreground/70 hover:text-foreground px-2 py-1 h-auto"
            >
              <span className="text-xs">Edit</span>
            </Button>
            {showDeleteButton && (
              <>
                <Separator orientation="vertical" className="h-5 opacity-50" />
                <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      disabled={isDeleting}
                      className="text-destructive/70 hover:text-destructive hover:bg-destructive/10 px-2 py-1 h-auto"
                    >
                      <span className="text-xs">
                        {isDeleting ? 'Deleting...' : 'Delete'}
                      </span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-3" align="end">
                    <div className="space-y-2">
                      <p className="text-sm font-medium text-gray-900">
                        Delete this client information?
                      </p>
                      <p className="text-xs text-gray-500">
                        This action cannot be undone.
                      </p>
                      <div className="flex justify-end gap-2 pt-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setIsPopoverOpen(false)}
                          className="h-8 px-3"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={handleConfirmDelete}
                          disabled={isDeleting}
                          className="h-8 px-3 text-white"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
