import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CrashDocsLookupResponse,
  CrashDocsLookupStatusResponse,
} from '../types/client-intake-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export interface CrashReportLookupParams {
  taskId: string;
  data: {
    state: string;
    jurisdiction: string;
    last_name: string;
    incident_date: string;
    report_id?: string;
  };
}

export const policeReportsApi = createApi({
  reducerPath: 'policeReportsApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    lookupCrashDocs: builder.mutation<
      CrashDocsLookupResponse,
      CrashReportLookupParams
    >({
      query: ({ taskId, data }) => ({
        url: `api/tasks/${taskId}/police-report-lookup`,
        method: 'POST',
        body: data,
      }),
    }),

    // New endpoint to check the status of a police report lookup
    getCrashDocsLookupStatus: builder.query<
      CrashDocsLookupStatusResponse,
      string // lookup_id
    >({
      query: (lookupId) => ({
        url: `api/police-reports/lookups/${lookupId}/status`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLookupCrashDocsMutation, useGetCrashDocsLookupStatusQuery } =
  policeReportsApi;
