import { RefreshCw, XCircle } from 'lucide-react';
import React from 'react';

import { Badge } from '@/components/ui/badge';

import { ScheduleDetailsProps } from './types';
import { formatRetryDelay } from './utils';

export const RetryConfiguration: React.FC<ScheduleDetailsProps> = ({
  schedule,
}) => {
  // If retry is not enabled and there's no max retries, we can skip rendering
  if (!schedule.retry_enabled && !schedule.max_retries) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg p-3 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <RefreshCw className="h-4 w-4 text-primary flex-shrink-0" />
          <h4 className="font-medium">Retry</h4>
          {schedule.retry_enabled ? (
            <Badge
              variant="outline"
              className="bg-green-50 text-green-700 border-green-200 text-xs px-2"
            >
              Enabled
            </Badge>
          ) : (
            <Badge
              variant="outline"
              className="bg-amber-50 text-amber-700 border-amber-200 text-xs px-2"
            >
              Disabled
            </Badge>
          )}
        </div>

        {schedule.retry_enabled && (
          <div className="flex items-center gap-3 text-xs">
            <div className="flex items-center">
              <span className="text-muted-foreground">Delay:</span>
              <span className="ml-1 font-medium">
                {formatRetryDelay(schedule.retry_delay_seconds || 0)}
              </span>
            </div>

            <div className="flex items-center">
              <span className="text-muted-foreground">Max:</span>
              <span className="ml-1 font-medium">
                {schedule.max_retries || 0}
              </span>
            </div>
          </div>
        )}
      </div>

      {!schedule.retry_enabled && schedule.max_retries > 0 && (
        <div className="flex items-center text-xs text-muted-foreground mt-1">
          <XCircle className="h-3 w-3 mr-1 text-amber-600 flex-shrink-0" />
          <span>Automatic retries disabled</span>
        </div>
      )}
    </div>
  );
};
