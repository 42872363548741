import { PlusCircle } from 'lucide-react';
import React from 'react';

interface ClientSectionHeaderProps {
  title: string;
  onAddNew?: () => void;
  showAddButton?: boolean;
}

export const ClientSectionHeader: React.FC<ClientSectionHeaderProps> = ({
  title,
  onAddNew,
  showAddButton = false,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between leading-none py-2 border-b border-gray-100/80">
        <div className="flex items-baseline gap-4">
          <h3 className="text-lg font-medium text-gray-800">{title}</h3>
          <div className="flex items-center gap-3 pl-1">
            {showAddButton && onAddNew && (
              <button
                onClick={onAddNew}
                type="button"
                className="group inline-flex items-center gap-1.5 text-xs font-medium transition-all duration-300 text-muted-foreground/60 hover:text-primary/80"
                aria-label="Add New"
              >
                <PlusCircle className="h-3.5 w-3.5 transition-all duration-300 text-muted-foreground/60 group-hover:text-primary/80 group-hover:scale-110" />
                <span className="hidden sm:inline-block tracking-tight">
                  Add
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
