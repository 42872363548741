import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Header } from '@/layout/Header';
import { cn } from '@/lib/utils';

import useMatterSelection from '../../hooks/useMatterSelection';
import MatterTitle from '../MatterTitle';
import { TaskPanel } from '../task-panel';
import MatterPanel from './MatterPanel';

const MatterLayout: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();
  const location = useLocation();
  const navigate = useNavigate();
  const initialTab = location.pathname.endsWith('/demands')
    ? 'demands'
    : 'home';
  const [tabValue, setTabValue] = useState(initialTab);

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
    if (selectedMatterData?.id) {
      if (newValue === 'demands') {
        navigate(`/matters/${selectedMatterData.id}/demands`);
      } else if (location.pathname.endsWith('/demands')) {
        navigate(`/matters/${selectedMatterData.id}`);
      }
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden">
      <Header />
      <main className="flex-1 flex flex-col overflow-hidden">
        {selectedMatterData && (
          <div className="border-b border-border flex-shrink-0">
            <div className="px-6 pt-4 pb-1">
              <MatterTitle />
              <Tabs
                value={tabValue}
                onValueChange={handleTabChange}
                id="matter-tabs"
              >
                <TabsList className="mt-2 bg-transparent">
                  <TabsTrigger
                    value="home"
                    className="font-sans font-bold px-3 mr-2 bg-none text-muted-foreground data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:rounded-full"
                  >
                    <span className="pb-0.5">Home</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="documents"
                    className="font-sans font-bold px-3 mr-2 bg-none text-muted-foreground data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:rounded-full"
                  >
                    <span className="pb-0.5">Documents</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="matter-details"
                    className="font-sans font-bold px-3 mr-2 bg-none text-muted-foreground data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:rounded-full"
                  >
                    <span className="pb-0.5">Matter Details</span>
                  </TabsTrigger>
                  {/* Uncomment to get the demand tab back @dean */}
                  <TabsTrigger
                    value="demands"
                    className="font-sans font-bold px-3 mr-2 bg-none text-muted-foreground data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:rounded-full"
                  >
                    <span className="pb-0.5">Demands</span>
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        )}

        {/* Content area */}
        <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
          {/* Matter Panel (left on desktop, bottom on mobile) */}
          <div
            className={cn(
              'w-full',
              tabValue !== 'demands' && 'md:w-1/2',
              'order-last md:order-first h-full overflow-hidden',
            )}
          >
            <Tabs value={tabValue} id="matter-content-tabs" className="h-full">
              <MatterPanel activeTab={tabValue} />
            </Tabs>
          </div>

          {/* Task Panel (right on desktop, top on mobile) */}
          <div
            className={cn(
              'w-full md:w-1/2 order-first md:order-last h-full overflow-auto pl-0.5',
              (!selectedMatterData || tabValue === 'demands') && 'hidden',
            )}
          >
            {selectedMatterData && <TaskPanel />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default MatterLayout;
