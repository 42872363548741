import { FrontContextProvider } from '@/providers/frontContext';

import { FrontSidebarContent } from './FrontSidebarContent';

export const FrontPluginSidebar = () => {
  return (
    <div className="flex h-screen w-screen flex-auto">
      <FrontContextProvider>
        <FrontSidebarContent />
      </FrontContextProvider>
    </div>
  );
};
