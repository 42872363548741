import { Box, Typography } from '@mui/material';

import { InsuranceNote } from '../../../../services/types/matter-notes-types';
import { INSURANCE_POLICY_TYPE_OPTIONS } from '../../../matter-notes/config/noteConfigs';
import ReadOnlyNoteField from './ReadOnlyNoteField';

interface ReadOnlyInsuranceNoteItemProps {
  note: InsuranceNote;
}

const getPolicyTypeLabel = (value: string) => {
  const option = INSURANCE_POLICY_TYPE_OPTIONS.find(
    (opt) => opt.value === value,
  );
  return option?.label || value;
};

const ReadOnlyInsuranceNoteItem = ({
  note,
}: ReadOnlyInsuranceNoteItemProps) => {
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '20px',
          mb: 2,
        }}
      >
        {getPolicyTypeLabel(note.policy_type as string)}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          '& > *': {
            width: '100%',
          },
        }}
      >
        <ReadOnlyNoteField label="POLICY HOLDER" value={note.holder} />
        <ReadOnlyNoteField label="POLICY NUMBER" value={note.policy_number} />
        <ReadOnlyNoteField label="CLAIM NUMBER" value={note.claim_number} />
        <ReadOnlyNoteField label="ADJUSTER" value={note.adjuster_name} />
        <ReadOnlyNoteField label="MEMBER ID" value={note.member_id} />
        <ReadOnlyNoteField label="GROUP ID" value={note.group_id} />
      </Box>
    </>
  );
};

export default ReadOnlyInsuranceNoteItem;
