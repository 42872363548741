import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { GroupedCombobox } from '@/components/ui/grouped-combobox';
import { Input } from '@/components/ui/input';
import { INSURANCE_CARRIER_CONTACTS } from '@/constants/insurance-carriers';
import { useGetNotesQuery } from '@/services/api/matterNotesService';
import { Contact, ContactType } from '@/services/types/task-types';
import { isPhoneNumberValid } from '@/utils/validation';

import {
  InsuranceNote,
  MedicalNote,
  NOTE_TYPES,
} from '../../services/types/matter-notes-types';

interface ContactSelectionDisplayProps {
  provider: Contact;
  displayValue: string;
  isCustomMode: boolean;
  phoneNumber: string;
  onClear: (e: React.MouseEvent) => void;
}

const ContactSelectionDisplay: React.FC<ContactSelectionDisplayProps> = ({
  provider,
  displayValue,
  isCustomMode,
  phoneNumber,
  onClear,
}) => (
  <div className="flex flex-col gap-0.5 min-w-0 w-full">
    <div className="font-medium text-sm truncate text-left flex justify-between items-center">
      <span>
        {displayValue}
        {isCustomMode && ' (Custom Number)'}
      </span>
      <button
        type="button"
        className="text-gray-500 hover:text-gray-900 cursor-pointer border text-sm font-medium px-2 py-1 rounded hover:bg-gray-200 transition-colors"
        onClick={onClear}
      >
        Clear
      </button>
    </div>
    <div className="text-[11px] text-gray-500 text-left flex gap-2">
      <span>{provider.type}</span>
      <span className="font-medium">
        {isCustomMode ? phoneNumber : provider.phone}
      </span>
    </div>
  </div>
);

const useContacts = (matterId: string) => {
  const { data: medicalNotes } = useGetNotesQuery({
    matterId,
    noteType: NOTE_TYPES.MEDICAL,
  });

  const { data: insuranceNotes } = useGetNotesQuery({
    matterId,
    noteType: NOTE_TYPES.INSURANCE,
  });

  const contacts = React.useMemo(() => {
    const noteContacts: Contact[] = [];

    if (medicalNotes) {
      noteContacts.push(
        ...(medicalNotes as MedicalNote[]).map((note) => ({
          id: note.id,
          name: note.name || 'Unnamed Provider',
          phone: note.phone || '',
          type: ContactType.MEDICAL_PROVIDER,
        })),
      );
    }

    if (insuranceNotes) {
      noteContacts.push(
        ...(insuranceNotes as InsuranceNote[]).map((note) => ({
          id: note.id,
          name: `${note.carrier || 'Unknown Carrier'} - ${note.adjuster_name || 'Unknown Adjuster'}`,
          phone: note.adjuster_phone || '',
          type: ContactType.INSURANCE_ADJUSTER,
        })),
      );
    }

    return noteContacts;
  }, [medicalNotes, insuranceNotes]);

  return { contacts };
};

interface ContactSelectorProps {
  form: UseFormReturn<any>;
  matterId: string;
  isMedicalTask: boolean;
  isInsuranceTask: boolean;
  allowCustomPhoneNumber?: boolean;
}

export const ContactSelector: React.FC<ContactSelectorProps> = ({
  form,
  matterId,
  isMedicalTask,
  isInsuranceTask,
  allowCustomPhoneNumber = true,
}) => {
  const { contacts } = useContacts(matterId);
  const selectedContact = form.watch('provider');
  const phoneNumber = form.watch('phoneNumber');

  // Determine if we're in custom mode by comparing phone numbers
  // Only allow custom mode if allowCustomPhoneNumber is true
  const isCustomMode =
    allowCustomPhoneNumber &&
    selectedContact &&
    phoneNumber !== selectedContact.phone;

  // Categorize providers into suggested and all
  const categorizedProviders = React.useMemo(() => {
    // Get suggested contacts based on task type
    const suggested = contacts.filter((provider) => {
      if (isMedicalTask && provider.type === ContactType.MEDICAL_PROVIDER)
        return true;
      if (
        isInsuranceTask &&
        (provider.type === ContactType.INSURANCE_ADJUSTER ||
          provider.type === ContactType.INSURANCE_CARRIER)
      )
        return true;
      return false;
    });

    // All other matter contacts (excluding those in suggested)
    const allOthers = contacts.filter(
      (contact) => !suggested.some((s) => s.id === contact.id),
    );

    return { suggested, allOthers };
  }, [contacts, isMedicalTask, isInsuranceTask]);

  // Set first suggested contact as default if available
  React.useEffect(() => {
    const [firstSuggested] = categorizedProviders.suggested;
    // Only set default if there's a suggested contact and no contact or phone number already set
    if (firstSuggested && !selectedContact && !phoneNumber) {
      form.setValue('provider', firstSuggested);
      form.setValue('phoneNumber', firstSuggested.phone);
    }
  }, [categorizedProviders.suggested, form, selectedContact, phoneNumber]);

  // When a contact is selected and custom phone numbers are not allowed,
  // ensure the phone number is always set to the contact's phone
  React.useEffect(() => {
    if (
      !allowCustomPhoneNumber &&
      selectedContact &&
      phoneNumber !== selectedContact.phone
    ) {
      form.setValue('phoneNumber', selectedContact.phone);
    }
  }, [allowCustomPhoneNumber, selectedContact, phoneNumber, form]);

  return (
    <>
      <FormField
        control={form.control}
        name="provider"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-bold">Contact</FormLabel>
            <FormControl>
              <div className="relative">
                <GroupedCombobox
                  key={phoneNumber || 'no-phone'}
                  searchKeys={['name', 'type', 'phone']}
                  label=""
                  groups={[
                    ...(categorizedProviders.suggested.length > 0
                      ? [
                          {
                            label: 'Suggested Matter Contacts',
                            options: categorizedProviders.suggested,
                          },
                        ]
                      : []),
                    ...(categorizedProviders.allOthers.length > 0
                      ? [
                          {
                            label: 'All Matter Contacts',
                            options: categorizedProviders.allOthers,
                          },
                        ]
                      : []),
                    {
                      label: 'Insurance Carriers',
                      options: INSURANCE_CARRIER_CONTACTS,
                    },
                  ]}
                  value={field.value}
                  onChange={(value) => {
                    if (value) {
                      field.onChange(value);
                      form.setValue('phoneNumber', value.phone);
                    }
                  }}
                  placeholder={(() => {
                    if (!allowCustomPhoneNumber) {
                      return 'Select a contact';
                    }
                    if (phoneNumber) {
                      return `Phone: ${phoneNumber}`;
                    }
                    return 'Select a contact or enter a number';
                  })()}
                  displayValue={(provider) => provider.name}
                  renderItem={({ item: provider, displayValue }) => (
                    <div className="flex flex-col min-w-0">
                      <div className="font-medium truncate text-gray-900">
                        {displayValue}
                      </div>
                      <div className="text-[11px] text-gray-500 flex gap-2">
                        <span>{provider.type}</span>
                        {provider.phone && (
                          <span className="font-medium">{provider.phone}</span>
                        )}
                      </div>
                    </div>
                  )}
                  renderSelection={({ item: provider, displayValue }) =>
                    field.value ? (
                      <ContactSelectionDisplay
                        provider={provider}
                        displayValue={displayValue}
                        isCustomMode={isCustomMode}
                        phoneNumber={phoneNumber}
                        onClear={(e) => {
                          e.stopPropagation();
                          field.onChange(null);
                          form.setValue('phoneNumber', '');
                        }}
                      />
                    ) : (
                      <span className="text-gray-500">
                        {(() => {
                          if (!allowCustomPhoneNumber) {
                            return 'Select a contact';
                          }
                          if (phoneNumber) {
                            return `Phone: ${phoneNumber}`;
                          }
                          return 'Select a contact or enter a number';
                        })()}
                      </span>
                    )
                  }
                />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {allowCustomPhoneNumber && (
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="max-w-[50%]">
              <FormLabel className="font-bold" htmlFor="phone">
                Phone Number
              </FormLabel>
              <FormControl>
                <Input
                  id="phone"
                  type="tel"
                  placeholder="Enter phone number (e.g. 303-217-0796)"
                  className="h-9 transition-colors data-[invalid=true]:border-red-500/50 data-[invalid=true]:ring-red-500/25"
                  data-invalid={!isPhoneNumberValid(field.value)}
                  {...field}
                />
              </FormControl>
              <FormMessage>
                {(() => {
                  if (!field.value) {
                    return 'Phone number is required';
                  }
                  if (!isPhoneNumberValid(field.value)) {
                    return 'Please enter a valid phone number (e.g. 303-217-0796)';
                  }
                  return '';
                })()}
              </FormMessage>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
