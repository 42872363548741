import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import useMatterSelection from '../hooks/useMatterSelection';
import {
  usePatchMatterMutation,
  usePatchMatterSummaryMutation,
} from '../services/api';
import {
  DetailedMatter,
  MatterSummary,
} from '../services/types/client-intake-types';
import theme from '../theme/theme';
import ClientScreenTile from './base/ClientScreenTile';
import EditableTextArea from './base/EditableTextArea';
import SeedSummaryButton from './matter-summary/components/SeedSummaryButton';

const SummaryItem: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Grid item xs={12} sm={12} lg={4} key={title}>
      <Typography variant="body1" sx={{ mb: '8px' }}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

const Summary: React.FC<{ matterId: string; summary: MatterSummary }> = ({
  matterId,
  summary,
}) => {
  const [patchMatterSummary] = usePatchMatterSummaryMutation();

  const handleSave = async (content: string) => {
    await patchMatterSummary({
      matter_id: matterId,
      id: summary.id,
      patch: { content },
    }).unwrap();
  };

  return (
    <SummaryItem title={summary.category}>
      <EditableTextArea
        hideBorder
        noPadding
        value={summary.content}
        onSave={handleSave}
      />
    </SummaryItem>
  );
};

const IntakeNotes: React.FC<{ matter: DetailedMatter | undefined }> = ({
  matter,
}) => {
  const [updateMatter] = usePatchMatterMutation();

  const handleSave = async (content: string) => {
    if (!matter?.id) return;
    await updateMatter({
      matter_id: matter.id,
      patch: { intake_note: content },
    }).unwrap();
  };

  return (
    <Grid item xs={12} sm={12} lg={4}>
      <Typography
        variant="body1"
        sx={{ mb: '8px', color: 'var(--Primary, rgba(62, 33, 243, 1))' }}
      >
        Finch Evaluation
      </Typography>
      <EditableTextArea
        hideBorder
        noPadding
        value={matter?.intake_note || ''}
        onSave={handleSave}
      />
    </Grid>
  );
};

const Summaries: React.FC = () => {
  const { selectedMatterData, refetchMatterDetails } = useMatterSelection();

  // Handler to refresh summaries after seeding completes
  const handleSeedingComplete = useCallback(() => {
    refetchMatterDetails();
  }, [refetchMatterDetails]);

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2} sx={{ margin: 'initial' }}>
        <Box
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.border.main}`,
            paddingBottom: 3,
          }}
          justifyContent="space-between"
          display="flex"
          alignItems="bottom"
        >
          <Typography variant="h2">Case Summary</Typography>
          <SeedSummaryButton
            matterId={selectedMatterData?.id || ''}
            onSeedingComplete={handleSeedingComplete}
          />
        </Box>
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {selectedMatterData?.summaries &&
              selectedMatterData.summaries.map((summary) => (
                <Summary
                  matterId={selectedMatterData.id}
                  key={summary.id}
                  summary={summary}
                />
              ))}
            <IntakeNotes matter={selectedMatterData} />
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default Summaries;
