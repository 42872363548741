import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import WavesurferPlayer from '@wavesurfer/react';
import React, { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import { audioService } from '../../services/api/audioService';
import { Conversation } from '../../services/types/client-intake-types';
import theme from '../../theme/theme';
import { formatCallDuration, formatIntakeCall } from '../../utils/formatting';

interface AudioPlayerProps {
  conversation: Conversation;
  onPlay: () => void;
  playingConversationId: string | null;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  conversation,
  onPlay,
  playingConversationId,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchAudioUrl = async () => {
      if (conversation.audio_path) {
        try {
          const url = await audioService.fetchPresignedUrl(
            conversation.audio_path,
          );
          setAudioUrl(url);
        } catch (error) {
          Sentry.captureException(
            `Failed to fetch audio URL for ${conversation.id}`,
          );
          setAudioUrl(null);
        }
      }
    };

    fetchAudioUrl();
  }, [conversation.audio_path, conversation.id]);

  useEffect(() => {
    if (playingConversationId !== conversation.id) {
      wavesurfer?.pause();
      setIsPlaying(false);
    }
  }, [playingConversationId, wavesurfer]);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/transcript/${conversation.id}/pdf/`,
          {
            credentials: 'include',
          },
        );

        if (response.ok) {
          const blob = await response.blob();
          setPdfBlob(blob);
        }
      } catch (error) {
        Sentry.captureException(error);
        setPdfBlob(null);
      }
    };

    fetchPdf();
  }, [conversation.id]);

  const onReady = (ws: WaveSurfer) => {
    setWavesurfer(ws);
    setIsPlaying(false);
    setTotalDuration(ws.getDuration());
  };

  const onPlayPause = () => {
    if (wavesurfer) {
      if (!isPlaying) {
        onPlay();
      }
      wavesurfer.playPause();
    }
  };

  useEffect(() => {
    if (!wavesurfer) return;

    const onTimeUpdate = (time: number) => {
      setCurrentTime(time);
    };

    wavesurfer.on('audioprocess', onTimeUpdate);

    // eslint-disable-next-line consistent-return
    return () => wavesurfer.un('audioprocess', onTimeUpdate);
  }, [wavesurfer]);

  return (
    <Stack
      spacing={3}
      sx={{
        background: theme.palette.backgroundGray.dark,
        borderRadius: '8px',
        padding: 3,
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          {formatIntakeCall(conversation)}
        </Typography>

        <Stack direction="row" gap="24px" alignItems="center">
          {pdfBlob && (
            <Button
              variant="outlined"
              color="info"
              href={URL.createObjectURL(pdfBlob)}
              target="_blank"
              rel="noreferrer"
              sx={{
                border: `1px solid ${theme.palette.border.dark}`,
                fontFamily: 'area-normal, sans-serif',
                fontSize: '12px',
                fontWeight: '700',
                letterSpacing: '1px',
                backgroundColor: 'white',
              }}
            >
              View Transcript
            </Button>

            // <Link
            //   variant="body1"
            //   color="#3E21F3"
            //   href={URL.createObjectURL(pdfBlob)}
            //   component="a"
            //   target="_blank"
            //   rel="noreferrer"
            //   sx={{ letterSpacing: '0px' }}
            // >
            //   View Transcript
            // </Link>
          )}
          {audioUrl && (
            <Link
              variant="body2"
              color="#3E21F3"
              href={audioUrl}
              download
              component="a"
              target="_blank"
              rel="noreferrer"
              sx={{ letterSpacing: '0px' }}
            >
              Download MP3
            </Link>
          )}
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton
          onClick={onPlayPause}
          disableRipple
          sx={{
            svg: {
              fill: '#000000',
            },
          }}
        >
          {isPlaying ? (
            <PauseCircleFilledIcon fontSize="large" />
          ) : (
            <PlayCircleFilledWhiteIcon fontSize="large" />
          )}
        </IconButton>
        <Box sx={{ flex: 1 }}>
          <WavesurferPlayer
            height={40}
            fillParent
            normalize
            waveColor="#E0E0E0"
            progressColor={theme.palette.info.dark}
            cursorColor="rgba(0,0,0,0)"
            cursorWidth={2}
            url={audioUrl || '/audio/sample.wav'}
            hideScrollbar
            dragToSeek
            barWidth={4}
            barGap={3}
            barRadius={100}
            onReady={onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        </Box>
        <Box
          sx={{
            width: 54,
            marginRight: 2,
            textAlign: 'right',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '700',
            color: '#808080',
          }}
        >
          {formatCallDuration(
            Math.floor(currentTime),
            Math.floor(totalDuration),
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default AudioPlayer;
