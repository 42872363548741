// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router-dom';

import { useGetSharedMatterQuery } from '../services/api/sharedMatterService';

const useSharedMatterSelection = () => {
  const { token } = useParams();

  const {
    data: selectedMatterData,
    error: selectedMatterError,
    isLoading: selectedMatterLoading,
  } = useGetSharedMatterQuery(token || '', {
    skip: !token,
  });

  return {
    selectedMatterData,
    selectedMatterError,
    selectedMatterLoading,
  };
};

export default useSharedMatterSelection;
