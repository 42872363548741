import { loadCKEditorCloud } from '@ckeditor/ckeditor5-react';

import { userApiClient } from '@/services/api/userService';
import { User } from '@/services/types/user-types';
import { selectUser } from '@/state/selectors/root-selectors';
import { store } from '@/state/state';

const {
  CKEditor: { Plugin },
} = await loadCKEditorCloud({
  version: '44.3.0',

  // Optional configuration:
  premium: true,
  translations: ['en'],
});
/**
 * The `UsersIntegration` lets you manage user data and permissions.
 *
 * This is an essential feature when many users work on the same document.
 *
 * To read more about it, visit the CKEditor 5 documentation: https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/users.html.
 */
export class UsersIntegration extends Plugin {
  static get requires() {
    return ['Users'];
  }

  static get pluginName() {
    return 'UsersIntegration';
  }

  async init() {
    const usersPlugin = this.editor.plugins.get('Users');
    const { data: users } = await store.dispatch(
      userApiClient.endpoints.getStaffUsers.initiate(),
    );

    const currentUser = selectUser(store.getState());

    if (!users || !currentUser) {
      usersPlugin.addUser({
        id: 'anonymous',
        name: 'Anonymous',
      });
      usersPlugin.useAnonymousUser();
      return;
    }

    users.forEach((user) => {
      usersPlugin.addUser({
        id: user.id,
        name: UsersIntegration.get_user_name(user),
      });
    });

    usersPlugin.defineMe(currentUser.id);
  }

  private static get_user_name(user: User) {
    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return user.email;
  }
}
